@import './mixins';

.sidenav---sidenav---_2tBP {
  position: fixed;
  background-color: color(site,leftMenuBg);
  z-index: 45;
  min-width: 55px;
  .sidenav---sidenav-nav---3tvij {
    .sidenav---sidenav-navitem---uwIJ- {
      .sidenav---navitem---9uL5T {
        .sidenav---navicon---3gCRo, .sidenav---navtext---1AE_f {
          color: color(site, txtLightGrey);
          font-size: 12px;
          font-weight: 600;
          &> * {
            color: color(site, txtLightGrey); 
          }
        }
        .sidenav---navicon---3gCRo {
          font-size: 18px;
          width: 55px;
        }
      }
      &.sidenav---selected---1EK3y {
        .sidenav---navitem---9uL5T {
          .sidenav---sidenav-subnavitem---1cD47 {
            color: color(site,theadColor);
          }
          .sidenav---navicon---3gCRo, .sidenav---navtext---1AE_f {
            color: color(site,highlighter);
            &> * {
              color: color(site,highlighter);
            }
          }
          &:after {
            background: transparent;
          }
        }
      }
    }
  }
  .sidenav---sidenav-subnavitem---1cD47 {
    padding: 10px 14px 5px 40px;
    background-color: color(sidenav,submenuBg);
  }
  &.sidenav---navtext---1AE_f {
    color: color(site,theadColor) !important;
  }
}

.sidenav---sidenav-subnavitem---1cD47 {
  &:nth-child(2) {
    margin-top: 0px !important;
  }
}
.sidenav---sidenav-toggle---1KRjR {
  .sidenav---icon-bar---u1f02 {
    background: color(site,txtColor);
  }
}

.logo {
  width: 150px;
}

.btn-small-logo{
  cursor: pointer;
  img{
    width: 25px;
    height: auto;
    margin: 15px;
  }
}

.btn-minimize-menu {
  padding: 18px;
  cursor: pointer;
  color: $light-color !important;
}
.wzrd-post-a-job {
    .radio-btn-group .btn-group .btn {
        border: 1px solid #e0e0e0;
        border-radius: 10px !important;
        background: #fff;
        color: #000 !important;
        padding: 10px 40px;
    }

    .w-30 {
        width: 30% !important;
    }

    .text-blue {
        color: #1481de !important;
    }

    .font-14 {
        font-size: 14px !important;
    }

    .text-underline {
        text-decoration: underline !important;
    }

    .wizard-body {
        min-height: auto;

        .wizard-header {
            .userTip {
                margin-bottom: 25px;
            }
        }

        .for-tgl-btn {
            .btn-group {
                flex-direction: row !important;

                label {
                    margin: auto 5px;
                    min-width: 230px;
                }
            }
        }

        .btn.active {
            border-color: #d3f1e3 !important;
            color: #000 !important;
        }

        .wizard-header {
            margin-bottom: 0px;
        }
    }

    .sub-srvs-typ-tgl {
        .btn-group {
            flex-direction: row;

            .btn {
                min-width: fit-content;
                margin: 5px;
                padding: 10px 40px;

                &.active {
                    border: 1px solid #d3f1e3;
                    color: #000;
                }
            }
        }
    }

    .wizard-header {
        h1 {
            font-weight: 500;
            font-size: 24px;
            color: #1d1a34;
        }

        h2 {
            font-weight: 500;
            font-size: 22px;
            color: #1d1a34;
        }

        margin-bottom: 0px;

        .userTip {
            font-weight: 500;
            font-size: 16px;
            color: #1d1a34;
            text-align: center;
        }
    }

    .y-n-btn {
        flex-direction: row !important;

        .btn {
            margin: 5px !important;
            min-width: 240px !important;
        }
    }
}

.thrd-lvl-btns {
    .btn-group {
        flex-direction: row !important;
        .btn {
            margin: 5px !important;
            min-width: fit-content !important;
            &.active {
                border: 1px solid #d3f1e3 !important;
            }
        }
    }
}

.wizard-body {
    min-height: auto !important;
}

.wizard-actions {
    .btn-primary {
        border-radius: 5px !important;
        background: #1481de !important;
    }

    .btn-secondary {
        border-color: #979797 !important;
        background: #fff !important;
        color: #707070 !important;
        border-radius: 5px !important;
    }
}

.chatgpt-actions {
    top: calc(100vh - 125px) !important;
    justify-content: end !important;
}

.issue-fix-suggestions {
    max-height: calc(100vh - 355px) !important;
}

.img-upld-cont {
    .rich-control {
        border-radius: 10px !important;
        border-color: #e0e0e0 !important;
    }
}

.trbl-st-cont{
    background: #fff;
    padding: 10px;
}

.action-alignment {
    margin-top: 160px !important;
}

.recurring-aligment {
    width: 600px !important;
}

.dropDown-style > .ui.selection.dropdown  {
     border-color: #000 !important
}

.btn-group-bot {
    flex-direction: column !important;
}
.tenant-chat-bot{
    .form-control{
        border: 1px solid #ededf1 !important;
        border-radius: 15px !important;
        background-color: #f9f9f9 !important;
        color: #000 !important;
        padding: 30px 20px !important;
        font-family: 'Poppins', sans-serif;       
    }
}

@media only screen and (max-width: 576px) {
    .tenant-chat-bot{
        .tenant-emailwrap{
            padding: 27px 20px !important;
        }
    }
}

@media (min-width: 768px) {
    .wzrd-post-a-job {
        .radio-btn-group .btn-group .btn {
            border: 1px solid #e0e0e0;
            border-radius: 10px !important;
            background: #fff;
            color: #000 !important;
            padding: 10px 40px;
        }

        .w-30 {
            width: 100% !important;
        }
    }
}
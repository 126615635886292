@import "theme";
@import "helpers";
@import "ba-icons";
@import "icomoon";
@import "common";
@import '/node_modules/semantic-ui-css/components/search.css';
@import '/node_modules/semantic-ui-css/components/label.css';
@import '/node_modules/semantic-ui-css/components/dropdown.css';
@import '../Components/Tenant/MyJobs/MyJobs.scss';
@import "sidenav";
@import "./bootstrap-override.scss";
@import "inspection";
@import "ba-font-stack";


body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #00324b !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: color(site, bodyBg) !important;
  // background-color: #f3f3f3 !important;
  font-size: 0.9rem;
  // padding-top: 60px;
  line-height: 1.5;
  height: auto;
  min-height: 100%;
  overflow: auto !important;
}
h1,
h2,
h3,
h4,
h5,
button {
  font-family: 'Poppins', sans-serif;
}
.font-regular{
  font-weight: 400 !important;
}
.cursor-pointer{
  cursor: pointer !important;
}
// Scroll Bar

/* width */
// ::-webkit-scrollbar {
//   width: 7px;
// }

/* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px color(grey, shadeone);
//   border-radius: 10px;
// }

/* Handle */
// ::-webkit-scrollbar-thumb {
//   background: color(primary, base);
//   border-radius: 10px;
// }

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: color(primary, base);
// }

.container-fluid-wrapper {
  // max-width: 930px;
  margin: 0 auto;
}

.top-nav-bar {
  // background: color(site, headerBg);
  // background: #fff !important;
  // position: fixed;
  // top: 0;
  // right: 0;
  // left: 0;
  // z-index: 44;
  // box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  //padding: 0 20px;
  @include respond-to(tabssam) {
    // padding: 5px 10px;
  }
  // height: 50px;
  .navbar-nav {
    li.nav-item {
      // margin-left: 10px;
      @include respond-to(tabsDektop) {
        margin: 0;
      }
      a.nav-link {
        color: color(site, defaultBlack);
        font-size: 12px;
        padding: 25px 15px 25px;
        @include respond-to(tabsDektop) {
          color: color(site, defaultBlack);
          padding: 5px 10px;
        }
      }
      .support-btn {
        background: color(site, lightBlue);
        color: color(site, darkBlue);
        border: 1px solid color(site, darkBlue);
        border-radius: 9px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 14px;
        @include respond-to(tabssam) {
          padding: 5px 10px;
          border: none;
          background: none;
        }
        &:focus{
          box-shadow: 0 0 0;
        }
      }
      &.active {
        a.nav-link {
          color: color(site, highlighter);
          border-bottom: 2px solid color(site, highlighter);
          @include respond-to(tabsDektop) {
            border-bottom: none;
            text-decoration: underline;
          }
        }
      }
      &.header-user-profile {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    @include respond-to(tabssam) {
      flex-direction: column;
    }
    .dropdown {
      @include respond-to(tabsDektop) {
        .dropdown-toggle {
          display: none;
        }
      }
      .dropdown-menu {
        right: 0;
        left: unset;
        top: 100%;
        @include respond-to(tabsDektop) {
          display: block;
          box-shadow: none;
          background: none;
          a {
            padding: 5px 10px;
            font-weight: 600;
            color: color(site, primary);
          }
          .dropdown-toggle {
            display: none;
          }
        }
      }
    }
    & + .navbar-nav {
      @include respond-to(tabssam) {
        margin-top: 0 !important;
      }
    }
  }
}
.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}
.loading-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.post-job-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  .btn-primary {
    background: color(site, btnDefault);
    border-color: color(site, btnDefault);
    font-size: 13px;
    padding: 5px 15px;
    border-radius: 25px;
    border: none;
    color: #fff;
    &:not(:disabled):active,
    &:not(:disabled):focus {
      background: color(site, btnDefault);
      border-color: color(site, btnDefault);
      box-shadow: none;
    }
  }
}
iframe#launcher,
iframe#webWidget {
  left: 0;
}
.no-suggetions {
  font-size: 12px;
}
.react-bs-table-sizePerPage-dropdown {
  display: none !important;
  visibility: hidden;
}
.light-blue-button{
  background: color(site, lightBlue);
  color: color(site, darkBlue);
  border: 1px solid color(site, darkBlue);
  border-radius: 10px !important;  
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 12px;
  padding: 8px 14px;
  min-width: 90px;
  cursor: pointer;
  &:focus{
    box-shadow: 0 0 0;
  }
  &:hover{
    background: color(site, darkBlue);
    color: color(site, defaultWhite);
  }
}
.next-btn{
  background: color(site, defaultBlack);
  color: color(site, defaultWhite);
  border-radius: 10px !important;  
  &:hover{
    background: rgb(48, 48, 48) !important;     
    color: color(site, defaultWhite) !important;  
  }
}
.red-card{
  background-color: #fdedec;
  color: color(site, highlighter) !important;  
  border-radius: 8px !important;
  padding: 15px;
  box-shadow: 0 0 0 !important;
  label{
    color: color(site, highlighter) !important;  
    font-size: 16px;
  }
}
.text-orange{
  color: #e5ab23;
}
.w-33{
  width: 33%;
}
.w-66{
  width: 66%;
}
//ba switch
//ba switch
.ba-switch {
  //taken out from dashboardN
  position: relative;
  display: inline-flex;
  margin: 5px 0 0 0;

  .ba-switch-input {
    position: absolute;
    z-index: -1; // Put the input behind the label so it doesn't overlay text
    opacity: 0;

    & ~ .ba-switch-label {
      position: relative;
      background: #dce4ea;
      color: #fff;
      display: flex;
      padding: 0 12px 0 30px;
      border-radius: 34px;
      font-size: 10px;
      height: 26px;
      line-height: 26px;
      font-weight: 600;
      cursor: pointer;
      // box-shadow: inset 0 0 5px rgba(#000,0.2);
      text-shadow: 1px 1px 2px rgba(#000, 0.2);
      &:before {
        content: "";
        background: #fff;
        width: 20px;
        height: 20px;
        border-radius: 34px;
        position: absolute;
        left: 3px;
        top: 3px;
        box-shadow: 2px 2px 3px rgba(#000, 0.15);
        transition: all 200ms ease-in;
      }

      .on-label {
        display: none;
        color: #fff;
      }

      .off-label {
        display: block;
        color: #668290;
      }
    }

    &:checked ~ .ba-switch-label {
      background: #27b975 !important;
      padding: 0 30px 0 12px;

      &:before {
        left: calc(100% - 23px);
        box-shadow: -2px -2px 3px rgba(#000, 0.15);
      }

      .on-label {
        display: block;
        color: #fff;
      }

      .off-label {
        display: none;
        color: #668290;
      }

      &:disabled {
        ~ .ba-switch-label {
          background: desaturate(rgba(53, 185, 74, 0.62), 10);
        }
      }
    }

    &:disabled {
      ~ .ba-switch-label {
        cursor: not-allowed;
      }
    }
  }
}
// video and pdf viewer

.video-pdf-viewer {
  width: 100%;
  max-height: calc(100vh - 170px);
  overflow: hidden;
  position: relative;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  video {
    width: 100%;
  }
}
.intercom-lightweight-app-launcher,
.intercom-launcher-frame {
  transform: scale(0.7) !important;
  bottom: 0px !important;
}

// Property details section

.po-section {
  padding-top: 10px;
  display: flex;
  .property-option {
    display: flex;
    align-items: center;
    padding-right: 50px;
    i {
      font-size: 22px;
      padding-right: 15px;
    }
  }
}

.h-auto {
  height: auto;
}

.home-wrapper {
  width: 100%;
  margin: 0 auto;
  padding-top: 70px;
  @include respond-to(phablet) {
    width: 80%;
    h1,
    p {
      text-align: center;
    }
  }
  h1 {
    font-size: 24px;
    margin-bottom: 5px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }
  p {
    font-size: 15px;
    margin-bottom: 50px;
    color: #8b8a96 !important;
  }
  h1,
  p {
    color: color(site, defaultBlack);
  }
  .home-btn {
    display: flex;
    height: 85px;
    align-items: center;
    background: color(site, defaultWhite);
    border-radius: 16px;
    padding: 25px 40px;
    font-size: 18px;
    margin-bottom: 30px;
    color: color(site, highlighter);
    font-weight: 400;
    width: 100%;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    @include respond-to(phablet) {
      margin-bottom: 20px;
      height: 50px;
      padding: 24px 20px 20px;
      font-size: 14px;
    }
    &.maintenance {
      background: color(site, darkBlue);
      color: color(site, defaultWhite);
      border: 0 none;
      &:focus{
        outline: 0 none;
      }
      @include respond-to(phablet) {
        margin-top: 60px;
      }
      .btn-label {
        color: color(site, defaultWhite);
      }
    }
    .plus-icon {
      font-size: 30px;
      width: 40px;
      text-align: center;
      margin-right: 20px;
      @include respond-to(phablet) {
        font-size: 22px;
        margin-right: 10px;
      }
    }
    .btn-label {
      flex-grow: 1;
      color: color(site, defaultBlack);
    }
    .counter {
      width: 35px;
      height: 35px;
      background: color(site, highlighter);
      @include flex-center();
      color: #fff;
      border-radius: 100%;
      font-size: 12px;
      @include respond-to(phablet) {
        width: 22px;
        height: 22px;
      }
    }
  }
}

.innerHtmlContainer {
  p {
    margin-bottom: 15px;
  }
}

.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 40px;
  h2 {
    font-size: 24px;
    color: color(site, defaultBlack);
    margin: 0;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    display: flex;
    align-items: center;
    span{
      background: color(site, dateProposedColor);
      color: color(site, defaultWhite);    
      font-size: 11px;
      font-weight: 500;
      padding: 6px 7px;
      border-radius: 5px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
    }
  }
}

.button-text {
  background: none;
  color: color(site, primary);
  display: inline-flex;
  align-items: center;
  border: none;
  &.back-btn {
    font-size: 14px;
    span {
     // text-decoration: underline;
    }
    i {
      margin-right: 5px;
      font-size: 9px;
      font-weight: 800;
    }
  }
}

.job-list-card {
  // border: 1px solid color(site, primary);
  border-radius: 15px;
  margin-bottom: 20px;
  position: relative;
  padding: 20px 25px;
  background-color: color(site, defaultWhite);
  &:hover{
    box-shadow: 0 3px 9px #e1e1e1;
  }
  & &-body {
    //padding: 12px;
    position: relative;
    //background: #f8f9fa;
    @include respond-to(phablet) {
      padding: 8px;
    }
    .job-number {
      font-size: 12px;
      color: #8b8a96;
      font-weight: 400;
    }
    .job-title {
      font-size: 16px;
      color: rgba(color(site, defaultBlack), 0.8);
      font-weight: 600;
    }
    .job-address {
      color: color(site, txtLightGrey);
      font-size: 10px;
    }
    .job-detail {
      color: rgba(color(site, defaultBlack), 0.6);
      font-size: 13px;
      margin-top: 5px;
    }
    .job-budget {
      font-size: 12px;
      color: color(site, defaultBlack);
      font-weight: 500;
      margin-top: 5px;
    }
    .job-label-set {
      display: flex;
      position: absolute;
      top: 12px;
      right: 12px;
      justify-content: flex-start;
      margin-top: 5px;
      margin-bottom: 0;
      @include respond-to(phablet) {
        position: unset;
        flex-wrap: wrap;
        .badge {
          padding: 5px;
        }
      }
    }
    .property-options {
      color: color(site, defaultBlack);
      margin-top: 20px;
      span{
        font-size: 14px;
      }
      i {
        margin-right: 5px;
        font-size: 18px;
        color: #8b8a96;
      }
    }
  }
  & &-footer {
    display: flex;
    justify-content: flex-end;
    //background: color(site, cardFtBg);
    padding: 0;
    border-radius: 0 0 5px 5px;
    @include respond-to(phablet) {
      justify-content: space-between;
    }
    .button-text {
      font-size: 12px;
      text-transform: uppercase;
      //padding: 8px 15px 9px;
      &:focus{
        outline: 0 none;
      }
      i {
        font-size: 18px;
        margin-right: 7px;
      }
      &.view-btn {
        @include respond-to(phablet) {
          position: absolute;
          top: 8px;
          right: 8px;
          padding: 0;
        }
      }
    }
  }
}

.job-details-data-wrapper {
  border: 1px solid color(site, primary);
  border-radius: 5px;
  .job-details-data {
    padding: 25px;
  }
  .job-action-btn {
    // background: color(site, cardFtBg);
    background: #ffffff;
    & > .card-actions {
      // background: color(site, cardFtBg);
      background: #ffffff;
      & > button {
        background: none;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
  .ext-detail-box .ext-detail-row .ext-icon-col {
    @include respond-to(phablet) {
      width: 80px;
      overflow: hidden;
      font-weight: 600;
      .icon-desc {
        overflow: hidden;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        display: block;
      }
    }
  }
}

.merged-job-card {
  background: #f8f9fa;
  padding: 8px 12px;
  line-height: 1.4;
  border-radius: 5px;
  margin-bottom: 5px;
  .job-number {
    font-size: 11px;
  }
  .job-title {
    font-size: 13px;
    font-weight: 600;
  }
  .job-contact {
    font-size: 13px;
  }
  &.job-selected {
    background: color(site, primary);
    color: color(site, defaultWhite);
  }
}

.info-header-bar {
  border: solid 1px #ffeeba;
  background-color: #fff3cd;
  padding: 10px 18px 10px 18px;

  .info-header-bar-text {
    //font-family: AvenirNext;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #856404;
  }
}

.confident-lbl {
  border-radius: 5px;
  padding: 5px 10px;

  &.confident-high {
    border: 1px solid #f40000;
    background: rgba(255, 0, 0, 0.18);
    color: #f40000 !important;
  }

  &.confident-medium {
    border: 1px solid #f4ca00;
    border-radius: 5px;
    padding: 5px 10px;
    background: rgba(244, 202, 0, 0.18);
    color: #b99900 !important;
  }

  &.confident-low {
    border: 1px solid #1481de;
    border-radius: 5px;
    padding: 5px 10px;
    background: rgba(20, 129, 222, 0.169);
    color: #1481de !important;
  }
}

.job-details-image {
  width: 28px;
}

.jobpost-wizard {
  .wizard-actions {
    .btn {
      margin-bottom: 10px !important;
    }
  }
}

.note-type.job-note {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #383d4a;
  .label {
    background: none;
  }
}

.job-note-headerL {
  .posted-date {
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.14px;
    color: #9d9d9d;
  }
  .user-name {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.2px;
    color: #383d4a;
  }

  .user-type {
    padding: 6px 15px;
    border-radius: 9px;
    background-color: #fff9dd;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.2px;
    text-align: center;
    color: #4a4a4a;
  }
}

.prop-dtl-pin {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: 0.2px;
  color: #383d4a;
  text-align: center;
  img {
    width: 24px;
  }
}

.prop-dtl-lbl {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: 0.12px;
  color: #383d4a;
}

.action-btn {
  border-radius: 3px !important;
  background: #34495e !important;
  color: #fff !important;

  &.reject-action {
    background: none !important;
    color: #eb4c4b !important;
  }

  &.panel-close {
    background-color: black !important;
    border-radius: 20px !important;    
    i {
      font-weight: 800;
    }
  }
}

.prim-blue-btn {
  border-radius: 6px !important;
  background-color: #1481de;
  width: 130px;
  height: 40px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 70px;
}

.setngs-quote-drop {
  .ui.selection.dropdown {
    width: 50%;
  }
}
.job-note-email {
    overflow: hidden;

    span {
        height: 24px;
        width: auto;
        border-radius: 6px;
        background-color: #d3e1eb;
        padding: 1px 1px;
        margin-right: 2px;
    }
}

.jobpost-wizard {
    .wizard-actions {
        .btn {
            margin-bottom: 10px !important;
        }
    }
}
.communication-drawer {
    &.side-drwr {
        .drawer-panel {
            overflow: initial !important;
        }
    }
}

.drawer-panel {
    .panel-cont-side {
        height: 100vh;
    }
    @media only screen and (max-width: 576px) {
        width: 100% !important;
        right: -100% !important;
    }
}


.bigger-btn {
  border-color: #34495e;
  color: #34495e;
  font-weight: 600;
  border-radius: 5px;
  &:hover {
    background: #34495e;
    border-color: #34495e;
  }
}

.Toastify {
  .Toastify__toast-container {
    z-index: 99999999999;
    @media only screen and (max-width: 576px) {
      position: fixed !important;
      top: 20px !important;
      bottom: unset !important;
      left: unset !important;
      width: 330px !important;
      right: 0 !important;
      padding: 0 20px !important;
    }
  }
}
.aprv-jb-btns {
  label.btn-primary {
    color: #34495e !important;
    font-weight: 300;
    border: 1px solid #34495e !important;
    width: 195px;

    &.active {
      color: #fff !important;
      background: #34495e !important;
      border: 1px solid #34495e !important;
    }
  }
}
.quote-action-reject{
  background: #ffcbcb;
    border: none;
    width: 130px;
    border-radius: 30px;
}
.quote-action-accepted{
  background: #9cffa4;
    border: none;
    width: 130px;
    border-radius: 30px;
    color: #299532;
}
.pagination{
  margin-bottom: 0;
  .page-item{
    margin: 0 5px;
    .page-link{
      background-color: color(site, lightBlue);
      border: 0 none;
      width: 44px;
      border-radius: 8px;
      height: 44px;
      color: color(site, defaultBlack);
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      &:focus{
        box-shadow: 0 0 0;
      }
    }
    &.active, &:hover{
      .page-link{
        background-color: color(site, primaryColor);
        color: color(site, defaultWhite);
      }
    }
  }
  
  .page-item[title="first page"] .page-link,
  .page-item[title="previous page"] .page-link,
  .page-item[title="next page"] .page-link,
  .page-item[title="last page"] .page-link{
    background-color: color(site, defaultWhite);
    border: 1px solid color(site, primaryColor);
    color: color(site, primaryColor);
    &:hover{
      background-color: #efefef;
    }
  }
}
.credit-card-block{
  input{
      background-color: #fff !important;
  }
}
.ElementsApp .InputElement{
  background-color: #fff !important;
}

.react-viewer-close{
  z-index: 1032 !important;
}
.react-viewer-mask, .react-viewer-canvas, .react-viewer-footer, .ui.dimmer{
  z-index: 1031 !important;
}
.post-summery{
  h4{
    font-size: 14px;
    color: #8b8a96;
    font-weight: 500;
  }
  p{
    font-size: 16px;
    color: color(site, defaultBlack) !important;
  }
}
.ui.dimmer{
  background-color: rgba(0, 0, 0, .50) !important;
}

.custom-modal{
  height: auto !important;
  position: relative !important;
  .close-icon{
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 5px;
    background: #F1F1F2;
    border-radius: 6px !important;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
  .content{
    padding: 25px 25px 0 !important;
    .wizard-header{
      h2{
        color: color(site, defaultBlack) !important;
        font-size: 17px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
      }
      p{
        color: color(site, successColor);
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-style: italic;
      }
    }
    
    p{
      color: color(site, defaultBlack);
      font-size: 12px;
      font-family: 'Poppins', sans-serif;
    }
  }
  .actions{
    border: 0 none !important;
    padding: 20px 10px !important;
    background: color(site, defaultWhite) !important;
    button{
      padding: 10px 16px !important;
    }
  }
}

/* The custom-checkbox */
.customcheck {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.customcheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius:3px;
  background-color: color(site, highlighter);
}

/* On mouse-over, add a grey background color */
.customcheck:hover input ~ .checkmark {
  // background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.customcheck input:checked ~ .checkmark {
  background-color: color(site, highlighter);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.customcheck input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.customcheck .checkmark:after {
  left: 7px;
  top: 4px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.common-tag {
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 5px;
  float: left;
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 600;
  &.small {
    font-size: 10px;
    border-radius: 4px;
    margin-right: 5px;
  }
  &.light-gray {
    background-color: #eff3f6;
    color: #000000;
  }
  &.yellow {
    color: #e1ac5b;
    background-color: #fcf3ce;
  }
  &.dark-yellow {
    background-color: #f7d332;
  }
  &.card-yellow {
    color: #e08700;
    float: none;
    font-weight: 400;
    background-color: #fcf3ce;
  }
  &.red {
    background: color(site, dangerBg);
    color: color(site, dangerColor);
  }
  &.dark-red {
    color: white;
    background-color: #fc573b;
  }
  &.success {
    background-color: #5fdfa4;
    color: white;
  }
  &.green {
    background: color(site, successBg);
    color: color(site, successColor);
  }
  &.light-green {
    color: #27b975;
    background-color: #b2e2cf;
  }
  &.yellowish-green {
    color: #000000;
    background-color: #d9fea8;
  }
  &.extra-light-yellow {
    color: #000000;
    background-color: #f7f0be;
  }
  &.black {
    color: white;
    background-color: #000000;
  }
  &.blue {
    color: white;
    background-color: #0060ff;
  }
  &.rounded {
    border-radius: 20px !important;
  }
  &.orangish-yellow {
    color:white;
    background-color:#F39D3A;
  }
  &.badge-warning{
    background: #e9a164;
    color: #fff;
  }
  &.badge-primary{
      background: #e7f2fc;
      color: #000;
  }
  &.badge-drafted {
    background: color(site, drafted);
    color: color(site, draftedColor);
  }
  &.badge-danger{
    background: color(site, highlighter);
    color: color(site, defaultWhite);
  }
}

.flex-column-section{
  display: flex;
  flex-direction: column;
}
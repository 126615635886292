.modal-inspection-call {
    padding: 0;
    background: #f2f2f2;
}
.inspection-call-wrapper {
    display: flex;
}
.inspection-components {
    width: 290px;
    flex: 0 0 290px;
    .property-summary {
        padding: 20px 20px 15px 20px;
        text-align: center;
        background: $ins-feature-bg;
        h3 {
            font-size: 14px;
            font-weight: 600;
            margin: 0 0 10px 0;
        }
        p {
            font-size: 12px;
            color: $base-color-7;
            margin: 0;
            &.location {
                color: $base-color;
                margin-bottom: 5px;
            }
        }
        .button {
            font-size: 14px;
            margin-top: 10px;
            margin-right: 5px;
            &:last-child {
                margin-right: 0;
            }
        }
        app-profile-viewer {
            img {
                width: 80px;
                height: 80px;
                border-radius: 100%;
            }
        }
    }
    .property-inspection-component-list {
        table {
            width: 100%;
            tbody {
                background: none;
            }
        }
        .property-inspection-component-item {
            &>td {
                padding: 0;
                &>div {
                    padding: 10px 5px 10px 20px;
                    border-bottom: 1px solid $brand-primary-light;
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: #fff;
                    color: $base-color-7;
                    &:before {
                        content: "";
                        width: 8px;
                        background: url(../images/icons/drag-dot.png);
                        position: absolute;
                        top: 4px;
                        bottom: 4px;
                        left: 5px;
                        opacity: 0;
                    }
                    &.selected {
                        background: $brand-primary;
                        color: #fff;
                        border-radius: 5px;
                        &>i {
                            color: $brand-primary;
                            position: absolute;
                            right: 3px;
                        }
                        .button {
                            color: #fff;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                        &:before {
                            opacity: 0.4;
                        }
                    }
                    .component-name {
                        flex: 1 0 auto;
                        width: 0;
                        padding-left: 5px;
                        @include truncate();
                    }
                }
            }                
        }
    }
}
.inspection-details {
    flex-grow: 1;
    padding: 0px 5px 15px 25px;
    .inspection-video-wrapper {
        margin-bottom: 15px;
        // position: sticky;
        // top: 50px;
        z-index: 1;
        background: $ins-card-bg;
    }
    .inspection-video {
        min-height: 150px;
        background: url(../images/icons/play-button.png) no-repeat center center;
        background-size: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .video-playback{
            height: 250px;
            width: auto;
        }
        .inspection-video-note {
            position: absolute;
            bottom: 10px;
            color: $text-light;
        }
        #videos {
            display: flex;
            justify-content: center;
            #subscriber {
                left: unset;
            }
        }
        #publisher {
            display: none;
        }
        .video-controler {
            display: none;
        }
    }
    .inspection-video-controls {
        background: $ins-header-bg;
        padding: 8px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $base-color-7;
        font-size: 11px;
        &>app-property-component-checklist {
            &>h2 {
                font-size: 12px;
                margin: 0;
            }
        }
        button.control-btn {
            background: none;
            border: none;
            color: $text-color;
            display: flex;
            align-items: center;
            font-size: 12px;
            i {
                margin-right: 7px;
                font-size: 14px;
            }
            &:active, &:focus {
                border: none;
                outline: none;
            }
            &:hover {
                cursor: pointer;
            }
            &.end-btn {
                color: #dc3545;
            }
        }
    }
    .custom-control.custom-checkbox {
        margin: 0;
        padding: 0;

        .custom-control-indicator {
            width: 1.3rem;
            height: 1.3rem;
            position: unset;
        }
    }
    .inspection-features {
        background: #fff;
        margin-bottom: 15px !important;
        .component-table {
            width: 100%;
            background: #fff;
            table-layout: fixed;
            td, th {
                &.col-check {
                    text-align: center;
                    width: 115px;
                    padding-left:0;
                    padding-right:0;
                    @include respond-to(laptop){
                        width: 80px;
                        span {
                            @include truncate();
                            display: block;
                        }
                    }
                }
            }
            thead {
                tr {
                    background: #dce4ea;
                    th {
                        padding: 15px 10px;
                        font-size: 12px;
                        font-weight: 600;
                        &.compo-name {
                            font-size: 13px;
                            font-weight: 700;
                            text-transform: uppercase;
                        }
                    }
                }
            }
            tbody {
                border-bottom: 1px solid rgba($base-color,0.1);
                tr {
                    td {
                        padding: 3px 10px;
                        font-size: 12px;
                        .capture-btn {
                            border: none;
                            color: $base-color-7;
                            font-size: 11px;
                            margin: 1px 0px 1px 3px;
                            background: #edf2f5;
                            padding: 5px 7px;
                            border-radius: 3px;
                            position: relative;
                            .loader-sm {
                                position: absolute;
                                transform: scale(0.7);
                                bottom: 2px;
                                transform-origin: center center;
                            }
                            &:hover {
                                cursor: pointer;
                            }
                            &:last-child {
                                margin-right: 0px;
                            }
                            i {
                                font-size: 14px;
                            }
                            &:focus, &:active {
                                outline: none;
                                border: none;
                            }
                        }
                        &.action-col {
                            width: 195px;
                            text-align: right;
                        }
                    }
                    &.attachment-holder {
                        background: #f1f4f5;
                        padding: 10px 15px;                            
                    }
                    &.inspection-history {
                        td {
                            padding: 0;
                            table {
                                tr {
                                    td {
                                        padding: 5px 10px;
                                    }
                                    &.inspection-history-details {
                                        background: #f1f4f5;
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
        }
    }
    .inspection-note {
        .actions {
            text-align: right;
            padding-top: 10px;
        }
    }
    
}
.bg-white {
    background: #fff;
}

.component-list-table {
    display:table;
    width: 100%;
}
.component-list {
    display: table-row;
    .feature-name, .feature-action {
        display: table-cell;
        padding: 5px 0px;
        vertical-align: middle;
    }
    .feature-name {
        padding-right: 20px;
    }
    .feature-action {
        text-align: right;
        button {
            background: none;
            border: none;
            color: $base-color-7;
            font-size: 16px;
            
            &:active, &:focus {
                outline: none;
            }
            &:hover {
                cursor: pointer;
                color: $base-color;
            }
        }
    }
}

.inspection-report-wrapper {
    .ins-report-mainheader {
        padding: 15px 20px;
        border-bottom: 1px solid rgba($base-color,0.2);
        h3 {
            color: $base-color-7;
            font-size: 14px;
            font-weight: 700;
        }
        p {
            font-size: 13px;
            color: $base-color-7;
            margin: 0;
        }
    }
    .card {
        box-shadow: none;
        margin-bottom:2px;
        border-radius: 5px !important;
        background: $white-color;
        .card-header {
            padding: 0;
            background: $form-control-bg;
            border-bottom: none;
            border-radius: 5px !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h5{
                width: 100%;
                button {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    font-size: 12px;
                    color: $brand-primary;
                    justify-content: space-between;
                    font-weight: 400;
                    padding: 10px;
                    i {
                        color: $base-color-7;
                    }
                    &:focus {
                        text-decoration: none;
                    }
                    &:not(.collapsed) {
                        background: rgba(#000,0.01);
                    }
                    &:hover {
                        background: rgba(#000,0.01);
                        text-decoration: none;
                        cursor: pointer;
                    }
                    .item-image {
                        margin-right: 10px;
                        img {
                            width: 35px;
                            height: 35px;
                            border-radius: 15px;
                        }                            
                    }
                    &:not(.collapsed) {
                        .ba-icn-angle-down {
                            transform: rotate(180deg);
                        }
                    }
                }
            }                
        }
        &>.collapse {
            &>.card-body {
                padding: 0;
            }
        }
    }
    .ins-report-content {
        padding: 15px;
        display: flex;
        border-bottom: 1px solid rgba($base-color,0.2);
        color: $base-color-7;
        .ins-component {
            flex: 0 0 200px;
            width: 200px;
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 700;
            margin-right: 20px;
        }
        
    }
    .ins-report {
        flex-grow: 1;
        padding: 10px;
        .ins-report-header {
            display: flex;
            margin-bottom: 20px;
            .header-note {
                flex-grow: 1;
                font-size: 13px;
            }
            .report-video {
                flex: 0 0 85px;
                margin-right: 20px;
                .remove {
                    background: $color-danger;
                    color: #fff;
                    position: absolute;
                    top: 2px;
                    right: 0px;
                    border: none;
                    line-height: 18px;
                    width: 16px;
                    height: 16px;
                    border-radius: 3px;
                    font-size: 11px;
                    cursor: pointer;
                    padding: 0;
                    cursor: pointer;
                    z-index: 999;
                }
                .video-wrapper {
                    background: $light-bg;
                    height: 85px;
                    margin-bottom: 2px;
                }
                .video-placeholder {
                    width: 100%;
                    height: 85px;                    
                    background-size: 30%;
                }
            }
            .expand-btn {
                margin-left: 15px;
                button {
                    &.ellipsis-btn {
                        color: $green-color;
                        background: transparent;
                        border: none;
                        font-size: 25px;
                        padding: 5px 10px;
                        display: block;
                        &:focus, &:active {
                            outline: none;
                        }
                    }
                }
            }
        }
        .ins-component-feature {
            margin-bottom: 5px;
            padding: 15px 0;
            border-top: 1px solid $ins-card-bg;
            h5 {
                font-size: 14px;
                margin-bottom: 10px;
                font-weight: 400;
            }
            p {
                margin-bottom: 12px;
                font-size: 12px;
            }
            @at-root .ins-checks {
                margin: 10px auto;
                label {
                    padding: 2px 15px;
                    border-radius: 20px;
                    background: $button-primary;
                    color: #fff;
                    font-size: 12px;
                    @include flex-center();
                    margin: 2px 5px 0 2px;
                    .status-icon {
                        font-size: 9px;
                        margin-left: -5px;
                        margin-right: 5px;
                    }
                    &.featureInactive {
                        background: $color-danger;
                    }
                    &.featureActive {
                        background: $color-success2;
                    }
                }
            }
            
        }
    }
}

.maintenance-job-btn-container {
    position: relative;
    .capture-more-window {
        position: absolute;
        top: 100%;
        background: #fff;
        padding: 15px 15px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
        width: 350px;
        z-index: 9;
        right: 0;
    }
}

.main-component-list {
    tbody {
        border-bottom: 1px solid $ins-card-bg;
        &:first-child {
            border-top: 1px solid $ins-card-bg;
        }
        tr {
            &:first-child {
                td {
                    vertical-align: middle;
                }
            }
            &:last-child:not(:first-child) {
                td {
                    padding-bottom: 15px;
                }
                &:hover {
                    td {
                        background: none !important;                        
                    }
                }
            }
            h2 {
                font-size: 14px;
            }
            p {
                font-size: 12px;
            }
        }
    }
}

.property-inspection-checklist {
    .checklist-name {
        font-size: 13px;
        font-weight: 500;
    }
    table {
        width: 100%;
        tbody {
            td {
                padding: 5px 0 5px 0;
                line-height: 16px;
            }
        }
    }
    .property-inspection-checklist-items {
        .table {
            tbody {
                tr {
                    border-bottom: 1px solid $brand-primary-light;
                    &:first-child {
                        border-top: 1px solid $brand-primary-light;
                    }
                }
                
            }
        }
    }
}
.create-checklist {
    h2 {
        .button {
            width: 22px;
            height: 22px;
            padding:0;
            border-radius: 100%;
            @include flex-center();
        }
    }
    .create-checklist-sub-form {
        background: $form-control-bg;
        border-radius: 5px;
        margin-bottom: 5px;
        padding: 10px 12px 15px 12px;
        .form-group {
            .form-control {
                background: #fff;
            }
        }
    }
    .table {
        margin-bottom: 0;
        tr {
            td {
                padding: 0;
            }
        }
    }
}

.es-btn {
    display: flex;
    .edit-btn {
        background-color: $base-color-7;
        border-radius: 50%;
        padding: 0px 7px;
        margin-right: 5px;
        width: 20px;
        height: 20px;
    }
    .save-btn {
        background-color: $base-color-7;
        border-radius: 50%;
        padding: 0px 5px;
        width: 20px;
        height: 20px;
    }
    .button {
        background-color: transparent;
        border: 0px;
        padding: 0px;
        i {
            font-size: 8px;
        }
    }
}
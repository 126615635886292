.fixed-top {
    padding: 15px 0 !important;
}

.job-title-header {
    margin-top: 20px;
    h3 {
        font-size: 20px;
        font-weight: 500;
        color: #1d1a34;
    }
    h6 {
        font-size: 14px;
        color: #8b8a96;
        font-weight: 400;
        margin-bottom: 35px;
    }
}

.jobpost-wizard {
    padding: 35px 40px !important;
    h2 {
        margin-bottom: 3px !important;
    }
    p {
        font-size: 14px;
        color: #8b8a96 !important;
        margin-bottom: 25px;
    }
    .form-group {
        .form-control {
            &.rich-control {
                height: 95px !important;
                border: 1px solid #a4d5ff !important;
                margin-bottom: 35px !important;
            }
        }
    }
    .wizard-actions {
        margin-top: 0 !important;
        .btn {
            text-transform: none;
            background: #000;
            color: #fff;
            border-radius: 10px;
            height: 52px !important;
            cursor: pointer;
            font-size: 14px;
        }
    }
    .fileslider {
        margin-bottom: 0 !important;
        .fileslider-item {
            height: 84px !important;
            width: 103px !important;
        }
    }
}

.success-wizard {
    text-align: center;
    margin: 50px auto;
    padding: 0 15px;
    .exp-img {
        height: 90px;
    }
    h2 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 12px;
    }
    h6 {
        font-size: 14px;
        font-weight: 400;
        color: #27b975;
        font-style: italic;
        margin-bottom: 20px;
    }
    p {
        font-size: 12px;
        color: #8b8a96;
        line-height: 1.5;
    }
}
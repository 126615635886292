.uploaded-img {
    width: 100px;
    margin-right: 2px;
}

.mention {
    height: 24px;
    width: 65px;
    border-radius: 6px;
    background-color: #d3e1eb;
    padding: 3px 3px;
    margin-right: 2px;
    -webkit-user-select: all;
    user-select: all;
}
@charset "UTF-8";
/* BASE */
.calendar__row {
  display: flex;
  flex-direction: row; }

.calendar__cell {
  flex: 1; }

/* THEME */
body {
  margin: 10px; }

body,
html {
  background: #fff;
  color: #555;
  font-family: "Lato", sans-serif;
  font-size: 14px; }

* {
  box-sizing: border-box; }

@media only screen and (max-width: 576px) {
  .container {
    max-width: 96%; } }

.calendar__body {
  position: relative; }

.calendar__cell {
  height: 40px; }

.calendar__cell--appointment {
  display: flex;
  position: relative;
  border-top: #e5e5e5 solid 1px;
  border-right: #f4f4f4 solid 1px; }

.calendar__cell--time-spacing {
  flex: 6px 0 0;
  border-right: none; }

.calendar__cell--day-of-week {
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.calendar__cell--day-of-week__day {
  font-size: 1.1em;
  color: #999; }

.calendar__cell--day-of-week__date {
  font-size: 2.5em; }

.calendar__cell--day-of-week--today .calendar__cell--day-of-week__day,
.calendar__cell--day-of-week--today .calendar__cell--day-of-week__date {
  color: #fc3d39; }

.calendar__cell--weekend {
  background: #fafafa; }

.calendar__cell--time-col {
  flex: 50px 0 0; }

.calendar__cell--time {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1em;
  transform: translateY(-50%);
  font-size: 0.9em;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: center; }
  .calendar__cell--time:after {
    content: "";
    background: #ddd;
    height: 4px;
    width: 4px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border-radius: 50%; }

.calendar__row--deco-last-row {
  height: 0; }
  .calendar__row--deco-last-row .calendar__cell--appointment {
    height: 0; }

.calendar__appointment {
  background: #e0f6d8;
  color: #0c881d;
  border: #bfecaf solid 1px;
  border-left: #76db56 solid 2px;
  padding: 4px 6px;
  font-size: 12px;
  position: absolute;
  top: -1px;
  right: -1px;
  left: 0;
  z-index: 1;
  min-height: 100%; }

.calendar__appointment__name {
  font-weight: bold; }

.calendar__current-time {
  height: 1px;
  background-color: #fc3d39;
  position: absolute;
  right: 0px;
  left: 10px;
  z-index: 1; }

/* TODO: La hora actual está quedando fuera del bloque
   * del calendario.
   */
.calendar__current-time__text {
  font-family: "Roboto Condensed", sans-serif;
  color: #fc3d39;
  position: absolute;
  top: 50%;
  transform: translate3d(-100%, -50%, 0);
  margin-left: -2px; }

/* INTERACTIONS */
.calendar__row:hover .calendar__cell--time {
  color: #555; }
  .calendar__row:hover .calendar__cell--time:after {
    background-color: #aaa; }

.calendar__row:hover + .calendar__row .calendar__cell--time {
  color: #555; }
  .calendar__row:hover + .calendar__row .calendar__cell--time:after {
    background-color: #aaa; }

.event-scheduler .ds-row {
  display: flex; }
  .event-scheduler .ds-row .ds-titlespace {
    width: 160px;
    min-width: 160px;
    flex: 0 0 80px; }
    .event-scheduler .ds-row .ds-titlespace .ds-title-details {
      height: 40px;
      margin-bottom: 10px;
      padding: 3px 5px;
      display: flex;
      align-items: center;
      background: #e8f1f1;
      position: relative;
      cursor: pointer; }
      .event-scheduler .ds-row .ds-titlespace .ds-title-details .ds-title {
        flex: 1 1 auto;
        font-size: 11px;
        font-weight: 600;
        width: calc(100% - 30px);
        position: relative; }
        .event-scheduler .ds-row .ds-titlespace .ds-title-details .ds-title span {
          display: block;
          max-width: 95%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
    .event-scheduler .ds-row .ds-titlespace .space-holder {
      font-size: 14px;
      font-weight: 700;
      background: none;
      height: 40px;
      margin-bottom: 0;
      line-height: 1em; }
  .event-scheduler .ds-row .ds-dataspace {
    width: calc(100% - 110px);
    flex: 1 1 auto;
    overflow-x: auto; }
    .event-scheduler .ds-row .ds-dataspace .ds-data {
      position: relative;
      display: flex;
      width: 100%;
      height: 40px;
      margin-bottom: 10px; }
      .event-scheduler .ds-row .ds-dataspace .ds-data .ds-timeslots {
        background: transparent;
        font-size: 11px;
        font-weight: 600;
        border-top: 0;
        border-bottom: 0;
        display: flex;
        flex: 1;
        position: relative;
        border-top: 1px solid #eceeee;
        border-bottom: 1px solid #eceeee; }
        .event-scheduler .ds-row .ds-dataspace .ds-data .ds-timeslots .ds-timeslot {
          border-right: 1px solid #eceeee;
          display: flex;
          align-items: center; }
          .event-scheduler .ds-row .ds-dataspace .ds-data .ds-timeslots .ds-timeslot .droppable {
            width: 100%;
            height: 100%;
            overflow: hidden;
            background: rgba(184, 214, 214, 0.1); }
        .event-scheduler .ds-row .ds-dataspace .ds-data .ds-timeslots .suggested {
          position: relative;
          cursor: pointer; }
          .event-scheduler .ds-row .ds-dataspace .ds-data .ds-timeslots .suggested .droppable {
            background: #89befc; }
          .event-scheduler .ds-row .ds-dataspace .ds-data .ds-timeslots .suggested .working-slot-pararl {
            background: #deff4d;
            height: 50%;
            z-index: 9;
            width: 100%;
            position: absolute;
            bottom: 0; }
        .event-scheduler .ds-row .ds-dataspace .ds-data .ds-timeslots .not-available {
          position: relative; }
          .event-scheduler .ds-row .ds-dataspace .ds-data .ds-timeslots .not-available .droppable {
            background: #fc8989; }
          .event-scheduler .ds-row .ds-dataspace .ds-data .ds-timeslots .not-available .working-slot-pararl {
            background: #deff4d;
            height: 50%;
            z-index: 9;
            width: 100%;
            position: absolute;
            bottom: 0; }
        .event-scheduler .ds-row .ds-dataspace .ds-data .ds-timeslots .working-slot .droppable {
          background: #deff4d; }
        .event-scheduler .ds-row .ds-dataspace .ds-data .ds-timeslots .available {
          position: relative;
          cursor: pointer; }
          .event-scheduler .ds-row .ds-dataspace .ds-data .ds-timeslots .available .droppable {
            background: #8afc8a; }
          .event-scheduler .ds-row .ds-dataspace .ds-data .ds-timeslots .available .working-slot-pararl {
            background: #deff4d;
            height: 50%;
            z-index: 9;
            width: 100%;
            position: absolute;
            bottom: 0; }
    .event-scheduler .ds-row .ds-dataspace .title-holder {
      height: 40px;
      margin-bottom: 0;
      line-height: 1em; }
      .event-scheduler .ds-row .ds-dataspace .title-holder .ds-timeslots {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent; }
        .event-scheduler .ds-row .ds-dataspace .title-holder .ds-timeslots .ds-timeslot {
          border-right: 1px solid transparent;
          display: flex;
          align-items: center; }

.timeslot-label {
  font-weight: 700 !important;
  font-size: 14px !important; }

.available-timeslot {
  width: 50px;
  height: 35px;
  background: #8afc8a;
  align-items: center;
  display: flex;
  text-align: center; }

.not-available-timeslot {
  width: 50px;
  height: 35px;
  background: #fc8989;
  align-items: center;
  display: flex;
  text-align: center; }

.working-timeslot {
  width: 50px;
  height: 35px;
  background: #deff4d;
  align-items: center;
  display: flex;
  text-align: center; }

.suggested-timeslot {
  width: 50px;
  height: 35px;
  background: #89befc;
  align-items: center;
  display: flex;
  text-align: center; }

.add-new-events {
  background: none !important;
  border: 0px !important; }
  .add-new-events label {
    font-size: 14px !important; }
  .add-new-events .plus-icon {
    border-radius: 15px !important;
    height: 25px !important;
    width: 80px !important;
    font-size: 12px !important; }

.calender-icon {
  font-size: 17px;
  display: inline-flex;
  position: relative;
  bottom: -2px;
  border: 1px solid #e9ecef;
  padding: 6px;
  padding-right: 7px; }

@import "../../Styles/mixins";
@import "../../Styles/theme";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

body {
  background: color(site, bodyBg);
  padding-bottom: 25px;
  @include respond-to(phablet) {
    padding-bottom: 90px;
  }
}
.tenant-chat {
    body {
        padding-bottom: 0px;
    }
}
// .container {
//     max-width: 100%;
// }
.main-nav-items {
  .dropdown.nav-item {
    .nav-link {
      padding: 0px;
      margin-left: 8px;
      .user-image {
        border-radius: 14px;
        width: 30px;
        height: 30px;
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
  }
}

.main-page-wrapper {
 // margin-right: 40px;
  @include respond-to(phablet) {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.dropdown-menu {
  font-size: 13px;
  border-radius: 3px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0;
  .dropdown-item {
    padding: 10px;
    color: color(site, txtColor);
  }
}

.font-13 {
  font-size: 13px !important;
}

.font-25 {
  font-size: 25px !important;
}

.font-400 {
  font-weight: 400 !important;
}

.font-600 {
  font-weight: 600 !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.custom-control {
  .custom-radio {
    display: flex;
    align-items: flex-start;
    padding-left: 0;
    min-height: 25px;
    margin-bottom: 0;
    cursor: pointer;
  }
}
.card {
  border: none;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
}

.navbar {
  .edit-btn {
    background: #e2f3ff;
    border: none;
    padding: 0 12px;
    height: 30px;
    color: #4ba1eb;
    border-radius: 5px;
    font-weight: 400;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    .ba-icn {
      margin-right: 5px;
      font-size: 14px;
    }
  }

  .with-left-margin {
    margin-left: 5px;
  }

  .approve-btn {
    background: #a5ffa5;
    border: none;
    padding: 0 12px;
    height: 30px;
    color: #156f3c;
    border-radius: 5px;
    font-weight: 400;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .reject-btn {
    background: #ffc2bb;
    border: none;
    padding: 0 12px;
    height: 30px;
    color: #f54935;
    border-radius: 5px;
    font-weight: 400;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
}

.external-card {
  border-radius: 20px;
  // box-shadow: 0 3px 20px rgba(0, 0, 0, 0.05);
  padding: 25px 20px 20px 20px;
  color: #1f1e31;
  @include respond-to(phablet) {
    display: flex;
    flex-direction: column;
  }

  .menu-bar {
    border: 0px;
    padding: 0px;
    .nav {
      background: white;
      padding-left: 30px;
      border-bottom: 2px solid #f7f9fc;
    }
    .nav-item {
      .nav-link {
        color: #AAABB4;
        height: 50px;
        font-size: 18px;
        font-weight: 500;
        @include respond-to(phablet) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      .active {
        color: #EB4C4B;
        border-bottom: 1px solid #EB4C4B;
        padding-bottom: 50px;
      }
    }
  }
  .card-text {
    font-size: 14px;
  }
  .card-body {
    padding: 30px 30px 20px 30px;
    @include respond-to(phablet) {
      padding: 20px;
    }
    .job-details-data {
      .job-number {
        font-weight: 600;
        font-size: 12px;
        color: #bcc0c3;
      }

      .h-55 {
        height: 55px;
      }

      .job-description {
        font-weight: 600;
      }

      .job-details-item {
        .row-icon {
          padding: 2px 2px 2px 2px;
          text-align: center;

          .icon-desc {
            font-size: 10px;
            width: 75px;
          }
        }

        .row-label {
          padding: 8px 5px;
        }

        .primary-job-type {
          font-weight: 600;
          padding: 0px 5px;
        }

        .sub-job-type {
          font-weight: 500;
        }

        .key-collection-option {
          width: 300px;
          font-weight: 600;
          padding: 0px 5px;

          .fileslider {
            background-color: #fff;
          }
        }
      }
    }
  }
  .disclaimer-wrapper {
    margin-top: 10px;
    border-radius: 16px;
    border: 1px solid rgba(255, 160, 71, 0.26);
    background-color: rgba(255, 160, 71, 0.1);
    padding: 20px;
    h4 {
      color: #FFA147;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.external-timeline {
}

.add-new-image {
  font-size: 50px;
  font-weight: bolder;
}

.edit-job-actions {
  border: 0px;

  .btn-primary {
    background: #fff;
    border: 0 solid #fff;
    border-radius: 15px;
    margin-left: 5px;
    color: #4ba1eb;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 6px 15px;
    font-size: 12px;
    line-height: normal;
    font-weight: 700;
    font-family: Open Sans, sans-serif;
  }
  .btn-reject {
    background: #fff;
    border: 0 solid #fff;
    border-radius: 15px;
    margin-left: 5px;
    color: #ff7741;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 6px 15px;
    font-size: 12px;
    line-height: normal;
    font-weight: 700;
    font-family: Open Sans, sans-serif;
  }
  .btn-approve {
    background: #fff;
    border: 0 solid #fff;
    border-radius: 15px;
    margin-left: 5px;
    color: #3dc176;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 6px 15px;
    font-size: 12px;
    line-height: normal;
    font-weight: 700;
    font-family: Open Sans, sans-serif;
  }
}
.notify-bell {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  background: color(site, primary);
  z-index: 2;
  @include respond-to(phablet) {
    bottom: 3px;
  }
  @include flex-center();
  border-radius: 100%;
  a {
    @include flex-center();
    color: #fff;
    i {
      font-size: 40px;
      animation: vibrate 2s;
      animation-iteration-count: infinite;
    }
  }
}
.ext-detail-box {
  margin-top: 15px;
  display: table;
  @include respond-to(phablet) {
    width: 100%;
  }
  .ext-detail-row {
    display: table-row;
    // background-color: #bcc0c3;
    .ext-icon-col {
      display: table-cell;
      padding: 5px 0;
      text-align: center;
      vertical-align: top;
      i {
        font-size: 25px;
      }
      & > span {
        display: block;
        font-size: 10px;
        line-height: 1em;
        min-height: 20px;
      }
    }
    .ext-detail-col {
      display: table-cell;
      padding: 5px 0 5px 20px;
      vertical-align: top;
      & > span {
        padding-top: 10px;
        display: block;
      }
    }
  }
}
.external-gallery {
  margin-top: -3px;
  .fileslider {
    background: none;
    padding: 0;
    display: flex !important;
    margin-bottom: 0;
    .fileslider-item {
      width: 58px !important;
      height: 48px !important;
      margin: 0 5px 0 5px !important;
      background: #eff3f6;
      border-radius: 8px;
      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;        
        cursor: pointer;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.external-job-attachments{
  .fileslider{
    padding-left: 18px;
  }
}

.card-details-row {
  min-height: 85px;
  width: auto;
  background: #F7F9FC;
  padding: 20px 15px;
  margin-bottom: 10px;
  border-radius: 16px;
}

.ext-card-actions {
  margin-top: 30px;
  .edit-button {
    border-radius: 10px;
    color: #1481de;
    background-color: rgba(20, 129, 222, 0.1);
    border: 1px solid rgba(20, 129, 222, 0.32);
    padding: 12px 27px;
    font-weight: 500;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    .ba-icn {
      margin-right: 5px;
    }
  }
  .post-button {
    border-radius: 10px;
    color: #fff;
    background-color: #000;
    border: 1px solid #000;
    padding: 12px 30px;
    font-weight: 500;
    &.with-left-margin {
      margin-left: 10px;
    }
  }
}
.app-download-wrapper {
  background-color: color(site, primary);
  // background-image: url(../../images/app-download-bg.jpg);
  // background-repeat: no-repeat;
  // background-position: center bottom;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  background-size: 55%;
  padding: 35px 35px 0;
  text-align: center;
  @include respond-to(phablet) {
    padding: 20px 20px 0;
  }
  .app-download-close {
    font-size: 16px;
  }
  h1 {
    font-size: 55px;
    font-weight: 300;
    color: #fff;
    @include respond-to(phablet) {
      font-size: 35px;
    }
    .font-600 {
      display: block;
      font-weight: 600;
    }
  }
  p {
    width: 100%;
    padding: 0 20px;
    max-width: 540px;
    font-size: 20px;
    color: #fff;
    margin: 0 auto 20px;
    @include respond-to(phablet) {
      font-size: 15px;
    }
  }
  .download-app-links {
    img {
      margin: 0 5px;
    }
  }
  .bg-fixer {
    img {
      max-width: 650px;
      width: 100%;
      height: auto;
    }
  }
}

.attachments-slide {
  .card.h-100 {
    .img-thumbnail {
      height: 100px;
    }
  }
}

@keyframes vibrate {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  3% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  6% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  9% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  12% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  15% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  18% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  21% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  24% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  27% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  30% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  33% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  36% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  39% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  42% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  45% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}

#notfound {
  position: relative;
  height: 50vh;
  .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  .notfound-404 {
    position: relative;
    height: 105px;
    margin: 0px auto 20px;
    z-index: -1;
    .title {
      font-family: "Montserrat", sans-serif;
      font-size: 120px;
      font-weight: 200;
      margin: 0px;
      color: #211b19;
      text-transform: uppercase;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    .sub-title {
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      color: #211b19;
      padding: 10px 5px;
      margin: auto;
      display: inline-block;
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      width: fit-content;
    }
  }
  a {
    font-family: "Montserrat", sans-serif;
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    padding: 13px 23px;
    font-size: 18px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    &:hover {
      color: #ff6300;
      background: #211b19;
    }
  }
}
@media only screen and (max-width: 767px) {
  .notfound {
    .notfound-404 {
      h1 {
        font-size: 148px;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .notfound {
    .notfound-404 {
      height: 148px;
      margin: 0px auto 10px;
      h1 {
        font-size: 86px;
      }
      h2 {
        font-size: 16px;
      }
    }
    a {
      padding: 7px 15px;
      font-size: 14px;
    }
  }
}

.trkng-will-atnd {
  background: #E6E6E6 !important;
  border: 0 solid #E6E6E6 !important;
  color: #000 !important;
  border-radius: 15px !important;
  padding: 6px 15px !important;
  font-weight: 600 !important;
  outline:none;
  line-height: normal;
}
.trkng-apprv-btn {
  background: #27B975 !important;
  border: 0 solid #27B975 !important;
  color: #fff !important;
  border-radius: 15px !important;
  padding: 6px 15px !important;
  font-weight: 600 !important;
  outline:none;
  line-height: normal;
}
.trkng-rjct-btn {
  background: #E84B4A !important;
  border: 0 solid #E84B4A !important;
  color: #fff !important;
  border-radius: 15px !important;
  padding: 6px 15px !important;
  font-weight: 600 !important;
  outline:none;
  line-height: normal;
}
@font-face {
  font-family: 'ba-icn';
  src:  url('../fonts/ba-icn.eot?malsq2');
  src:  url('../fonts/ba-icn.eot?malsq2#iefix') format('embedded-opentype'),
    url('../fonts/ba-icn.ttf?malsq2') format('truetype'),
    url('../fonts/ba-icn.woff?malsq2') format('woff'),
    url('../fonts/ba-icn.svg?malsq2#ba-icn') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ba-icn {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ba-icn' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ba-icn-file-merge:before {
  content: "\e9e3";
}
.ba-icn-info:before {
  content: "\e9e2";
}
.ba-icn-settings:before {
  content: "\e9e1";
}
.ba-icn-dot-menu:before {
  content: "\e9df";
}
.ba-icn-flash:before {
  content: "\e9e0";
}
.ba-icn-refresh-new:before {
  content: "\e9de";
}
.ba-icn-timeline-menu:before {
  content: "\e9da";
}
.ba-icn-help-menu:before {
  content: "\e9db";
}
.ba-icn-chat-menu:before {
  content: "\e9dc";
}
.ba-icn-bell-menu:before {
  content: "\e9dd";
}
.ba-icn-omni-channel:before {
  content: "\e9d8";
}
.ba-icn-upload:before {
  content: "\e9d7";
}
.ba-icn-maintenance-settings:before {
  content: "\e9d6";
}
.ba-icn-smart-lock-audit:before {
  content: "\e9c2";
}
.ba-icn-minus-circle:before {
  content: "\e9ae";
}
.ba-icn-camera-line:before {
  content: "\e9ab";
}
.ba-icn-money:before {
  content: "\e9ac";
}
.ba-icn-moving-clock:before {
  content: "\e9ad";
}
.ba-icn-user-profile:before {
  content: "\e9aa";
}
.ba-icn-merge:before {
  content: "\e9a9";
}
.ba-icn-support:before {
  content: "\e9a8";
}
.ba-icn-notifications:before {
  content: "\e9a5";
}
.ba-icn-timeline1:before {
  content: "\e9a6";
}
.ba-icn-messages:before {
  content: "\e9a7";
}
.ba-icn-plus-circle:before {
  content: "\e9a4";
}
.ba-icn-ok-boxed:before {
  content: "\e9a3";
}
.ba-icn-camera:before {
  content: "\e99f";
}
.ba-icn-compare:before {
  content: "\e9a0";
}
.ba-icn-bath-tub:before {
  content: "\e99c";
}
.ba-icn-car:before {
  content: "\e99d";
}
.ba-icn-furniture:before {
  content: "\e99e";
}
.ba-icn-sync-log:before {
  content: "\e99b";
}
.ba-icn-rules:before {
  content: "\e99a";
}
.ba-icn-bulb:before {
  content: "\e999";
}
.ba-icn-notes:before {
  content: "\e998";
}
.ba-icn-warn:before {
  content: "\e997";
}
.ba-icn-battery-status:before {
  content: "\e9b0";
}
.ba-icn-battery-low .path1:before {
  content: "\e9b1";
  color: rgb(102, 130, 144);
}
.ba-icn-battery-low .path2:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(252, 87, 59);
}
.ba-icn-battery-full .path1:before {
  content: "\e9b3";
  color: rgb(102, 130, 144);
}
.ba-icn-battery-full .path2:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(130, 212, 143);
}
.ba-icn-battery-full .path3:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(130, 212, 143);
}
.ba-icn-battery-full .path4:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(130, 212, 143);
}
.ba-icn-battery-full .path5:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(130, 212, 143);
}
.ba-icn-file-color .path1:before {
  content: "\e9cb";
  color: rgb(254, 225, 135);
}
.ba-icn-file-color .path2:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.ba-icn-file-color .path3:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.ba-icn-file-color .path4:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.ba-icn-file-color .path5:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.ba-icn-file-color .path6:before {
  content: "\e9d0";
  margin-left: -1em;
  color: rgb(254, 225, 135);
}
.ba-icn-file-color .path7:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.ba-icn-file-color .path8:before {
  content: "\e9d2";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.ba-icn-file-color .path9:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.ba-icn-file-color .path10:before {
  content: "\e9d4";
  margin-left: -1em;
  color: rgb(254, 225, 135);
}
.ba-icn-file-color .path11:before {
  content: "\e9d5";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.ba-icn-property-color .path1:before {
  content: "\e9c3";
  color: rgb(255, 231, 119);
}
.ba-icn-property-color .path2:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(255, 231, 119);
}
.ba-icn-property-color .path3:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(255, 231, 119);
}
.ba-icn-property-color .path4:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(255, 231, 119);
}
.ba-icn-property-color .path5:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(255, 231, 119);
}
.ba-icn-property-color .path6:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(255, 210, 0);
}
.ba-icn-property-color .path7:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(255, 210, 0);
}
.ba-icn-property-color .path8:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(255, 210, 0);
}
.ba-icn-org-color .path1:before {
  content: "\e9b8";
  color: rgb(255, 238, 192);
}
.ba-icn-org-color .path2:before {
  content: "\e9b9";
  margin-left: -1em;
  color: rgb(255, 238, 192);
}
.ba-icn-org-color .path3:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(255, 238, 192);
}
.ba-icn-org-color .path4:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.ba-icn-user-group-color .path1:before {
  content: "\e9bc";
  color: rgb(255, 238, 192);
}
.ba-icn-user-group-color .path2:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(255, 238, 192);
}
.ba-icn-user-group-color .path3:before {
  content: "\e9be";
  margin-left: -1em;
  color: rgb(255, 238, 192);
}
.ba-icn-user-group-color .path4:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(255, 238, 192);
}
.ba-icn-user-group-color .path5:before {
  content: "\e9c0";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.ba-icn-user-group-color .path6:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.ba-icn-grid-view .path1:before {
  content: "\e993";
  color: rgb(255, 231, 119);
}
.ba-icn-grid-view .path2:before {
  content: "\e994";
  margin-left: -1em;
  color: rgb(255, 210, 0);
}
.ba-icn-grid-view .path3:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(255, 231, 119);
}
.ba-icn-grid-view .path4:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(255, 210, 0);
}
.ba-icn-hamburger-menu .path1:before {
  content: "\e98d";
  color: rgb(255, 231, 119);
}
.ba-icn-hamburger-menu .path2:before {
  content: "\e98e";
  margin-left: -1.0048828125em;
  color: rgb(255, 231, 119);
}
.ba-icn-hamburger-menu .path3:before {
  content: "\e98f";
  margin-left: -1.0048828125em;
  color: rgb(255, 231, 119);
}
.ba-icn-hamburger-menu .path4:before {
  content: "\e990";
  margin-left: -1.0048828125em;
  color: rgb(255, 210, 0);
}
.ba-icn-hamburger-menu .path5:before {
  content: "\e991";
  margin-left: -1.0048828125em;
  color: rgb(255, 210, 0);
}
.ba-icn-hamburger-menu .path6:before {
  content: "\e992";
  margin-left: -1.0048828125em;
  color: rgb(255, 210, 0);
}
.ba-icn-external-schedule:before {
  content: "\e98c";
}
.ba-icn-my-jobs:before {
  content: "\e900";
}
.ba-icn-bell-muted:before {
  content: "\e986";
}
.ba-icn-advance-search:before {
  content: "\e985";
}
.ba-icn-property-manager:before {
  content: "\e982";
}
.ba-icn-property-owner:before {
  content: "\e983";
}
.ba-icn-property-tenant:before {
  content: "\e984";
}
.ba-icn-controls:before {
  content: "\e981";
}
.ba-icn-import:before {
  content: "\e97c";
}
.ba-icn-integration-approvals:before {
  content: "\e97d";
}
.ba-icn-organisation:before {
  content: "\e97e";
}
.ba-icn-properties:before {
  content: "\e97f";
}
.ba-icn-trades2:before {
  content: "\e980";
}
.ba-icn-minus:before {
  content: "\e97a";
}
.ba-icn-plus2:before {
  content: "\e97b";
}
.ba-icn-locate:before {
  content: "\e979";
}
.ba-icn-user-external:before {
  content: "\e977";
}
.ba-icn-user-group:before {
  content: "\e978";
}
.ba-icn-img:before {
  content: "\e976";
}
.ba-icn-sales:before {
  content: "\e975";
}
.ba-icn-warning:before {
  content: "\e974";
}
.ba-icn-inspections:before {
  content: "\e973";
}
.ba-icn-priority-filter .path1:before {
  content: "\e96d";
  color: rgb(255, 161, 71);
}
.ba-icn-priority-filter .path2:before {
  content: "\e96e";
  margin-left: -1.142578125em;
  color: rgba(52, 152, 219, 0.62);
}
.ba-icn-priority-filter .path3:before {
  content: "\e96f";
  margin-left: -1.142578125em;
  color: rgba(235, 76, 75, 0.6);
}
.ba-icn-status-filter .path1:before {
  content: "\e970";
  color: rgb(255, 161, 71);
}
.ba-icn-status-filter .path2:before {
  content: "\e971";
  margin-left: -1em;
  color: rgba(52, 152, 219, 0.62);
}
.ba-icn-status-filter .path3:before {
  content: "\e972";
  margin-left: -1em;
  color: rgba(53, 185, 74, 0.62);
}
.ba-icn-eye-close:before {
  content: "\e96b";
}
.ba-icn-eye:before {
  content: "\e96c";
}
.ba-icn-logout:before {
  content: "\e969";
}
.ba-icn-login:before {
  content: "\e96a";
}
.ba-icn-work-order-reject:before {
  content: "\e967";
}
.ba-icn-information:before {
  content: "\e966";
}
.ba-icn-feedback-complete:before {
  content: "\e964";
}
.ba-icn-folder:before {
  content: "\e963";
}
.ba-icn-inspection:before {
  content: "\e95d";
}
.ba-icn-credit-card:before {
  content: "\e95e";
}
.ba-icn-gears:before {
  content: "\e95f";
}
.ba-icn-structure:before {
  content: "\e960";
}
.ba-icn-unlock:before {
  content: "\e9af";
}
.ba-icn-lock:before {
  content: "\e961";
}
.ba-icn-plug:before {
  content: "\e962";
}
.ba-icn-request-revision:before {
  content: "\e95b";
}
.ba-icn-mute:before {
  content: "\e959";
}
.ba-icn-muted:before {
  content: "\e95a";
}
.ba-icn-health:before {
  content: "\e950";
}
.ba-icn-printer:before {
  content: "\e94f";
}
.ba-icn-font-size:before {
  content: "\e94b";
}
.ba-icn-font-up:before {
  content: "\e948";
}
.ba-icn-font-down:before {
  content: "\e949";
}
.ba-icn-accept-reject:before {
  content: "\e947";
}
.ba-icn-down-arrow:before {
  content: "\e940";
}
.ba-icn-right-arrow:before {
  content: "\e9d9";
}
.ba-icn-up-arrow:before {
  content: "\e98b";
}
.ba-icn-bar-chart:before {
  content: "\e93a";
}
.ba-icn-shared-folder:before {
  content: "\e93b";
}
.ba-icn-speedometer:before {
  content: "\e93c";
}
.ba-icn-upgrade:before {
  content: "\e939";
}
.ba-icn-video-record:before {
  content: "\e938";
}
.ba-icn-line-chart:before {
  content: "\e935";
}
.ba-icn-download:before {
  content: "\e92d";
}
.ba-icn-value:before {
  content: "\e92b";
}
.ba-icn-manager:before {
  content: "\e93e";
}
.ba-icn-engineer:before {
  content: "\e93d";
}
.ba-icn-user:before {
  content: "\e94a";
}
.ba-icn-user-plus:before {
  content: "\e903";
}
.ba-icn-refresh:before {
  content: "\e95c";
}
.ba-icn-repeat:before {
  content: "\e90d";
}
.ba-icn-timeline:before {
  content: "\e914";
}
.ba-icn-work-order:before {
  content: "\e934";
}
.ba-icn-add-note:before {
  content: "\e942";
}
.ba-icn-invoice:before {
  content: "\e953";
}
.ba-icn-address-book:before {
  content: "\e90f";
}
.ba-icn-notepad:before {
  content: "\e94c";
}
.ba-icn-checklist:before {
  content: "\e941";
}
.ba-icn-feedback:before {
  content: "\e91a";
}
.ba-icn-group:before {
  content: "\e91c";
}
.ba-icn-clone:before {
  content: "\e919";
}
.ba-icn-trades-remind:before {
  content: "\e965";
}
.ba-icn-trades:before {
  content: "\e927";
}
.ba-icn-change-payer:before {
  content: "\e94d";
}
.ba-icn-reject-revise-quote:before {
  content: "\e956";
}
.ba-icn-revise-quote:before {
  content: "\e957";
}
.ba-icn-increase-quote-limit:before {
  content: "\e954";
}
.ba-icn-place-quote:before {
  content: "\e91e";
}
.ba-icn-assign-job:before {
  content: "\e951";
}
.ba-icn-reassign-job:before {
  content: "\e952";
}
.ba-icn-job-variation-add:before {
  content: "\e925";
}
.ba-icn-job-variation:before {
  content: "\e91d";
}
.ba-icn-job-review:before {
  content: "\e98a";
}
.ba-icn-job-send:before {
  content: "\e968";
}
.ba-icn-job-reject:before {
  content: "\e987";
}
.ba-icn-job-done:before {
  content: "\e958";
}
.ba-icn-job-add:before {
  content: "\e946";
}
.ba-icn-briefcase:before {
  content: "\e911";
}
.ba-icn-history:before {
  content: "\e9a2";
}
.ba-icn-clock:before {
  content: "\e918";
}
.ba-icn-schedule-approve:before {
  content: "\e955";
}
.ba-icn-schedule-lock:before {
  content: "\e944";
}
.ba-icn-schedule-unlock:before {
  content: "\e945";
}
.ba-icn-datetime:before {
  content: "\e936";
}
.ba-icn-calendar-share:before {
  content: "\e91b";
}
.ba-icn-calendar-gray:before {
  content: "\e920";
}
.ba-icn-bathtub:before {
  content: "\e907";
}
.ba-icn-bed:before {
  content: "\e908";
}
.ba-icn-garage:before {
  content: "\e909";
}
.ba-icn-key:before {
  content: "\e928";
}
.ba-icn-property-import:before {
  content: "\e93f";
}
.ba-icn-building:before {
  content: "\e943";
}
.ba-icn-house:before {
  content: "\e90a";
}
.ba-icn-home:before {
  content: "\e923";
}
.ba-icn-questions:before {
  content: "\e931";
}
.ba-icn-worldwide:before {
  content: "\e932";
}
.ba-icn-ios:before {
  content: "\e930";
}
.ba-icn-android:before {
  content: "\e933";
}
.ba-icn-share:before {
  content: "\e921";
}
.ba-icn-send:before {
  content: "\e924";
}
.ba-icn-view-map:before {
  content: "\e937";
}
.ba-icn-pin:before {
  content: "\e90b";
}
.ba-icn-recover-bin:before {
  content: "\e922";
}
.ba-icn-bin:before {
  content: "\e90e";
}
.ba-icn-paper-clip:before {
  content: "\e926";
}
.ba-icn-edit:before {
  content: "\e90c";
}
.ba-icn-search:before {
  content: "\e910";
}
.ba-icn-mobile:before {
  content: "\e917";
}
.ba-icn-webcam:before {
  content: "\e904";
}
.ba-icn-chat:before {
  content: "\e901";
}
.ba-icn-envelope:before {
  content: "\e916";
}
.ba-icn-phone:before {
  content: "\e902";
}
.ba-icn-snooze:before {
  content: "\e989";
}
.ba-icn-bell:before {
  content: "\e92c";
}
.ba-icn-notification:before {
  content: "\e915";
}
.ba-icn-full-screen:before {
  content: "\e92e";
}
.ba-icn-full-screen-exit:before {
  content: "\e92f";
}
.ba-icn-maximize:before {
  content: "\e913";
}
.ba-icn-minimize:before {
  content: "\e906";
}
.ba-icn-plus:before {
  content: "\e912";
}
.ba-icn-close:before {
  content: "\e905";
}
.ba-icn-ok:before {
  content: "\e91f";
}
.ba-icn-sort-up-down:before {
  content: "\e9a1";
}
.ba-icn-angle-up:before {
  content: "\e988";
}
.ba-icn-angle-down:before {
  content: "\e94e";
}
.ba-icn-angle-left:before {
  content: "\e929";
}
.ba-icn-angle-right:before {
  content: "\e92a";
}

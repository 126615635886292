.notes{
    & .view-btn{
        background: #fff;
        border: 0 solid #fff;
        border-radius: 15px;
        color: #4ba1eb;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 6px 15px;
        font-size: 12px;
        line-height: normal;
        font-weight: 700;
        font-family: Open Sans, sans-serif;
        outline:none;
        text-decoration:underline;
    }

    & .view-btn:hover{
        background: #4ba1eb;
        color: #fff;
    }

}
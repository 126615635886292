@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNextCyr-Light.woff2') format('woff2'),
        url('../fonts/AvenirNextCyr-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNextCyr-Medium.woff2') format('woff2'),
        url('../fonts/AvenirNextCyr-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNextCyr-Regular.woff2') format('woff2'),
        url('../fonts/AvenirNextCyr-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNextCyr-Demi.woff2') format('woff2'),
        url('../fonts/AvenirNextCyr-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins-Light';
    src: url('../fonts/Poppins-Light.woff') format('woff'), url('../fonts/Poppins-Light.woff2') format('woff2'), url('../fonts/Poppins-Light.svg#Poppins-Light') format('svg'), url('../fonts/Poppins-Light.eot'), url('../fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/Poppins-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins-Regular';
    src: url('../fonts/Poppins-Regular.woff') format('woff'), url('../fonts/Poppins-Regular.woff2') format('woff2'), url('../fonts/Poppins-Regular.svg#Poppins-Regular') format('svg'), url('../fonts/Poppins-Regular.eot'), url('../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins-Medium';
    src: url('../fonts/Poppins-Medium.woff') format('woff'), url('../fonts/Poppins-Medium.woff2') format('woff2'), url('../fonts/Poppins-Medium.svg#Poppins-Medium') format('svg'), url('../fonts/Poppins-Medium.eot'), url('../fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../fonts/Poppins-SemiBold.woff') format('woff'), url('../fonts/Poppins-SemiBold.woff2') format('woff2'), url('../fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg'), url('../fonts/Poppins-SemiBold.eot'), url('../fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'), url('../fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins-Bold';
    src: url('../fonts/Poppins-Bold.woff') format('woff'), url('../fonts/Poppins-Bold.woff2') format('woff2'), url('../fonts/Poppins-Bold.svg#Poppins-Bold') format('svg'), url('../fonts/Poppins-Bold.eot'), url('../fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@import "../../../Styles/theme";

.job-labels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.job-labels label {
    font-size: 12px;
    font-weight: 600;
}
label{
    color: rgba(0,50,75,0.8);
}
.job-details-table {
    display: table;
    width: 100%;
    margin-bottom: 15px;
    color: rgba(54,80,93,0.9);
    font-size: 12px;
    .job-details-table-row {
        display: table-row;
        .table-label, .table-data {
            display: table-cell;
            padding-bottom: 6px;
        }
        .table-label {
            width: 120px;
            color: rgba(42, 142, 208, 0.8);
            text-align: right;
            padding-right: 13px;
            vertical-align: top;
        }
    }

}
.react-datepicker-wrapper {
    width: 100%;
}
.hide-results .ui.search .results {
    display: none!important;
    visibility: hidden !important;
}
.profile-stats {
    background: #f9f9f9;
    border-radius: 3px;
    margin: 15px 0;
    display: flex;
    justify-content: space-around;
    background: rgba(255,255,255,.6);
    padding: 22px 5px;

    .stat {
        border-left: none;
        color: rgba(0,50,75,.7);
        display: flex;
        align-items: center;
        flex: 1 1 auto;
        border-left: 1px solid rgba(0,50,75,.1);

        .stat-count {
            font-size: 35px;
            font-weight: bold;
            padding: 0 15px;
            justify-content: space-around;
        }
        .stat-count-mini {
            font-size: 20px;
            font-weight: bold;
            padding: 0 15px;
            justify-content: space-around;
        }
        .stat-details {
            font-size: 14px;
            justify-content: space-around;
            line-height: 1.3em;
            
            span {
                display: block;
            }
            span:last-child {
                font-size: 20px;
            }
        }
    }
}

.ellipsis {
    display: flex;
    flex-direction: column;
    height: 18px;
    justify-content: space-between;

    span {
        width: 4px;
        height: 4px;
        background: #606162;
        border-radius: 100%;
    }
}

.profile-badges {
    display: flex;
    flex-wrap: wrap;

    .profile-badge {
        height: 65px;
        margin-right: 15px;
        margin-bottom: 15px;
        border-radius: 5px;
        padding: 10px 10px;
        background-color: #fff;
        display: flex;
        align-items: center;
        box-shadow: 0 2px 3px #dae0e4;

        .abn-badge {
            background-image: url(/Images/abn.svg);
            width: 45px;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 100%;
        }

        .cm3-badge {
            background-image: url(/Images/cm3.svg);
            width: 45px;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 100%;
        }
    }
}

.subtoggle {
    background: #fff;
    padding: 5px;
    margin: 10px 0 35px 0;
    box-shadow: 0 5px 5px rgba(0,0,0,.1);
    border-radius: 3px;

    .sub-toggle-container {
        max-height: 400px;
        overflow-y: auto;
    }
}

.received-feedBack-main {
    border-collapse: collapse;
    margin-bottom: 10px;
    tbody {
        border: none !important;
    }

    td {
        padding-top: 10px;
        padding-bottom: 10px;
        color: rgba(0,50,75,.7);
        font-size: 12px;
        border:none;
        vertical-align: middle;
        .text-light {
            color: #606162 !important;
        }

        .font-600 {
            font-weight: 600 !important;
        }
    }
    tr {
        &.view-feedback {
            background: color(site, feedbackBg);
            td {
                padding-top: 2px;
                padding-bottom: 2px;
            }
            &>td {
                padding-top:10px;
                padding-bottom: 10px;
            }
        }
    }
}
.jobpost-main-details {
    .ui.search {
        .results.transition {
            z-index: 2 !important;
        }
    }
}
$base-color: #00324B;
$base-color-7: #668290;
$primary-color: rgba( $base-color, .4 );
$brand-primary: #eb4c4b;
//$green-color: #00d0a0;
$green-color: $brand-primary;
$light-green: rgba($green-color, .2);
$light-color: #a0a0a0;
$light-dark-color: rgba(0, 50, 75, 0.8);
$white-color: #FFF;
$text-color: #333;
$text-light: #606162;
$color-danger : #dc3545;
$color-primary: #007bff;
$color-success: rgba(53, 185, 74, 0.62) ;
$color-success2: #11a35b;
$color-warning: #ffc107;
$button-primary: #7e97a4;
$highlight: #81d38e;
$brand-primary-light: #e2f3ff;

$form-control-bg: #eff3f6;

$body-bg: #eff3f6;
$light-bg: #eff3f6;

$card-rds : 5px;
$timeline-width: 260px;
$sidebar-width: 225px;
$sidebar-min-width: 45px;
$header-bg: #dce5eb;

$job-tray-bg: #dce5eb;

$font-stack: 'Open Sans', sans-serif;

//inspection
$ins-header-bg: #dce5eb;
$ins-card-bg: #eef3f6;
$ins-feature-bg: #f4f6f8;

@mixin respond-to($media) {
    @if $media == mobileSmall {
        @media only screen and (max-width: 415px) {
            @content;
        }
    }
    @if $media == mobile {
        @media only screen and (max-width: 568px) {
            @content;
        }
    }
    @else if $media == phablet {
        @media only screen and (max-width: 767px) {
            @content;
        }
    }
    @else if $media == tabssam {
        @media only screen and (max-width: 991px) {
            @content;
        }
    }
    @else if $media == tabs {
        @media only screen and (max-width: 1023px) {
            @content;
        }
    }
    @else if $media == tabsDektop {
        @media only screen and (max-width: 1200px) {
            @content;
        }
    }
    @else if $media == desktop {
        @media only screen and (max-width: 1336px) {
            @content;
        }
    }
    @else if $media == laptop {
        @media only screen and (max-width: 1440px) {
            @content;
        }
    }
    @else if $media == xlarge {
        @media only screen and (max-width: 1600px) {
            @content;
        }
    }
    @else if $media == xxlarge {
        @media only screen and (min-width: 1601px) {
            @content;
        }
    }
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: "") {
    -webkit-box-shadow: $top $left $blur $color #{$inset};
    -moz-box-shadow: $top $left $blur $color #{$inset};
    -ms-box-shadow: $top $left $blur $color #{$inset};
    -o-box-shadow: $top $left $blur $color #{$inset};
    box-shadow: $top $left $blur $color #{$inset};
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
}


// generic transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}
// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}
// translate
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}

/*-----------Table Mixin-----------*/

@mixin li-width($col, $width) {
    #{$col} {
        width: $width;
    }
}

@mixin list-columns($widths...) {
    $n: 1;
    $var: "&:nth-child(" + $n + ")";

    @each $width in $widths {
        @include li-width($var, $width);
        $n: $n + 1;
        $var: "&:nth-child(" + $n + ")";
    }
}

// clearfix mixin
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin truncate($width: 100%) {
    max-width: $width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin flex-center($flex: inline-flex){
    display: $flex;
    justify-content: center;
    align-items: center;
}
@mixin button() {
        border-radius: 5px;
        display: inline-flex;
        border: 1px solid #fff;
        padding: 3px 15px 4px;
        align-items: center;
        background: #fff;
        color: $brand-primary;
        font-size: 13px;
        &.button-light-bg {
            background: #eff3f6;
        }
        &.selected {
            background: $brand-primary;
            color:#fff;
        }

        &:disabled {
            cursor: no-drop !important;
            opacity: 0.5;
        }

        &.button-sm {
            font-size: 12px;
            padding: 1px 10px;
        }
        &.button-l {
            padding: 6px 30px;
            font-size: 16px;
        }

        &.button-line { 
            background: #fff;
            color: $base-color-7;
            border: 1px solid $base-color-7;
        }

        &.button-rounded {
            border-radius: 30px;
            padding: 3px 15px 4px;

            &.button-sm {
                padding: 1px 10px;
            }
            
        }

        &.button-secondary {
            background: $brand-primary-light;
            border-color: $brand-primary-light;
            color: $brand-primary;
        }

        i {
            margin-right: 3px;

            &.ti {
                font-size: 0.8em;
            }
        }

        cursor: pointer;

        &:hover:not(:disabled) {
            opacity: 0.9;
            cursor: pointer;
        }

        &:focus {
            outline: none;
        }

        &:after {
            display: none;
        }


        &.button-fill {
            background: $base-color-7;
            border-color: $base-color-7;
            color: #fff;
        }

        &.button-primary {
            background: $green-color;
            border-color: $green-color;
            color: #fff;
            &.button-line {
                background: #fff;
                color: $brand-primary;
            }
        }

        &.button-danger {
            background: $color-danger;
            border-color: $color-danger;
            color: #fff;
        }

        &.button-warning {
            background: $color-warning;
            border-color: $color-warning;
            color: #fff;
        }

        &.button-reversed {
            background: #fff;
            border-color: #fff;
            color: $base-color-7;
        }

        &.button-success {
            background: $color-success;
            border-color: $color-success;
            color: #fff;
        }

        &.button-default {
            background: #7e97a4;
            border-color: #7e97a4;
            color: #fff;
        }

        &.button-selected {
            background: $highlight !important;
            border-color: $highlight !important;
            color: #fff;
        }

        &.attached-gallery { //custom gallery btn
            margin-top: 5px;
            padding-left: 8px;
            padding-right: 10px;

            i {
                font-size: 15px;
            }
        }
}

// .button-line-hover {
//     &:hover:not(:disabled) {
//         cursor: pointer;
//         background: #eb4c4b;
//         color: #fff !important;
//     }
// }


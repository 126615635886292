.googl-img-job {
    .btn-group-toggle {

        flex-direction: row !important;
        margin-top: 20px !important;

        label {
            padding: 0 !important;
            min-width: auto !important;
            width: fit-content;
            border-radius: 10px !important;
            border: 5px solid !important;
            margin: 5px !important;
            border-color: #f3f3f3 !important;

            &.active{
                border: 5px solid #27b975 !important;
            }

            img {
                border-radius: 10px;
                width: 100px;
                height: 100px;
            }
        }
    }
}

.home-wrapper {
    padding-top: 40px !important;
    h3 {
        font-size: 14px;
        font-weight: 500;
    }
    .logo {
        width: 188px !important;
    }
    .attachments-card {
        background: #fff;
        padding: 40px;
        border-radius: 20px;
        h6 {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.25;
            margin-bottom: 0;
        }
        .attach-body {
            margin-top: 20px;
            .fileslider {
                background-color: #f7f9fc;
                border-radius: 10px;
                padding: 30px;
                .fileslider-item {
                    width: 177px !important;
                    height: 111px !important;
                    margin: 0 15px 15px 0 !important;
                    border-radius: 3px !important;
                }
            }
        }
    }
    .navbar {
        margin-bottom: 50px !important;
    }
}
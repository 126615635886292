@import "src/Styles/mixins";
@import "../../../Styles/theme";

.height-auto {
    height: auto !important;
    top: auto !important;
    left: auto !important;
}

.settings-container {
    display: flex;
    align-items: stretch;
    padding-bottom: 10px;
    @include respond-to(phablet) {
        flex-direction: column;
    }
    .settings-menu {
        width: 270px;
        flex: 0 0 270px;
        margin-right: 15px;
        border-radius: 5px;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
        background: #fff;
        min-height: calc(100vh - 150px);
        @include respond-to(phablet) {
            width: 100%;
            min-height: auto;
            flex-basis: auto;
            padding-bottom: 10px;
        }
        h2 {
            font-size: 30px;
            color: color(site, txtColor);
            border-bottom: 1px solid #ebebeb;
            padding: 15px 0 10px 20px;
            font-weight: 300;
        }
        .settings-menu-items {
            margin: 0;
            padding: 0 0 0 20px;
            list-style: none;
            @include respond-to(phablet) {
                display: flex;
                justify-content: space-between;
                flex-direction: row !important;
                padding: 0 20px;
            }
            & > .nav-item {
                .nav-link {
                    position: relative;
                    font-size: 14px;
                    padding: 15px 5px 15px 45px;
                    color: color(site, txtColor);
                    background: none;
                    @include respond-to(phablet) {
                        padding: 15px;
                    }
                    span {
                        @include respond-to(phablet) {
                            display: none;
                        }
                    }

                    i {
                        font-size: 25px;
                        position: absolute;
                        left: 0;
                        top: 12px;
                        @include respond-to(phablet) {
                            position: unset;
                        }
                    }
                    &.active {
                        color: color(site, highlighter);
                    }
                    &:hover {
                        color: color(site, highlighter);
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .settings-details {
        width: calc(100% - 310px);
        flex: 1 1 auto;
        padding: 15px 20px;
        border-radius: 5px;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
        background: #fff;
        @include respond-to(phablet) {
            width: 100%;
            margin-top: 15px;
        }
        h2 {
            font-size: 18px;
            border-bottom: 1px solid #ebebeb;
            margin: 0 0 25px 0;
            padding: 4px 0 13px;
            font-weight: 400;
            color: color(site, txtColor);
            i {
                font-size: 23px;
                position: relative;
                top: 2px;
                margin-right: 5px;
            }
        }
        h3 {
            font-size: 14px;
            color: color(site, txtColor);
            font-weight: 400;
        }
    }
    .switch-table {
        margin: 0 -15px;
        tr {
            td {
                vertical-align: middle;
                padding: 5px 15px;
                color: color(site, txtLightGrey);
                h3 {
                    margin-bottom: 0;
                }
            }
            &:hover {
                td {
                    background: #fbfbfb;
                }
            }
        }
        .sub-setting-row {
            & > td {
                background: #fbfbfb;
                .sub-setting {
                    padding: 10px;
                }
            }
        }
    }
}

.settings-cont {
    padding: 0;
    border-radius: 16px;
    background-color: #fff;

    .settings-nav {
        border-bottom: 2px solid #f7f9fc ;
        .nav-item {
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            font-family: 'Poppins', sans-serif;
            line-height: 1.11;
            letter-spacing: 0.26px;
            @include respond-to(tabssam) {
                font-size: 13px;
              }
            span {
                color: #000 !important;
            }

            .active {
                background: none;
                border-bottom: 2px solid color(site, highlighter);
                border-radius: 0;
                span {
                    color: color(site, highlighter) !important;
                    font-weight: 500;
                }
            }
            a{
                padding: 22px 0;
                margin-left: 15px;
                margin-right: 15px;
                font-weight: 400;
            }
        }
    }
    .nav-body{
        padding: 30px;
    }
}

.toggle-bar{
    h4{
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        color: color(site, defaultBlack);
    }
    p{
        font-size: 12px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        color: #8b8a96;
    }
}

.back-btn.go-bk {
    width: max-content;
    height: 30px;
    padding: 7px 24px 7px 18px;
    border-radius: 9px;
    background-color: #eff3fb;
    color: #4d4d4d;
    font-weight: 800;
    span {
      text-decoration: none;
    }
  }

  .credit-card-default {
    background: #454545;
    border-radius: 5px;
    min-height: 200px;
    min-width: 100%;
    max-width: 100%;
    margin-top: 10px;
    color: #fff;
    @include respond-to(tabssam) {
        min-width: auto;
        max-width: 100%;
    }
    &.lo-hight-crd {
        min-height: 145px !important;
    }
    .card-type {
        font-size: 15px;
        font-weight: 700;
    }
    .card-data {
        padding-top: 35px;
        font-size: 12px;
        strong {
            display: block;
            font-size: 14px;
            font-weight: 600;
        }


    }
}
.credit-card-block-inner{
    width:400px;
    padding:25px 20px;
    background: #f7f9fc;
    border-radius: 5px;
    margin-bottom: 10px;
    max-width: 100%;
}


.credit-card {
    background: #f7f9fc;
    //border: 1px solid #95afc0;
    border-radius: 5px;
    min-height: 200px;
    min-width: 100%;
    width: 100%;
    margin-top: 10px;

    &.second-card {
        background: #eff3f6;
    }

    .card-type {
        font-size: 15px;
        font-weight: 700;
    }

    .card-data {
        padding-top: 35px;
        font-size: 12px;

        strong {
            display: block;
            font-size: 14px;
            font-weight: 600;
        }
        .btn-setdefault {
            background: #1481de;
        }
        .btn-remove {
            background: #fc573b;
        }
    }

    .btn-success {
        background: $brand-primary !important;
        border-color: $brand-primary !important;
    }
}

.pricing-card {
    border-radius: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
    margin-bottom: 5px;
    border: none;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    display: flex;
    position: relative;

    .list-group-item {
        border: 1px solid rgba(0, 0, 0, 0.05) !important;
    }

    .form-control {
        background: #eff3f6;
        border: 1px solid #eff3f6;
        font-size: 13px;
        padding: 8px 10px;
        height: 34px;
    }

    select.form-control {
        height: 34px !important;
        padding: 3px 10px;
    }

    .list-style-check {
        width: 23px;
        height: 23px;
        margin-right: 10px;
        margin-left: 5px;
        border-radius: 100%;
        padding: 6px;
        color: #2ec6e6;
        background: #e6f8fc;
        border: none;
        line-height: 0px;
        font-size: 10px;
    }
}
.card {
    .form-control {
        background: #eff3f6;
        border: 1px solid #eff3f6;
        font-size: 13px;
        padding: 3px 10px;
        height: 43px;
        &.text-area-height {
            height: 80px !important;
        }
    }

    .form-control-menu {
        background: #eff3f6;
        border: 1px solid #eff3f6;
        font-size: 13px;
        padding: 3px 10px;
        height: 34px;
    }

    .btn-setdefault {
        height: 34px;
        background: #1481de;
    }
    .btn-remove {
        height: 34px;
        background: #fc573b;
    }
}

.boxshadow{
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.text-purple {
    color: #621084 !important;
}

.bg-purple {
    background-color: #621084 !important;
}

.bg-light-purple{
    background-color: rgba(98, 16, 132, 0.12) !important;
    border: solid 1px !important;
}

.bg-dark{
    background-color: #414141!important;
}

.bg-dark {
    background-color: #414141 !important;
}

.bg-red{
    background-color: #e24343 !important;
}

.text-dark {
    color: #343F4D !important;
}

.text-light {
    color: #98A9BC !important;
}

.text-red {
    color: #FF0000 !important;
}

.text-light-dark {
    color: #414141 !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fw-300 {
    font-weight: 300;
}

.fw-500 {
    font-weight: 500;
}

.top-nav-bar {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 26px 0;

    a {
        img {
            width: 60%;
            max-width: 152px;
        }
    }
}

.bg-light-blue {
    background-color: #F7F9FC;
    width: 100%;
}

.white-box {
    max-width: 666px;
    width: 100%;
}

.border-radius-22-5 {
    border-radius: 22.5px;
    border:  1px #621084;
}

.badge {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 10px;
    font-size: 15px;
}

.btn-size {
    max-width: 170px;
    height: 40px;
    width: 100%;
    text-transform: uppercase;
}

.asset-form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    input,
    textarea {
        max-width: 478px;
        width: 100%;
        padding: 20px;
    }

    input {
        height: 52px;
    }

    .img-upld-cont {
        max-width: 478px;
        width: 100%;

        .rich-control {
            height: 113px;

            div {
                width: 100%;
                flex-direction: column;
                color: #4D7CFE;
                gap: 5px;

                .icon {
                    font-size: 24px;
                }

                label {
                    color: #4D7CFE;
                }

                .plus-icon {
                    display: none;
                }
            }
        }
    }

    .datepicker-input {
        position: relative;

        img {
            position: absolute;
            right: 30px;
            top: 40px;
            width: 20px;
        }
    }
}

.full-height {
    padding: 8vh 0;
}

.asset-count {
    color: #414141;
    font-size: 14px;
    line-height: 21px;
    padding: 15px;
}

.asset-label{
    color: #98a9bc;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
}

.asset-img{
    width: 30px;
}

.new-asset{
    font-size: 15px;
    color: #4d7cfe;
    font-weight: 500;
    border: none;
    background-color: unset;
    font-size: 15px;
    background: none;
    outline: none;
}

button.new-asset:focus {
    outline: none !important;
}

.add-asset{
    position: absolute;
    right: 15px;
    top: 45px;
    z-index: 999;
}

.v-align{
    vertical-align: sub;
}

.delete-icon {
        width: 14px;
        margin-left: 10px;
}
.btn-link {
    .accordian-icon {
        margin-top: 4px;
    }
}
.rotate {
    .accordian-icon{
        margin-top: 0px!important;
    }
}
.min-height-40 {
    min-height:40px;
}

.asset-type {
    font-size: 15px;
    color: #98a9bc;
}

.asset-detail{
    font-size: 15px;
    color: #414141;
}

.edit-asset{
    color: #4d7cfe;
    font-size: 15px;
    font-weight: 500;
    border: none;
    background-color: unset;
}

button.edit-asset:focus {
    outline: none !important;
}

.delete-asset{
    color: #e24343;
    font-size: 15px;
    font-weight: 500;
    border: none;
    background-color: unset;
}

button.delete-asset:focus {
    outline: none !important;
}

.rotate {
    transform: rotate(180deg);
    /* Rotate 180 degrees */
    transition: transform 0.3s ease;
    /* Smooth transition */
    border: none;
    background-color: unset;
}

button.rotate:focus {
    outline: none !important;
}

.expand {
    border: none;
    background-color: unset;
}

button.rotate:focus {
    outline: none !important;
}

.expand {
    border: none;
    background-color: unset;
}

.custom-card-header {
    padding-top: 0px !important;
    padding-bottom: 0px !important; 
}

.border-bottom{
    border-bottom: 1px solid #d3d9e0 !important;
}

.form-color{
    background-color: #f7f7f7 !important;
}


.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's above other content */
}

.popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.popup p {
    margin-bottom: 20px;
}

.popup button {
    background-color: #007bff; /* Bootstrap blue */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
}

.popup button:hover {
    background-color: #0056b3; /* Darker shade of blue on hover */
}

.pt-100{
    padding-top: 150px;
}

.text-area-input{
    // height: 60px!important;
    min-height: 60px;
}
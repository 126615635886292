.job-notes-drawer-container {
  .job-note {
    border-bottom: 1px solid #9697a1;
    padding: 15px 0;

    .user-name {
      font-size: 14px;
      font-weight: 550;
      color: black;
    }

    .posted-date {
      font-size: 12px;
      font-weight: 500;
      color: #9697a1;
      background-color: #e7ecf7;
      padding: 10px;
      border-radius: 10px;
    }

    .user-type {
      font-size: 12px;
      font-weight: 500;
      color: black;
      background-color: #fff9dd;
      padding: 10px;
      border-radius: 10px;
    }

    .note-type {
      color: #9697a1;
      font-size: 14px;
    }

    .job-note-email {
      overflow: hidden;
      font-size: 14px;
      span {
        height: 24px;
        width: auto;
        border-radius: 6px;
        background-color: #d3e1eb;
        padding: 1px 1px;
        margin-right: 2px;
      }
    }

  }

  .job-note:last-child {
    border-bottom: unset !important;
  }
  
}

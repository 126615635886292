@import "src/Styles/mixins";

//text helper classes
.font-300 {
  font-weight: 300 !important;
}

.font-400 {
  font-weight: 400 !important;
}

.font-500 {
  font-weight: 500 !important;
}

.font-600 {
  font-weight: 600 !important;
}

.font-700 {
  font-weight: 700 !important;
}

.font-bold {
  font-weight: bold !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}
.font-22 {
  font-size: 22px !important;
}

.font-25 {
  font-size: 25px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-italics{
  font-style: italic !important;
 }
 .font-green{
  color: #27B975;
 }
.badge {
  font-size: 10px;
  padding: 7px 10px;  
  font-weight: 500 !important;
  border-radius: 6px;

  &.badge-danger,
  &.urgent {
    background: color(site, highlighter);
    color: color(site, defaultWhite);
  }

  &.badge-primary,
  &.non-urgent {
    background: #e7f2fc;
    color: #000000;
  }

  &.badge-warning {
    background: #e9a164;
    color: color(site, defaultWhite);
  }

  &.badge-success {
    background: color(site, successBg);
    color: color(site, successColor);
  }

  &.badge-status {
    background: color(site, statusBg);
    color: color(site, statusColor);
  }

  &.badge-default {
    background: color(site, defaultBg);
    color: color(site, defaultColor);
  }
  &.badge-grey {
    background: color(site, lightGreyBG);
    color: color(site, darkGreyText);
  }
  &.badge-lightblue {
    background: #e7f2fc;
    color: color(site, defaultColor);
  }
  &.badge-waiting-confirmation {
    background: color(site, waitingConfirmation);
    color: color(site, waitingConfirmationColor);
  }

  &.badge-schedule-confirmed {
    background: color(site, scheduleConfirmed);
    color: color(site, scheduleConfirmedColor);
  }

  &.badge-schedule-rejected {
    background: color(site, scheduleRejected);
    color: color(site, scheduleRejectedColor);
  }

  &.badge-inspection-completed {
    background: #27b975;
    color: color(site, defaultWhite);
  }

  &.badge-drafted {
    background: color(site, drafted);
    color: color(site, draftedColor);
  }

  &.badge-date-proposed {
    background: color(site, dateProposed);
    color: color(site, dateProposedColor);
  }
}
.label {
  font-size: 11px;
  border-radius: 5px;
  padding: 2px 10px;
  margin-bottom: 0;
  &.label-danger {
    background: color(site, dangerBg);
    color: color(site, dangerColor);
  }
  &.label-primary {
    background: color(site, primaryBg);
    color: color(site, primaryColor);
  }
  &.label-warning {
    background: color(site, warningBg);
    color: color(site, warningColor);
  }
  &.label-success {
    background: color(site, successBg);
    color: color(site, successColor);
  }
  &.label-default {
    background: color(site, defaultBg);
    color: color(site, defaultColor);
  }
}

.required-icon {
  color: color(site, dangerColor);
}

.Toastify {
  .Toastify__toast--error,
  .Toastify__toast--success {
    border-radius: 15px;
    padding: 8px 8px 8px 15px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
    &:hover {
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.6);
    }
    .Toastify__close-button {
      color: rgba(#fff, 0.6);
      font-size: 12px;
    }
    .Toastify__progress-bar {
      background-color: rgba(#fff, 0.3);
    }
  }
  .Toastify__toast--error {
    background: color(site, toastErrBg);
    color: color(site, toastErrColor);
  }

  .Toastify__toast--success {
    background: color(site, toastSuccessBg);
    color: color(site, toastSuccessColor);
  }
}

.radio-btn-group {
  .btn-group {
    flex-wrap: wrap;
    justify-content: flex-start;
    label {
      &.btn {
        flex: 0 0 auto;
        margin-right: 5px;
        margin-bottom: 5px;
        border-radius: 15px !important;
        background: color(site, selectBtnBg);
        border-color: color(site, selectBtnBg);
        color: color(site, txtColor);
        &.active,
        &:focus,
        &:active {
          background: color(site, selectedBtnBg);
          border-color: color(site, selectedBtnBg);
          color: color(site, selectedBtnColor);
          box-shadow: none;
        }
        &:not(:disabled) {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
.file-upload {
  border-radius: 5px;
  background-color: color(site, txtLightGrey);
  background-image: url(/Images/attached-file.svg);
  background-repeat: no-repeat;
  background-size: 50px auto;
  background-position: 15px 15px;
  display: block;
  padding: 15px 15px 10px 78px;
  min-height: 80px;
  span {
    color: #fff;
    display: block;
    margin: 0 0 0 0;
    font-size: 13px;
  }
  &:hover {
    cursor: pointer;
  }
}
.filepond--wrapper {
  margin-top: 5px;
}
.filepond--drop-label.filepond--drop-label label {
  text-align: left;
  background-color: color(site, formControlBg);
  background-image: url(/Images/attached-file.svg);
  padding: 15px 15px 10px 78px;
  background-repeat: no-repeat;
  background-size: 50px auto;
  background-position: 15px 15px;
  font-weight: 600;
  min-height: 80px;
  border-radius: 5px;
}

.custom-control {
  .custom-control-input {
    &:checked {
      & ~ .custom-control-label {
        &::before {
          border-color: color(site, accentColor);
          background-color: color(site, accentColor);
        }
      }
    }
  }
  .custom-control-label {
    padding-top: 3px;
  }
}

//text
.paragraph {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.main-headding {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.sub-headding {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

a {
  color: color(primary, base);
}

a:hover {
  color: color(primary, shadeone);
}

//Card Styles
.card-wrapper {
  .grid-container {
    .table {
      background: #fff;
    }
  }
}
.card-headding {
  font-size: 1.2rem;
  font-weight: 500;
  color: color(primary, base);
  border-bottom: 1px solid color(grey, shadetwo);
  margin: 5px;
  margin-bottom: 20px;
  font-weight: 400;
  letter-spacing: 0;

  p {
    margin-bottom: 5px;
  }
}

//Grid Style
.grid-container {
  .table-bordered td,
  .table-bordered th,
  table,
  .react-bs-table-bordered {
    border: none;
  }

  .react-bootstrap-table {
    overflow-x: auto;
    table {
      table-layout: auto;
    }
  }

  table {
    margin-bottom: 0;
    caption {
      display: none;
    }
  }

  thead {
    font-size: 12px;
    background: color(site, theadBg);
    border-bottom: none;
    color: color(site, theadColor);
    tr {
      th {
        padding: 9px 15px;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid color(grey, shadethree) !important;
      td {
        font-size: 12px;
        padding: 8px 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: color(site, tdColor);
      }
      &:hover {
        background: rgba(color(site, primary), 0.1);
        cursor: pointer;
      }
    }
  }

  .row.react-bootstrap-table-pagination {
    justify-content: center;
    padding: 15px 5px 5px;
    background: #fff;
    margin: 0;
    & > * {
      width: auto;
      flex-basis: auto;
    }
    #pageDropDown {
      font-size: 12px;
      padding: 2px 5px;
      background-color: color(site, formControlBg);
      border-color: color(site, formControlBg);
      color: color(site, txtColor);
      &:active,
      &:focus {
        box-shadow: none;
      }
    }
    .dropdown-menu {
      .dropdown-item {
        font-size: 12px;
        padding: 5px 10px;
        a {
          color: color(site, txtColor);
          &:hover {
          }
        }
        cursor: pointer;
      }
    }
    .pagination {
      margin-bottom: 0;
      .page-item {
        .page-link {
          border: none;
          color: color(site, txtColor);
          border-radius: 2px;
          padding: 3px 8px;
          font-size: 12px;
          &:focus {
            box-shadow: none;
          }
        }
        &.active {
          .page-link {
            background-color: color(site, theadBg);
            color: #fff;
          }
        }
      }
    }
  }

  .page-link {
    color: color(primary, base);
  }

  &.custom-responsive-grid {
    @include respond-to(phablet) {
      table {
        display: flex;
        thead {
          tr {
            th {
              display: block;
              width: 105px;
              height: 36px;
              &:last-child {
                text-align: left;
              }
            }
          }
        }
        tbody {
          display: flex;
          overflow-x: auto;
          tr {
            td {
              display: block;
              padding-top: 9px;
              padding-bottom: 9px;
              height: 36px;
              &:last-child {
                text-align: left;
              }
            }
          }
        }
      }
    }

    &.document-list-grid {
      @include respond-to(phablet) {
        table {
          thead {
            tr {
              th {
                width: 130px;
              }
            }
          }
        }
      }
    }

    &.property-inspection-grid {
      @include respond-to(phablet) {
        table {
          thead {
            tr {
              th {
                width: 145px;
              }
            }
          }
        }
      }
    }
  }
}

.separate-list {
  padding: 10px 0px;
  border-bottom: 1px solid color(site, formControlBg);
}

// Job post lock button

.ckeck-button {
  background-color: color(site, formControlBg);
  border-color: color(site, formControlBg);
  color: color(site, txtColor);
  border-radius: 50px;
  overflow: auto;
  display: inline-block;
  margin-right: 5px;
  label {
    display: flex;
    width: fit-content;
    margin-bottom: 0px;
    input {
      position: absolute;
      opacity: 0;
    }
    span {
      text-align: center;
      padding: 7px 13px;
      display: block;
    }
  }
  input:checked + span {
    background: color(site, selectedBtnBg);
    border-color: color(site, selectedBtnBg);
    color: color(site, selectedBtnColor);
  }
}
// Buttons
.btn-primary {
  color: color(white, base);
  background-color: color(primary, base);
  border-color: color(primary, base);
  font-size: 0.8rem;
}

.btn-primary:hover {
  color: color(white, base);
  background-color: color(primary, shadeone);
  border-color: color(primary, shadeone);
}

.react-bs-table-tool-bar .form-control {
  height: calc(1.2em + 0.75rem + 4px);
  font-size: 0.9rem;
}

.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.txt-justify {
  text-align: justify;
}
.ellipsis-btn {
  background: transparent;
  border: none;
  font-size: 20px;
  padding-left: 15px;
  color: color(site, txtColor);
  & :hover {
    color: #eb4c4b;
  }
}

//margins
.m-s {
  margin: 5px;
}

.m-m {
  margin: 10px;
}

.m-l {
  margin: 15px;
}

.m-x-s {
  margin: 0 5px;
}

.m-x-m {
  margin: 0 10px;
}

.m-x-l {
  margin: 0 15px;
}

.m-y-s {
  margin: 5px 0;
}

.m-y-m {
  margin: 10px 0;
}

.m-y-l {
  margin: 15px 0;
}

.m-t-s {
  margin-top: 5px;
}

.m-t-m {
  margin-top: 10px;
}

.m-t-l {
  margin-top: 15px;
}

.m-b-s {
  margin-bottom: 5px;
}

.m-b-m {
  margin-bottom: 10px;
}

.m-b-l {
  margin-bottom: 15px;
}

//padding
.p-s {
  padding: 5px;
}

.p-m {
  padding: 10px;
}

.p-l {
  padding: 15px;
}

.p-x-s {
  padding: 0 5px;
}

.p-x-m {
  padding: 0 10px;
}

.p-x-l {
  padding: 0 15px;
}

.p-y-s {
  padding: 5px 0;
}

.p-y-m {
  padding: 10px 0;
}

.p-y-l {
  padding: 15px 0;
}

.p-t-s {
  padding-top: 5px;
}

.p-t-m {
  padding-top: 10px;
}

.p-t-l {
  padding-top: 15px;
}

.p-b-s {
  padding-bottom: 5px;
}

.p-b-m {
  padding-bottom: 10px;
}

.p-b-l {
  padding-bottom: 15px;
}

//Floating
.f-left {
  float: left;
}

.f-right {
  float: right;
}

.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fileslider {
  display: flex;
  flex-wrap: wrap;
  .fileslider-item {
    width: 75px;
    height: 45px;
    margin: 2px;
    display: inline-flex;
    background: color(site, theadBg);
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      cursor: pointer;
    }
  }
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  border-width: 1px;
  border-style: solid;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  &.ui {
    color: #fff;
  }

  &.button-rounded {
    border-radius: 20px;
  }
  &.button-default {
    background: color(site, btnDefault);
    border-color: color(site, btnDefault);
  }
  &.button-primary {
    background: color(site, primary);
    border-color: color(site, primary);
  }
  &.button-reject {
    background-color: color(site, dangerColor);
    border-color: color(site, dangerColor);
  }
  &:focus {
    border: 0px;
    outline: 0px;
  }
}

.blink {
  animation: blink 600ms linear infinite;
}
.blink-slow {
  animation: blink 1200ms linear infinite;
}

.txt-wrap {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: block;
}

.badge {
  line-height: 1.1em;
}

@keyframes blink {
  50% {
    opacity: 0.2;
  }
}



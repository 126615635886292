@import "../../Styles/mixins";
@import "../../Styles/theme";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

body{
  padding: 0;
}
.main-nav-items {
  .dropdown.nav-item {
    .nav-link {
      padding: 0px;
      margin-left: 8px;
      .user-image {
        border-radius: 14px;
        width: 30px;
        height: 30px;
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
  }
}

.container-fluid {
  @include respond-to(phablet) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.main-page-wrapper {
 // margin-right: 40px;
  //margin-left: 40px;
  @include respond-to(phablet) {
    margin-right: 0;
    margin-left: 0;
  }
}
.main-menu{
  background-color: color(site, defaultWhite);
  box-shadow: 0 0 3px 5px rgba(0, 0, 0, 0.01);
  .navbar-toggler{
    padding-top: 22px;
    padding-bottom: 22px;
    border: 0 none;
    &:focus{
      outline: 0 none;
    }
  }
}

.dropdown-menu {
  font-size: 13px;
  border-radius: 3px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0;
  .dropdown-item {
    padding: 10px;
    color: color(site, txtColor);
  }
}

.font-13 {
  font-size: 13px !important;
}

.font-25 {
  font-size: 25px !important;
}

.font-600 {
  font-weight: 600 !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.custom-control {
  .custom-radio {
    display: flex;
    align-items: flex-start;
    padding-left: 0;
    min-height: 25px;
    margin-bottom: 0;
    cursor: pointer;
  }
}
.card {
  border: none;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
}
#ifram-container {
  #bot-frame {
    border: none;
    width: 100%;
    min-height: 620px;
    html {
      background: red;
    }
    .eCdssv {
      box-shadow: none;
      height: calc(100vh - 186px);
    }
    body {
      padding: 0px !important;
    }
  }
}

.bna-chat-locator {
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.verified {
  color: rgb(45, 61, 45);
}

#ifram-container {
  // box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  height: 100%;
  // position: absolute;
  width: 350px;
  bottom: 44px;
  right: 15px;
}
.jMiGxl {
  box-shadow: inset 1px 0px 11px 0 rgba(0, 0, 0, 0.15);
}

.jkaZzo {
  box-shadow: inset 1px 12px 11px 0 rgba(0, 0, 0, 0.15);
}

.job-tile {
  background-color: #ffffff;
  border: solid 1px #007bff;
}
.job-action-btn {
  background: color(site, drawerFooterBg);
  height: 50px;
  .card-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: color(site, drawerFooterBg);
    position: relative;
    padding: 0 15px;

    & > button {
      background: #fff;
      border-color: #fff;
      border-style: solid;
      border-width: 0px;
      border-radius: 5px;
      margin-left: 5px;
      color: color(site, primary);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 3px 15px;
      min-height: 27px;
      font-size: 12px;
      line-height: normal;
      font-weight: 500;
      font-family: "Open Sans", sans-serif;
      &.button-alert {
        transition: all 300ms ease-in;
        background: #7ed18d;
        color: #186725;
        &:not(:disabled) {
          &:hover {
            background: rgba(#7ed18d, 0.8);
          }
        }
      }
      &.primary-action,
      &.more-actions,
      &.button-primary {
        color: $brand-primary;
      }

      &.reject-action {
        color: #ec717d;
      }
      & > i {
        margin-right: 5px;
      }
    }
  }
}
.action-reject{
  background: #fff !important;
  color: #ec717d;
}
.action-accept{
  color: #40ac57;
}
.jobpost-wizard-edit{
  @mixin formFields() {
    border: 1px solid color(site, formBorderN);
    background: color(site, formBgN);
    font-size: 14px;
    padding: 9px 10px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
  }
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  .fileslider {
    text-align: left;
  }
  h2 {
    font-size: 20px;
    color: color(site, defaultBlack);
    margin-bottom: 5px;
  }
  p.usertip {
    font-size: 12px;
    color: color(site, defaultBlack);
  }
  .wizard-header {
    margin-bottom: 60px;
  }

  .radio-btn-group {
    .btn-group {
      margin: 0 auto;
      flex-direction: column;
      .btn {
        font-size: 12px;
        border: 1px solid color(site, primary);
        padding: 10px 45px;
        min-width: 250px;
        margin: 0 0 10px;
        // border-radius: 50px !important;
        background: none;
        color: color(site, primary);
        position: relative;
        @include respond-to(phablet) {
          font-size: 12px;
        }
        .ba-icn {
          width: 24px;
          height: 24px;
          border-radius: 100%;
          background: color(site, successColor);
          color: color(site, defaultWhite);
          position: absolute;
          left: 10px;
          top: calc(50% - 12px);
          align-items: center;
          justify-content: center;
          font-size: 10px;
          display: none;
        }
        &.active {
          background: color(site, successBg);
          border: 1px solid color(site, successColor);
          color: color(site, successColor);
          .ba-icn {
            display: inline-flex;
          }
        }
      }
    }
  }
  .note-danger {
    background: #de4c39;
    border-radius: 5px;
    margin-bottom: 30px;
    p {
      font-size: 14px;
      color: color(site, defaultWhite);
      padding: 10px 25px;
      margin: 0;
    }
  }
  .form-group {
    margin-bottom: 25px;
    .form-control {
      @include formFields();
      &.form-textarea {
        min-height: 150px;
        line-height: 1.2em;
        &.no-min-height{
          min-height: unset !important;
        }
      }
      &.rich-control {
        border-radius: 40px;
        border: 1px solid #eee;
        background: color(site, defaultWhite);
        position: relative;
        span {
          &:first-child {
            img {
              display: none;
            }
          }
          &:before {
            content: url(/Images/upload-icon-blue.png);
            position: absolute;
            left: 15px;
            top: 4px;
          }
        }
        label {
          color: color(site, primaryColor);
        }
        .plus-icon {
          display: none;
        }
      }
    }
    .form-label {
      font-size: 12px;
      text-align: left;
      margin-bottom: 3px;
      color: color(site, defaultBlack);
      display: block;
    }
  }

  .wizard-body {
    min-height: calc(
      100vh - (55px + 40px + 120px + 25px)
    ); // minus menu height, top padding, wizard action button space, body padding bottom
    &.job-post-type {
      .radio-btn-group {
        .btn-group {
          flex-direction: row;
          @include respond-to(phablet) {
            flex-direction: column;
          }
          .btn {
            min-width: auto;
            margin: 10px 5px;
            @include respond-to(phablet) {
              min-width: 250px;
              margin: 0 0 10px 0;
            }
          }
        }
      }
    }
  }
  .wizard-body {
    .accordion {
      text-align: start;
    }
  }
  .wizard-actions {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    @include respond-to(phablet) {
      display: block;
      margin-top: 20px;
    }
    .btn {
      display: block;
      font-weight: 500;
      margin: 0 5px;
      border-radius: 20px;
      height: 40px;
      font-size: 12px;
      text-transform: uppercase;
      padding: 0 40px;
      flex: 0 0 auto;
      line-height: 38px;
      @include respond-to(phablet) {
        width: 100%;
        font-size: 14px;
      }
      &.btn-primary {
        background: color(site, primary);
        color: color(site, defaultWhite);
        border: 1px solid color(site, primary);
        @include respond-to(phablet) {
          margin-bottom: 20px;
        }
      }
      &.btn-secondary {
        background: color(site, primaryBg);
        color: color(site, primaryColor);
        border: 1px solid color(site, primaryColor);
      }
      &.back-to-main-chat{
        background-color: color(site, defaultBlack) !important;
        height: 55px !important;
        border-radius: 12px !important;
        border: 0 none !important;
        color: color(site, defaultWhite) !important;
        &:hover{
          background-color: #333 !important;
        }
      }
    }
  }

  .ui.search {
    .results {
      .content {
        width: 100%;
        padding-bottom: 0;
      }
    }
  }
  .ui.input > input.prompt {
    @include formFields();
  }

  .job-posted-icon {
    padding: 30px 0 30px;
    & > .ba-icn {
      font-size: 20px;
      color: #ffffff;
      position: relative;
      background: #27B975;
      height: 80px;
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 70px;
      margin: 0 auto;
      & > .ba-icn {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        position: absolute;
        bottom: -8px;
        left: -8px;
        background: color(site, successBg);
        color: color(site, successColor);
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .job-posted-msg {
    margin-bottom: 20px;
    h3 {
      font-size: 20px;
      font-weight: 500;
      margin: 0;
      color: color(site, defaultBlack);
    }
    h4 {
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;
      color: color(site, defaultBlack);
    }
  }
}
.jobpost-wizard-owner{
  @mixin formFields() {
    border: 1px solid color(site, formBorderN);
    background: color(site, formBgN);
    font-size: 14px;
    padding: 9px 10px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
  }
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  .fileslider {
    text-align: left;
  }
  h2 {
    font-size: 20px;
    color: color(site, defaultBlack);
    margin-bottom: 5px;
  }
  p.usertip {
    font-size: 12px;
    color: color(site, defaultBlack);
  }
  .wizard-header {
    margin-bottom: 60px;
  }

  .radio-btn-group {
    .btn-group {
      margin: 0 auto;
      flex-direction: column;
      .btn {
        font-size: 12px;
        border: 1px solid color(site, primary);
        padding: 10px 45px;
        min-width: 250px;
        margin: 0 0 10px;
        // border-radius: 50px !important;
        background: none;
        color: color(site, primary);
        position: relative;
        @include respond-to(phablet) {
          font-size: 12px;
        }
        .ba-icn {
          width: 24px;
          height: 24px;
          border-radius: 100%;
          background: color(site, successColor);
          color: color(site, defaultWhite);
          position: absolute;
          left: 10px;
          top: calc(50% - 12px);
          align-items: center;
          justify-content: center;
          font-size: 10px;
          display: none;
        }
        &.active {
          background: color(site, successBg);
          border: 1px solid color(site, successColor);
          color: color(site, successColor);
          .ba-icn {
            display: inline-flex;
          }
        }
      }
    }
  }
  .note-danger {
    background: #de4c39;
    border-radius: 5px;
    margin-bottom: 30px;
    p {
      font-size: 14px;
      color: color(site, defaultWhite);
      padding: 10px 25px;
      margin: 0;
    }
  }
  .form-group {
    margin-bottom: 25px;
    .form-control {
      @include formFields();
      &.form-textarea {
        min-height: 150px;
        line-height: 1.2em;
        &.no-min-height{
          min-height: unset !important;
        }
      }
      &.rich-control {
        border-radius: 40px;
        border: 1px solid color(site, primaryColor);
        background: color(site, defaultWhite);
        position: relative;
        span {
          &:first-child {
            img {
              display: none;
            }
          }
          &:before {
            content: url(/Images/upload-icon-blue.png);
            // position: absolute;
            left: 15px;
            top: 4px;
          }
        }
        label {
          color: color(site, primaryColor);
        }
        .plus-icon {
          display: none;
        }
      }
    }
    .form-label {
      font-size: 12px;
      text-align: left;
      margin-bottom: 3px;
      color: color(site, defaultBlack);
      display: block;
    }
  }

  .wizard-body {
    min-height: calc(
      100vh - (55px + 40px + 120px + 25px)
    ); // minus menu height, top padding, wizard action button space, body padding bottom
    &.job-post-type {
      .radio-btn-group {
        .btn-group {
          flex-direction: row;
          @include respond-to(phablet) {
            flex-direction: column;
          }
          .btn {
            min-width: auto;
            margin: 10px 5px;
            @include respond-to(phablet) {
              min-width: 250px;
              margin: 0 0 10px 0;
            }
          }
        }
      }
    }
  }
  .wizard-body {
    .accordion {
      text-align: start;
    }
  }
  .wizard-actions {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    @include respond-to(phablet) {
      display: block;
      margin-top: 20px;
    }
    .btn {
      display: block;
      font-weight: 500;
      margin: 0 5px;
      border-radius: 20px;
      height: 40px;
      font-size: 12px;
      text-transform: uppercase;
      padding: 0 40px;
      flex: 0 0 auto;
      line-height: 38px;
      @include respond-to(phablet) {
        width: 100%;
        font-size: 14px;
      }
      &.btn-primary {
        background: color(site, primary);
        color: color(site, defaultWhite);
        border: 1px solid color(site, primary);
        @include respond-to(phablet) {
          margin-bottom: 20px;
        }
      }
      &.btn-secondary {
        background: color(site, primaryBg);
        color: color(site, primaryColor);
        border: 1px solid color(site, primaryColor);
      }
      &.back-to-main-chat{
        background-color: color(site, defaultBlack) !important;
        height: 55px !important;
        border-radius: 12px !important;
        border: 0 none !important;
        color: color(site, defaultWhite) !important;
        &:hover{
          background-color: #333 !important;
        }
      }
    }
  }

  .ui.search {
    .results {
      .content {
        width: 100%;
        padding-bottom: 0;
      }
    }
  }
  .ui.input > input.prompt {
    @include formFields();
  }

  .job-posted-icon {
    padding: 30px 0 30px;
    & > .ba-icn {
      font-size: 20px;
      color: #ffffff;
      position: relative;
      background: #27B975;
      height: 80px;
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 70px;
      margin: 0 auto;
      & > .ba-icn {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        position: absolute;
        bottom: -8px;
        left: -8px;
        background: color(site, successBg);
        color: color(site, successColor);
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .job-posted-msg {
    margin-bottom: 20px;
    h3 {
      font-size: 20px;
      font-weight: 500;
      margin: 0;
      color: color(site, defaultBlack);
    }
    h4 {
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;
      color: color(site, defaultBlack);
    }
  }
}
.jobpost-wizard {
  @mixin formFields() {
    border: 1px solid color(site, formBorderN);
    background: color(site, formBgN);
    font-size: 14px;
    padding: 9px 10px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
  }
  text-align: left;
  max-width: 800px;
  background-color: color(site, defaultWhite);
  border-radius: 15px;
  padding: 40px;
  margin: 0 auto;
  .fileslider {
    text-align: left;
  }
  h2 {
    font-size: 20px;
    color: color(site, defaultBlack);
    margin-bottom: 25px !important;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
  }
  p.usertip {
    font-size: 12px;
    color: color(site, defaultBlack);
  }
  .wizard-header {
    // margin-bottom: 60px;
  }

  .radio-btn-group {
    .btn-group {
      // margin: 0 auto;
      // flex-direction: column;
      // flex-wrap: nowrap !important;
      .btn{        
        border: 0px none !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin: 0 0 10px 0 !important;
        background: transparent !important;
        
        &.active {
          .btn-inner{
            background: color(site, darkBlue) !important;
            color: color(site, defaultWhite) !important;
            &.posting-for{
              .ba-icn {
                color: color(site, defaultWhite) !important;
              }
            }
          }
        }
        &.tile-button{
          background: #fff !important;
          color: #000;
          border: 1px solid color(site, primaryColor) !important;
          font-family: 'Poppins', sans-serif;
          font-size: 12px;
          font-weight: 400;
          padding: 14px 16px !important;
          border-radius: 12px;
          input{
            display: none;
          }
          &.active{
            background: color(site, primaryColor) !important;
            color: color(site, defaultWhite) !important;
          }
        }
        &.common-job{
          border-radius: 12px !important;
          margin-bottom: 20px !important;
          position: relative;
          padding: 15px 18px !important;
          background-color: #f9f9f9 !important;
          text-align: left;   
          .job-number {
            font-size: 12px;
            color: #8b8a96;
            font-weight: 400;
          }
          .status{
            color: color(site, defaultBlack);
          }
          .job-title{
            font-size: 16px;
            color: color(site, defaultBlack);
            font-weight: 600;
          }
          .icon-ico-tools{
            background-color: color(site, successColor);
            color: color(site, defaultWhite);
            font-size: 14px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 34px;
            height: 40px;
          }
          .updated-text{
            color: #8b8a96;
          }
          .job-type-icon{
            color: #8b8a96;
            font-size: 32px;
          } 
          &:hover, &.active{
            background-color: color(site, darkBlue) !important;
            .job-title, .job-number, .icon-ico-tools, .updated-text, .job-type-icon, .status{
                color: color(site, defaultWhite);
            }
          }
        }
      }

      &.selective-tiles{
        .tile{
          background: #f9f9f9 !important;
          color: #000;
          border: 0 none !important;
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          font-weight: 400;
          padding: 22px 24px !important;
          border-radius: 12px;
          &.active{
            background: #1481de !important;
            color: #fff !important;
          }
          .icon{
            background-color: color(site, highlighter);
            color: #fff;
            height: 40px;
            width: 34px;
            min-width: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            border-radius: 10px;
            margin-right: 16px;
          }
          .address{
            text-align: left;
          }
        }
      }
      .btn-inner {
        color: #000 !important;
        padding: 20px !important;   
        font-weight: 400!important;
        border-radius: 10px !important;
        font-family: 'Poppins', sans-serif!important;
        font-size: 16px!important;
        background: #f1f1f2 !important;
        // border-radius: 50px !important;
        position: relative;
        display: flex;
        justify-content: start;
        align-items: center;
        text-align: start;
        &.posting-for{
          padding: 10px 20px !important;   
          .ba-icn {
            background: transparent;
            color: color(site, highlighter);
            font-size: 20px!important;
            width: 34px !important;
          }
        }
        @include respond-to(phablet) {
          font-size: 12px;
        }
        .ba-icn {
          width: 37px;
          height: 40px;
          border-radius: 10px;
          background: color(site, highlighter);
          color: color(site, defaultWhite);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 600;          
          margin-right: 15px;
        }
      }
    }
    &.key-collect-options{
      .btn-group{
        justify-content: space-between !important;
        display: flex !important;
        width: 100% !important;
        .btn{
          color: #000 !important;
          padding: 10px 20px !important;   
          font-weight: 400!important;
          border-radius: 10px !important;
          font-family: 'Poppins', sans-serif!important;
          font-size: 11px!important;
          background: #f1f1f2 !important;
          position: relative;
          display: flex;
          justify-content: start;
          align-items: center;
          text-align: start;
          width: calc((100%/3) - 10px);
          @include respond-to(phablet) {
            width: 100%
          }
          .ba-icn{
            font-size: 24px;
            color: color(site, highlighter);
          }
          &.active{
            background: color(site, darkBlue) !important;
            color: color(site, defaultWhite) !important;
            
            .ba-icn{
              font-size: 24px;
              color: color(site, defaultWhite);
            }
          }
        }
      }
      &.submit-options{
        .btn-group {
          justify-content: center !important;
          .btn{
            margin-right: 10px !important;
            font-size: 14px!important;
            justify-content: center !important;
            padding: 13px 20px !important;   
          }
        }
      }
    }
  }
  .note-danger {
    background: #de4c39;
    border-radius: 5px;
    margin-bottom: 30px;
    p {
      font-size: 14px;
      color: color(site, defaultWhite);
      padding: 10px 25px;
      margin: 0;
    }
  }
  .form-group {
    margin-bottom: 25px;
    .form-control {
      border: 0 none;
      background: color(site, defaultWhite);
      font-size: 14px;
      padding: 9px 0;
      height: 40px;
      line-height: 40px;
      border-radius: 0;
      border-bottom: 1px solid color(site, darkBlue);
      font-family: 'Poppins', sans-serif!important;
      color: color(site, defaultBlack);
  
      &.form-textarea {
        min-height: 150px;
        line-height: 1.2em;
        &.no-min-height{
          min-height: unset !important;
        }
      }
      &.rich-control {
        border-radius: 12px !important;
        border: 1px solid color(site, darkBlue) !important;
        background: color(site, lightBlue) !important;
        padding: 30px 40px 30px!important;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 88px;
        span {
          &:first-child {
            color: color(site, primaryColor) !important;
            font-weight: 600;
            img {
              display: none;
            }
          }
          &:before {
            //content: url(/Images/upload-icon-blue.png);
            // position: absolute;
            // left: 15px;
            // top: 4px;
          }
        }
        label {
          color: color(site, primaryColor) !important;
          font-weight: 500;
          font-size: 14px;
        }
        .plus-icon {
          display: none;
        }
      }
    }
    .form-label {
      font-size: 12px;
      text-align: left;
      margin-bottom: 3px;
      color: color(site, darkBlue);
      display: block;
      font-family: 'Poppins', sans-serif!important;
    }
  }

  .wizard-body {
    min-height: calc(
      100vh - (55px + 40px + 120px + 25px)
    ); // minus menu height, top padding, wizard action button space, body padding bottom
    &.job-post-type {
      .radio-btn-group {
        .btn-group {
          flex-direction: row;
          @include respond-to(phablet) {
            flex-direction: column;
          }
          .btn {
            min-width: auto;
            margin: 10px 5px;
            @include respond-to(phablet) {
              min-width: 250px;
              margin: 0 0 10px 0;
            }
          }
        }
      }
    }
  }
  .wizard-body {
    .accordion {
      text-align: start;
    }
  }
  .wizard-actions {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    @include respond-to(phablet) {
      display: block;
      margin-top: 20px;
    }
    .btn {
      display: block;
      font-weight: 500;
      margin: 0 5px;
      border-radius: 20px;
      height: 40px;
      font-size: 12px;
      text-transform: uppercase;
      padding: 0 40px;
      flex: 0 0 auto;
      line-height: 38px;
      @include respond-to(phablet) {
        width: 100%;
        font-size: 14px;
        padding: 0 !important;
      }
      &.btn-primary {
        background: color(site, primary);
        color: color(site, defaultWhite);
        border: 1px solid color(site, primary);
        @include respond-to(phablet) {
          margin-bottom: 20px;
        }
      }
      &.btn-secondary {
        background-color: #e6e6e6 !important;
        height: 55px !important;
        margin-bottom: 0 !important;
        border-radius: 12px !important;
        border: 0 none !important;
        color: #000 !important;
      }
      &.back-to-main-chat{
        background-color: color(site, defaultBlack) !important;
        height: 55px !important;
        border-radius: 12px !important;
        border: 0 none !important;
        color: color(site, defaultWhite) !important;
        &:hover{
          background-color: #333 !important;
        }
      }
    }
  }

  .ui.search {
    .results {
      .content {
        width: 100%;
        padding-bottom: 0;
      }
    }
  }
  .ui.input > input.prompt {
    @include formFields();
  }

  .job-posted-icon {
    padding: 30px 0 30px;
    & > .ba-icn {
      font-size: 20px;
      color: #ffffff;
      position: relative;
      background: #27B975 !important;
      border: 8px solid #e9f8f1;
      height: 80px;
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 70px;
      margin: 0 auto;
      & > .ba-icn {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        position: absolute;
        bottom: -8px;
        left: -8px;
        background: color(site, successBg);
        color: color(site, successColor);
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .job-posted-msg {
    margin-bottom: 20px;
    h3 {
      font-size: 20px;
      font-weight: 500;
      margin: 0;
      color: color(site, defaultBlack);
    }
    h4 {
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;
      color: color(site, defaultBlack);
    }
  }
}

.ins-report-gallery {
  .fileslider {
    display: flex !important;
    flex-wrap: wrap;
    padding: 0;
    background: none;
    .slider-item-wrapper {
      width: 31%;
      margin-right: 2%;
      max-width: 120px;
      margin-bottom: 15px;
      .fileslider-item {
        position: relative;
        width: 100%;
        padding-top: 100%;
        margin: 0;
        background: none;
        .img-holder {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background: rgba(96, 97, 98, 0.2);
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            cursor: pointer;
          }
        }
        .commented {
          background: #fff;
          color: $color-primary;
          position: absolute;
          bottom: 2px;
          left: 2px;
          border: none;
          line-height: 18px;
          width: 16px;
          height: 16px;
          border-radius: 2px;
          font-size: 12px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .img-datetime {
        font-size: 11px;
      }
    }
  }
}

.condition-report-gallery {
  .fileslider {
    .slider-item-wrapper {
      display: inline-flex;
    }
  }
}

// @media only screen and (min-width: 1200px) {
//   .web-side-nav {
//     width: 100px;
//     float: left;
//     background: #121432;
//     height: 100vh;
//     overflow-y: auto;

//     .navbar-nav {
//       flex-direction: column;
//       .nav-item {
//         a {
//           color: #fff !important;
//           height: 80px;
//         }

//         &.active {
//           background-image: url("../../images/actv-png.png");
//           background-color: #fff;
          
//           a{
//             color: #121432 !important;
//           }
//         }
//       }
//     }
//   }

//   .web-wrapr {
//     width: calc(100% - 100px);
//     float: left;
//   }

//   body {
//     padding-top: 0px !important;
//   }

//   .top-nav-bar {
//     position: relative !important;
//     padding: 10px 0 !important;
//   }

//   .main-nav-items {
//     ul:nth-child(1) {
//       display: none;
//     }
//   }
// }

@media only screen and (max-width: 600px) {
  .wzrd-post-a-job, .jobpost-wizard {
    .btn-group label {
      width: 100%;
    }
  }
}
.custom-scroller{
  overflow: hidden;
  overflow-y: auto;
  padding-right: 6px;  

  &.check-issues, &.describe-issue, &.issue-fix-suggestions{
    // max-height: calc(100vh - 330px);
    max-height: calc(100vh - 355px);
  }
  &.ongoing-jobs, &.issue-wizard{
    // max-height: calc(100vh - 270px);
    max-height: calc(100vh - 305px);
    // background: red;
  }  
  &.pending-approvals{
    height: calc(100vh - 358px);
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 576px) {
    &.check-issues, &.describe-issue, &.issue-fix-suggestions{
      // max-height: calc(100vh - 265px);
      max-height: calc(100vh - 405px);
    }
    &.ongoing-jobs, &.issue-wizard{
      // height: calc(100vh - 215px);
      max-height: calc(100vh - 300px);
      // background: aquamarine;
    }
    &.pending-approvals{
      height: calc(100vh - 368px);
      margin-bottom: 15px;
    }
  }
}
.warning-card{
  background-color: #fef2e6;
  padding: 10px 15px;
  border-radius: 8px;
  color: color(site, warningColor);
}
.chatbot-container{
  h1, h2{
    text-align: center;
  }
  .jobpost-wizard {
    padding: 0 40px;
    @media only screen and (max-width: 576px) {
      padding: 0 20px;
    }
  }  
  
  .innerHtmlContainer{
    ul{
      padding-left: 22px;
      list-style-type: none;
      li{
        &:before{
          content: "\2022";
          color: red;
          font-weight: 800;
          display: inline-block;
          width: 22px;
          margin-left: -22px;
          font-size: 16px;
        }
      }
    }
  }
  .tracking-timeline{
    .description{
      color: color(site, defaultBlack);
      font-size: 14px;
      a{
        text-decoration: underline;
        color: color(site, darkBlue);
      }
    }

    .step{
      position: relative;
      .status-icon{
        background-color: color(site, successColor);
        color: color(site, defaultWhite);
        font-size: 14px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        height: 40px;
      }
      .status-title{
        color: color(site, successColor);
        font-size: 14px;
        font-weight: 500;
        .tick-icon{
          background-color: color(site, successColor) !important;
          color: color(site, defaultWhite);
          font-size: 6px;
          border-radius: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          font-weight: bold;
          margin-left: 8px;
        }
      }
      .status-text{
        font-size: 12px;
        font-weight: 400;
      }      
      &:before{
        width: 17px;
        border-right: 1px dashed color(site, successColor);
        content: '';
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
      }
      &.inactive{
        .status-icon{        
          background-color: #f2f2f2;
          color: #c5c4ca;
        }
        .status-title{
          color: color(site, defaultBlack);
        }   
        &:before{
          border-right: 1px dashed #c5c4ca;
        }
      }
    }
    &.no-stepping{
      .step{
        &::before{
          display: none;
        }
        .status-title{
          font-size: 12px;
          font-weight: 400;
          color: color(site, lightBrown);
        }
        .status-text{
          color: color(site, defaultBlack);
          font-size: 14px;
          font-weight: 400;
        } 
      }
    }
  }

}
.light-brown-bg{
  background-color: #f9f9f9;
}
.text-light-brown{
  color: color(site, lightBrown)
}
.text-blue{
  color: #1481de;
}
.text-underline{
  text-decoration: underline;
}
@media only screen and (max-width: 767px) {

    .ui.tiny.modal {
      width: 85% !important;
      margin: 0;
    }

}
.popup-contents{
  text-align: center;
  font-size: 18px;
  color: color(site, defaultBlack);
  font-weight: 400;
  .popup-icon{
    
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    color: color(site, defaultWhite);
    font-size: 24px;
    border-radius: 50%;
    border: 6px solid #eee;
    &.error{
      background-color: color(site, highlighter);      
      border: 6px solid #ffdfdf;
    }
    &.warning{
      background-color: color(site, warningColor);      
      border: 6px solid #fff4e8;
    }
    &.success{
      background-color: color(site, successColor) !important;     
      border: 6px solid color(site, successBg);
    }
  }
}
.popup-actions{
  button{
    border-radius: 12px !important;
    background-color: #e6e6e6 !important;
    color: color(site, defaultBlack) !important;
    padding: 18px 40px !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    &.primary{
      background-color: color(site, defaultBlack) !important;
      color: color(site, defaultWhite) !important;
    }
  }
}
.common-chatbot-tabs{
  .nav-tabs{
    margin-bottom: 30px;
    border: 0 none;    
    .nav-link{
      background-color: color(site, defaultWhite) !important;
      border: 1px solid #d0e6f9 !important;
      padding: 8px 14px !important;
      border-right: 0 none !important;
      border-radius: 0 !important;
      color: color(site, darkBlue);
      font-size: 12px;
      &:first-child{        
        border-radius: 10px 0 0 10px !important;
      }
      &:last-child{        
        border-right: 1px solid #d0e6f9 !important;
        border-radius: 0 10px 10px 0 !important;
      }
      &.active, &:hover{
        background-color: color(site, darkBlue) !important;
        color: color(site, defaultWhite) !important;
        border-color: color(site, darkBlue) !important;
      }
    }
  }
  .accordion{
    .card{
      border-bottom: 1px solid #ededf1 !important;
      box-shadow: 0 0 0;
    }
    .card-header{
      padding: 0 0 15px 0;
      background: transparent;
      font-size: 14px;
      color: color(site, defaultBlack) ;
      font-weight: 500;
      border-bottom: 0;
      cursor: pointer;
    }
    .card-body{
      padding: 0 0 15px 0;
      font-size: 14px;
      color: color(site, lightBrown) ;
      font-weight: 400;
    }
  }
}
.qna-link{
  font-size: 14px;
  color: color(site, darkBlue);
  text-decoration: underline;
  cursor: pointer !important;
}

.dropdown-priority{
  border: 0 !important;
  border-bottom: 1px solid #1481de !important;
  border-radius: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  color: #1d1a34 !important;
}

.image-close-icon{
  border-radius: 50%;
    color: #fff;
    background-color: #621084;
    width: 10px;
    height: 10px;
    padding: 5px;
    font-size: 10px;
    position: relative;
    // right: -111px;
    // top: 14px;
}

.close-icon-position{
  position: absolute !important;
    right: 0 !important;
    top: -8px;
}

.relative{
  position:  relative !important;
}
@mixin  placeholder() {
    &::-webkit-input-placeholder {
        color: color(site,txtPlaceholder);
      }          
    &::-ms-input-placeholder {
        color: color(site,txtPlaceholder);
      }
    &::placeholder {
        color: color(site,txtPlaceholder);
    }
}

.form-group {
    margin-bottom: 10px;
    label {
        font-size: 12px;
        color: color(site,txtLightGrey);
        margin-bottom: 2px;
    }
    .form-label {
        font-size: 12px;
    }
    .input-group {
        .form-control {
            height: auto;
        }        
    }
    .form-control {
        background: color(site,formControlBg);
        border-color:  color(site,formControlBg);
        font-size: 12px;
        color: color(site,txtColor);
        font-family: $font-stack;
        
        &:focus {
            box-shadow: none;
        }
        &[readonly], &:disabled {
            cursor: no-drop;
        }
        &.rich-control {
            color: $text-color;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #eff3f6;
            border-color: #eff3f6;
            label {
                color: $text-color;
                flex: 1 1 100%;
                padding: 0 15px;
                font-weight: 600;
                font-size:14px;
                &:hover {
                    cursor: pointer;
                }
            }
            .plus-icon {
                background: $text-color;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                color: #fff;
                font-size: 10px;
                @include flex-center();
                i {
                    transform: rotate(45deg);
                }
            }
            .plus-icon-img {
                width: 20px;
                height: 20px;
                @include flex-center();
            }
            &:hover {
                cursor: pointer;
            }
        }
        &.form-textarea {
            height: 75px;
        }
        @include placeholder();
    }
    .light-bg-btn {
        background: color(site,formControlBg); 
    }
}
.ui.input {
    width: 100%;
    &>input.prompt {
        // background: color(site,formControlBg);
        background: #fff;
        border-color:  color(site,formControlBg);
        font-size: 12px;
        color: color(site,txtColor);
        border-radius: .25rem;
        font-family: $font-stack;
        @include placeholder();
    }
}
.ui.search {
    .results {
        width: 100%;
        border-color: rgba(149,175,192,0.2);
        box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.1);
        margin-top: 0;
        padding: 5px 0;
        z-index: 1;
        .result {
            padding: 8px 10px;
            .ui.label {
                background: none;
                padding: 0;
                font-weight: 400;
            }
        }
    }
}
.ui.selection{
    &.dropdown {
        // background: color(site,formControlBg);
        background: #fff;
        border-color:  color(site,formControlBg);
        font-size: 12px;
        line-height: auto;
        display: block;
        border-radius: 3px;
        color: color(site,txtColor);
        &:hover {
            border-color:  color(site,formControlBg);
        }
        &.active {
            border-color:  color(site,formControlBg);
            &:hover {
                border-color:  color(site,formControlBg);
                .menu {
                    border-color: rgba(149,175,192,0.2);
                    box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.1);
                }
            }
            .menu {
                border-color: rgba(149,175,192,0.2);
                box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.1);
                &:hover {
                    border-color: rgba(149,175,192,0.2);
                    box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.1);
                }
                &>.item {
                    font-size: 12px;
                    color: color(site,txtColor);
                    &:hover {
                        background: rgba(149,175,192,0.1);
                    }
                    &.active {
                        font-weight: 400;
                    }
                    &.selected {
                        font-weight: 600;
                        background: rgba(149,175,192,0.1);
                    }
                }
            }
        }
    }
}

@function color($color-map, $tone) {
    @if map-has-key($palette, $color-map) {
      $color: map-get($palette, $color-map);
      @if map-has-key($color, $tone) {
        $tone: map-get($color, $tone);
        @return $tone;
      }
      @error "unknown tone '#{$tone}' in '#{$color-map}'";
      @return null;
    }
    @else {
      @error "cannot find map key '#{$color-map}'";
    }
    @error "Something went wrong";
    @return null;
}
   
  $palette: (
    site: (
      primary: #4ba1eb,
      accentColor: #81d38e,
      bodyBg: #f7f9fc,
      headerBg: #f8f9fa,
      cardFtBg: #f8f9fa,
      leftMenuBg: #fff,
      txtColor: #4c6f81,
      txtLightGrey: #77797b,
      highlighter: #eb4c4b,
      secondary: #3498db,
      theadBg: #606162,
      theadColor: #fff,
      tdColor: #333,
      tableBorder: #eff3f6,
      defaultWhite: #fff,
      defaultBlack: #000,
      lightBrown: #9997a3,

      formControlBg: #eff3f6,
      txtPlaceholder: #c5c5c6,

      lightGreyBG: #EFF2F8,
      darkGreyText: #9697a1,

      formBorderN: #000,
      formBgN: #f8f9fa,

      jobDetailsTableLabel: #95afc0,

      dangerBg: #ffe6e2,
      dangerColor: #fc573b,
      primaryBg: #e2f3ff,
      primaryColor: #4ba1eb,
      warningBg: #fff9dd,
      warningColor: #ffa147,
      successBg: #d3f1e3,
      successColor: #27b975,
      defaultBg: #eff3f6,
      defaultColor: #000000,
      statusBg: #fff9dd,
      statusColor: #000000,

      selectBtnBg: #eff3f6,
      selectedBtnBg: #d3f1e3,
      selectedBtnColor: #39bf81,

      starBg: #dce5eb,
      StarOnbg: #FDE16D,

      starBgFeedback: #dce5eb,
      StarOnbgFeedback: #0EB86E,

      btnDefault: #668290,
      btnPrimary: #eb4c4b,

      toastErrBg: #e2a2a3,
      toastErrColor: #8f1a1f,
      toastSuccessBg: #a1d9a4,
      toastSuccessColor: #57945b,

      waitingConfirmation: #FFFFDE,
      waitingConfirmationColor: #DFBD11,
      scheduleConfirmed: #E1F8FE,
      scheduleConfirmedColor: #19A8CE,
      dateProposed: #F8F6F6,
      dateProposedColor: #A1A1A1,
      scheduleRejected: #FDEDED,
      scheduleRejectedColor: #D82727,
      inspectionCompleted: #E8FFF2,
      inspectionCompletedColor: #0EB86E,
      drafted: #FFEFE1,
      draftedColor: #D4680A,

      feedbackBg: #fafafb,
      drawerFooterBg: #e2f3ff,
      lightBlue: #e7f2fc,
      darkBlue: #1481de,
    ),
    primary: (
      base: #e53935,
      shadeone: #eb4c4b,
      shadetwo: #fbdcdc,
      shadethree: #fff5f5
    ),
    sidenav: (
      submenuBg: #eff6fb,
    ),
    notification: (
      error: #fbdcdc,
      success: #dcfbea,
      info:rgb(237, 243, 243)
    ),
    grey: (
      base: #6d6d6d,
      shadeone: #cbccd2 ,
      shadetwo: #dce5eb,
      shadethree: #efefef,
    ),
    green: (
      base: #388e3c
    ),
    white: (
      base: #fff
    ),
    black: (
      base: #000
    ),
    blue: (
      base:DodgerBlue
    )

  );
  
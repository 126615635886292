@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?72gid7');
  src:  url('../fonts/icomoon.eot?72gid7#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?72gid7') format('truetype'),
    url('../fonts/icomoon.woff?72gid7') format('woff'),
    url('../fonts/icomoon.svg?72gid7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-desc{
  font-family: 'Poppins', sans-serif !important;
}

.icon-ico-upload:before {
  content: "\e99a";
}
.icon-ico-exclamation:before {
  content: "\e999";
}
.icon-ico-caleandar-start:before {
  content: "\e993";
}
.icon-ico-caleandar-stop:before {
  content: "\e994";
}
.icon-ico-doller-home:before {
  content: "\e995";
}
.icon-ico-doller-mark:before {
  content: "\e996";
}
.icon-ico-home-cock:before {
  content: "\e997";
}
.icon-ico-water-drop:before {
  content: "\e998";
}
.icon-ico-maintenance:before {
  content: "\e992";
}
.icon-ico-user:before {
  content: "\e988";
}
.icon-ico-cheque:before {
  content: "\e989";
}
.icon-ico-location:before {
  content: "\e98a";
}
.icon-icon-complete:before {
  content: "\e98b";
}
.icon-ico-microphone:before {
  content: "\e98c";
}
.icon-ico-question:before {
  content: "\e98d";
}
.icon-ico-tools:before {
  content: "\e98e";
}
.icon-ico-tracking:before {
  content: "\e98f";
}
.icon-ico-worker:before {
  content: "\e990";
}
.icon-ico-attachment:before {
  content: "\e991";
}
.icon-icon-manager:before {
  content: "\e984";
}
.icon-icon-owner:before {
  content: "\e985";
}
.icon-icon-tenant:before {
  content: "\e986";
}
.icon-icon-trade:before {
  content: "\e987";
}
.icon-icon-wechat:before {
  content: "\e981";
}
.icon-icon-facebook-messenger:before {
  content: "\e982";
}
.icon-icon-whatsapp:before {
  content: "\e983";
}
.icon-Alarmsystems:before {
  content: "\e97f";
}
.icon-Powerpoints:before {
  content: "\e980";
}
.icon-AutomaticGates:before {
  content: "\e908";
}
.icon-Caretaking:before {
  content: "\e909";
}
.icon-CarstackerServicing:before {
  content: "\e90d";
}
.icon-Caulking:before {
  content: "\e90e";
}
.icon-Ceilings:before {
  content: "\e90f";
}
.icon-ChimneyFireplace:before {
  content: "\e910";
}
.icon-ConditionReports:before {
  content: "\e911";
}
.icon-ConsultingEngineers:before {
  content: "\e912";
}
.icon-DrivewayRepairs:before {
  content: "\e913";
}
.icon-FireServices:before {
  content: "\e914";
}
.icon-FloodRestoration:before {
  content: "\e915";
}
.icon-Flooring:before {
  content: "\e916";
}
.icon-FoundationRepairs:before {
  content: "\e917";
}
.icon-HeightAccessSafety:before {
  content: "\e918";
}
.icon-InsuranceValuation:before {
  content: "\e919";
}
.icon-Intercom:before {
  content: "\e91a";
}
.icon-Letterbox:before {
  content: "\e91b";
}
.icon-Lifts:before {
  content: "\e91c";
}
.icon-MeterReading:before {
  content: "\e91d";
}
.icon-MethTesting:before {
  content: "\e91e";
}
.icon-MouldInspector:before {
  content: "\e91f";
}
.icon-OdourRemovalAndScenting:before {
  content: "\e920";
}
.icon-Photography:before {
  content: "\e921";
}
.icon-PossumControl:before {
  content: "\e922";
}
.icon-Pumps:before {
  content: "\e923";
}
.icon-Signage:before {
  content: "\e924";
}
.icon-SmokeAlarm:before {
  content: "\e925";
}
.icon-Soakwells:before {
  content: "\e926";
}
.icon-TaxDepreciationInspection:before {
  content: "\e927";
}
.icon-Telecommunications:before {
  content: "\e928";
}
.icon-TestAndTag:before {
  content: "\e929";
}
.icon-Ventilation:before {
  content: "\e92a";
}
.icon-WashroomServices:before {
  content: "\e92b";
}
.icon-WasteManagement:before {
  content: "\e92c";
}
.icon-WaterFilters:before {
  content: "\e92d";
}
.icon-WindowDoorReplacement:before {
  content: "\e92e";
}
.icon-TilerTiling:before {
  content: "\e92f";
}
.icon-TimberFlooring:before {
  content: "\e930";
}
.icon-TreeFeller:before {
  content: "\e931";
}
.icon-VinylLaminate:before {
  content: "\e932";
}
.icon-Waterproofing:before {
  content: "\e933";
}
.icon-WindowCleaning:before {
  content: "\e934";
}
.icon-WindowTinting:before {
  content: "\e935";
}
.icon-Security:before {
  content: "\e936";
}
.icon-SecurityScreenDoors:before {
  content: "\e937";
}
.icon-ShadeSails:before {
  content: "\e938";
}
.icon-Sheds:before {
  content: "\e939";
}
.icon-ShowerScreens:before {
  content: "\e93a";
}
.icon-Skylights:before {
  content: "\e93b";
}
.icon-SolarPower:before {
  content: "\e93c";
}
.icon-Spas:before {
  content: "\e93d";
}
.icon-Stonemasons:before {
  content: "\e93e";
}
.icon-SwimmingPoolMaintenance:before {
  content: "\e93f";
}
.icon-RealEstateAgent:before {
  content: "\e940";
}
.icon-Removalists:before {
  content: "\e941";
}
.icon-RenderRendering:before {
  content: "\e942";
}
.icon-RetainingWalls:before {
  content: "\e943";
}
.icon-Rollerdoor:before {
  content: "\e944";
}
.icon-RollerShutters:before {
  content: "\e945";
}
.icon-Roofing:before {
  content: "\e946";
}
.icon-RoofRepairs:before {
  content: "\e947";
}
.icon-RubbishRemoval:before {
  content: "\e948";
}
.icon-Painter:before {
  content: "\e949";
}
.icon-Pavers:before {
  content: "\e94a";
}
.icon-Pergola:before {
  content: "\e94b";
}
.icon-PestControl:before {
  content: "\e94c";
}
.icon-PestInspection:before {
  content: "\e94d";
}
.icon-Plastering:before {
  content: "\e94e";
}
.icon-PoolFencing:before {
  content: "\e94f";
}
.icon-PressureCleaner:before {
  content: "\e950";
}
.icon-LawnTurf:before {
  content: "\e951";
}
.icon-Lighting:before {
  content: "\e952";
}
.icon-Locksmith:before {
  content: "\e953";
}
.icon-HomeTheatre:before {
  content: "\e954";
}
.icon-HotWaterSystem:before {
  content: "\e955";
}
.icon-HouseCleaning:before {
  content: "\e956";
}
.icon-InteriorDesign:before {
  content: "\e957";
}
.icon-IrrigationSystems:before {
  content: "\e958";
}
.icon-KitchenRenovations:before {
  content: "\e959";
}
.icon-Landscaper:before {
  content: "\e95a";
}
.icon-Fences:before {
  content: "\e95b";
}
.icon-Garages:before {
  content: "\e95c";
}
.icon-Gardening:before {
  content: "\e95d";
}
.icon-GasFitters:before {
  content: "\e95e";
}
.icon-Gates:before {
  content: "\e95f";
}
.icon-Gazebo:before {
  content: "\e960";
}
.icon-Glazier:before {
  content: "\e961";
}
.icon-Guttering:before {
  content: "\e962";
}
.icon-Decking:before {
  content: "\e963";
}
.icon-Demolition:before {
  content: "\e964";
}
.icon-DishwasherRepairs:before {
  content: "\e965";
}
.icon-Excavation:before {
  content: "\e966";
}
.icon-ExteriorCleaning:before {
  content: "\e967";
}
.icon-CommercialCleaning:before {
  content: "\e968";
}
.icon-ConcreteResurfacing:before {
  content: "\e969";
}
.icon-Curtains:before {
  content: "\e96a";
}
.icon-CabinetMakers:before {
  content: "\e96b";
}
.icon-Carpenters:before {
  content: "\e96c";
}
.icon-CarpetCleaning:before {
  content: "\e96d";
}
.icon-CarpetLayer:before {
  content: "\e96e";
}
.icon-Carports:before {
  content: "\e96f";
}
.icon-Cladding:before {
  content: "\e970";
}
.icon-Bricklayer:before {
  content: "\e971";
}
.icon-Builder:before {
  content: "\e972";
}
.icon-BuildingDesigner:before {
  content: "\e973";
}
.icon-BuildingInspection:before {
  content: "\e974";
}
.icon-BathroomRenovations:before {
  content: "\e975";
}
.icon-AirbnbCleaning:before {
  content: "\e976";
}
.icon-ApplianceInstallationOrRepair:before {
  content: "\e977";
}
.icon-Arborist:before {
  content: "\e978";
}
.icon-Architect:before {
  content: "\e979";
}
.icon-AsbestosRemoval:before {
  content: "\e97a";
}
.icon-AwningSuppliesOrInstallations:before {
  content: "\e97b";
}
.icon-Balustrades:before {
  content: "\e97c";
}
.icon-Balustradesk:before {
  content: "\e97d";
}
.icon-Blinds:before {
  content: "\e97e";
}
.icon-PeopleSearch:before {
  content: "\e903";
}
.icon-Workers:before {
  content: "\e904";
}
.icon-Man:before {
  content: "\e905";
}
.icon-Mail:before {
  content: "\e906";
}
.icon-Image:before {
  content: "\e907";
}
.icon-Default:before {
  content: "\e902";
}
.icon-AirConditioning:before {
  content: "\e900";
}
.icon-AntennaServices:before {
  content: "\e901";
}
.icon-Electrician:before {
  content: "\e90a";
}
.icon-Handyman:before {
  content: "\e90b";
}
.icon-Plumber:before {
  content: "\e90c";
}

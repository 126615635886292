// @import "src/Styles/_theme";

.react-bs-table-tool-bar {
  position: absolute;
  top: 15px;
  width: 97%;
}

.launch-demo-btn {
  position: absolute;
  right: 34%;
}

.demo-modal-popup .modal-content{
  height: calc(100vh - 60px);
}

.react-bs-table-sizePerPage-dropdown {
  display: none;
}

.uploaded-attachment {
  img {
    width: 100%;
  }
}

.job-notes-container {
  .job-note {
      color: color(site,txtColor);
      border-bottom: 1px solid #f4f4f6;
      padding: 15px 0;

      .job-note-header {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          h4 {
              margin-top: 3px;
              margin-bottom: 3px;
              color: color(site,txtColor);
              font-size: 15px;
              font-weight: 600;
          }

          p {
              margin-bottom: 0;
              color: color(site,txtLightGrey);
              font-size: 13px;
          }

          .job-note-headerL {
              display: flex;
              margin-bottom: 5px;
              .profile-img {
                  width: 40px;
                  height: 40px;
                  margin-right: 15px;
                  flex-shrink: 0;

                  img {
                      width: 100%;
                      height: 100%;
                      border-radius: 100%;
                  }
              }
          }

          .job-note-headerR {
              padding-top: 5px;
              margin-bottom: 5px;
              label {
                  font-size: 12px;
                  margin-bottom: 2px;
                  float:right;
                  clear: both;
              }
          }
      }

      .job-note-body {
          color: color(site,txtLightGrey);
          margin-left: 55px;
          .note-type {
              margin-bottom: 15px;
          }
          p:last-child {
              margin-bottom: 0;
          }
          @at-root .job-note-email {
              overflow: auto;
              max-height: calc(100vh - 200px);
             // border: 1px solid $base-color-7;
              table {
                  position: unset;
                  td {
                      border-width: 0 !important;
                  }
              }
          }
      }
      .job-note-attachment {
          margin: 20px 0 0 55px;
          h3 {
              font-size: 14px;
          }
      }
  }
}
@import 'src/Styles/theme';
@import 'src/Styles/mixins';
.job-detail-card {
    font-size: 12px;
    display: block;
    text-align: left;
    color: color(site,txtColor);
    .job-labels {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        label {
            font-size: 12px;
            font-weight: 600;
            small {
                font-size: 12px;
                font-weight: 400;
                padding-right: 3px;
                opacity: 0.7;
            }
        }
    }
    h3 {
        font-size: 14px;
        font-weight: 700;
        color: color(site,txtColor);
        overflow: hidden;
        line-height: normal;
        .org-logo {
            width: 35px;
            height: 35px;
            margin-right: 15px;
            flex: 0 0 auto;
            img {
                border-radius: 100%;
                width: 100%;
                height: 100%;
            }
        }
    }
    .job-description {
        margin-bottom: 20px;
    }
    .job-details-table {
        display: table;
        width: 100%;
        margin-bottom: 15px;
        .job-details-table-row {
            display: table-row;
            .table-label, .table-data {
                display: table-cell;
                padding-bottom: 6px;
                .badge.d-block {
                    display: inline-block !important;
                }
            }
            .table-label {
                width: 120px;
                color: color(site,jobDetailsTableLabel);
                text-align: right;
                padding-right: 13px;
                vertical-align: top;
            }
            &.valign-middle {
                .table-label, .table-data {
                    vertical-align: middle;
                }
            }
        }
        &.label-width-lg {
            .job-details-table-row {
                .table-label {
                    width: auto;
                    min-width: 120px;
                    white-space: nowrap;
                }
            }
        }
    }

    #print-section-jobcard {
        .job-card {
            padding: 0;
        }
    }
}
// .job-labels {
//     display: flex;
//     justify-content: space-between;
//     margin-bottom: 15px;
// }
// .job-labels label {
//     font-size: 12px;
//     font-weight: 600;
// }
// label{
//     color: rgba(0,50,75,0.8);
// }
// .job-details-table {
//     display: table;
//     width: 100%;
//     margin-bottom: 15px;
//     color: rgba(54,80,93,0.9);
//     font-size: 12px;
//     .job-details-table-row {
//         display: table-row;
//         .table-label, .table-data {
//             display: table-cell;
//             padding-bottom: 6px;
//         }
//         .table-label {
//             width: 120px;
//             color: rgba(42, 142, 208, 0.8);
//             text-align: right;
//             padding-right: 13px;
//             vertical-align: top;
//         }
//     }

// }
.uploaded-attachment {
    img {
        width: 100px;
    }
}

.quotes-container {
    .quote-card {
        background: #f8fafb;
        border-radius: 5px;
        margin-bottom: 5px;
        .quote-card-header {
            display: flex;
            justify-content: space-between;
            padding: 15px;
            &:hover {
                cursor: pointer;
            }
            .trade-personal {
                display: flex;
                justify-content: flex-start;
                .trade-pic {
                    flex: 0 0 40px;
                    margin-right: 20px;
                    img.profile-pic {
                        width: 40px;
                        height: 40px;
                    }
                }
                .trade-contact {
                    font-size: 12px;
                    line-height: 1.3em;
                    color: $text-color;
                    h3 {
                        font-size: 14px;
                        font-weight: 600;
                        margin: 0 0 5px 0;
                    }
                    p {
                        margin: 0;
                    }
                }
            }
            .trade-info {
                text-align: right;
                .trade-services {
                    font-weight: 600;
                    font-size: 12px;
                }
                @at-root .quoted-amount {
                    line-height: 1.2em;
                    .quote-value {
                        font-weight: 600;
                        font-size: 14px;
                        .revisedPrice {
                            position: relative;
                            .blink { 
                                position: absolute;
                                right: -3px;
                                top: 3px;
                            }
                        }
                    }
                    .quote-value-old {
                        font-size: 13px;
                        del {
                            color: $text-light;
                            text-decoration: line-through;  
                        }
                    }
                    .quote-type-min-max, .quote-type-rate {
                        text-align: right;
                        table {
                            tbody {
                                background: transparent;
                            }
                            td {
                                padding-right: 15px;
                                line-height: 1em;
                                text-align: left;
                                .quote-details-table & {
                                    padding-right: 0;
                                    padding-left: 15px;
                                    text-align: right;
                                }
                            }
                            .quote-card-header & {
                                margin-left: auto;
                                td {
                                    padding-right: 0;
                                    padding-left: 15px;
                                    text-align: right;
                                }
                            }
                        }
                        .form-label {
                            font-size: 12px;
                        }
                    }
                }
                .rating {
  
                }
            }
        }
        .quote-card-body {
            padding: 0 15px 15px;
            color: $text-color;
            .quote-description {
                padding-bottom: 15px;
            }
            .quote-details-table {
                display: table;
                width: 100%;
                margin-bottom: 15px;
                .job-details-table-row {
                    display: table-row;
                    .table-label, .table-data {
                        display: table-cell;
                        border-top: 1px solid #eff3f6;
                        padding-top: 8px;
                        padding-bottom: 8px;
                        font-size: 12px;
                        vertical-align: top;
                    }
                    .table-label { 
                        color: rgba($text-light,0.8);
                        padding-right: 10px;
                    }
                    .table-data {
                        text-align: right;
                    }
                }
            }
        }
        .quote-actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            padding: 0 15px;
            height: 50px;
            border-top: 1px solid #fff;
            background: lighten($text-light,15);
            border-radius: 0 0 5px 5px;
            &> button {
                background: #fff;
                border-color: #fff;
                border-style: solid;
                border-width: 1px;
                border-radius: 15px;
                color: $text-color;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                padding: 5px 10px;
                font-size: 12px;
                margin-left: 10px;
                font-weight: 600;
                &>i {
                    display: none;
                }
                &:hover:not(:disabled) {
                    background: #dfedf1;
                    cursor: pointer;
                }
            }
            .quote-sub-actions {
                position: absolute;
                right: 5px;
                bottom: calc(100% + 5px);
                width: 250px;
                border: 1px solid lighten($text-light,40);
                border-radius: 5px 5px 0 5px;
                overflow: hidden;
                box-shadow: -1px -1px 15px rgba(0,0,0,0.1);
                &>button {
                    display: block;
                    background: #fff;
                    color: $text-color;
                    width: 100%;
                    text-align: left;
                    font-size: 12px; 
                    padding: 10px 8px;
                    border-style: solid;
                    border-color: lighten($text-light,40);
                    border-width: 0 0 1px 0;
                    &>i {
                        display: none;
                    }
                    &:hover {
                        cursor: pointer;
                        background: lighten($text-light,40);
                    }
                }
            }
        }
    }
  }

  .signature-display {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 10px;
      .signature-details {
          text-align: center;
        .img {
            max-width: 250px;
            img {
                width: auto;
                height: auto;
                max-width: 100%;
            }
        }
        .signature-name {
            font-weight: 700;
        }
        .signature-date {
            color: color(site,txtLightGrey);
        }
      }
      
    .job-completed {
        border: 2px dashed color(site,secondary);
        border-radius: 5px;
        padding: 5px 10px 5px 5px;
        color: color(site, secondary);
        font-weight: 700;
        .complete-icon {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            background: color(site, secondary);
            color: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
        }
    }
  }
  
  .fileslider {
    display: block !important;
    flex-wrap: wrap;
    max-width: 100%;
    background-color: transparent;
    margin-bottom: 10px;
    .fileslider-item {
        width: 125px !important;
        height: 84px !important;
        margin: 0 10px 10px 0 !important;
        background: #fff !important;
        border-radius: 12px;
        border: 1px solid #f5f5f5;
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.provider-profile .profile-view-bg {
    background-color: #eff3f6;
}

// .panel-body {
//     padding: 15px;
//     overflow-y: auto;
//     height: calc(100vh - 105px);
// }
.provider-profile .profile-img-wrapper {
    width: 90%;
    padding-top: 90%;
    position: relative;
    height: 0;
    margin: 0 auto;
}
.provider-profile .profile-img-wrapper .profile-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    overflow: hidden;
}
.provider-profile .profile-img-wrapper .profile-img img {
    width: 100%;
    height: 100%;
}
// .text-center {
//     text-align: center !important;
// }

.font-18 {
    font-size: 18px !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}
.dashboardN .font-14 {
    font-size: 14px !important;
}
.dashboardN h3 {
    font-size: 16px;
    font-weight: 700;
    color: #668290;
}
.dashboardN .label.label-brand-blue {
    background: #668290;
    color: #fff;
}

.dashboardN .label {
    border-radius: 5px;
    display: inline-flex;
    font-size: 11px;
    padding: 4px 6px;
    margin-bottom: 0;
    font-weight: 700;
    align-items: center;
}
.provider-profile .label {
    margin: 3px 2px;
}
.font-600 {
    font-weight: 600 !important;
}

.ellipsis {
    display: flex;
    flex-direction: column;
    height: 18px;
    justify-content: space-between;
}

.font-700 {
    font-weight: 700 !important;
}
.pointer {
    cursor: pointer !important;
}
.clearfix::after {
    display: block;
    clear: both;
    content: "";
}
.font-30 {
    font-size: 30px !important;
}
.profile-stats {
    display: flex;
    justify-content: space-around;
    padding: 22px 5px;
    background: #f9f9f9;
    border-radius: 3px;
    margin: 15px 0;
}
.profile-stats .stat {
    color: rgba(0,50,75,.7);
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    border-left: none;
}
.profile-stats .stat .stat-count {
    font-size: 35px;
    font-weight: bold;
    padding: 0 15px;
    justify-content: space-around;

}
.profile-stats .stat .stat-count-mini {
    font-size: 20px;
    font-weight: bold;
    padding: 0 15px;
    justify-content: space-around;

}
.profile-stats .stat .stat-details {
    font-size: 14px;
    line-height: 1.3em;
    justify-content: space-around;
}
.profile-view-collapse {
    background-color: #eff3f6;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
    margin: 10px 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600 !important;
}
.right-collaps {
    table {
        margin: 0;
        td {
            border: none;
            padding-top:6px;
            padding-bottom: 6px;
        }
    }
}
h1, h2, h3, h4, h5, button {
    font-family: 'Poppins', sans-serif;
}
.chatbot-container{
    height: 100%;
    width: 100%;
    bottom: 0px !important;
    left: initial !important;
    right: 0px !important;
    top: initial !important;
    background: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 12px 12px 0px;
    overflow: hidden;
    position: relative;
    inset: initial;
    width: 100%;
    height: calc(100vh - 25px);
    z-index: 999;
    transform: scale(1);
    transform-origin: right bottom;
    transition: transform 0.3s ease 0s;
    font-family: 'Poppins', sans-serif;
    border: 1px solid #eee;
}

.chatbot-header {
    -webkit-box-align: center;
    align-items: center;
    /*background: rgb(235, 76, 75);*/
    background: #fff;
    color: rgb(255, 255, 255);
    display: flex;
    fill: rgb(255, 255, 255);
    -webkit-box-pack: justify;
    justify-content: center;
    padding: 0px 10px;
}
.chatbot-header .app-logo{
    margin: 30px 0;
}
.chatbot-header-title{
    margin: 0px;
    font-size: 15px;
    font-family: Avenir Next,sans-serif;
    line-height: 1.28571429em;
    margin: calc(2rem - .14286em) 0 1rem;
    font-weight: 700;
}

.chatbot-body {
    height: calc(100vh - 197px);
    overflow-y: auto;
    margin-top: 2px;
    padding-top: 6px;
    position: relative;
}
.chatbot-body .tenant-chat-bot .wizard-actions{
    /* position: absolute;
    bottom: 30px; */
    position: fixed;
    top: calc(100vh - 170px);
    text-align: center;
    width: 100%;
    left: 0;
    padding: 0 40px;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 576px) {
    .chatbot-body .tenant-chat-bot .wizard-actions{
        top: calc(100vh - 205px);
        padding: 0 20px;
        z-index: 1;
    }

    .confirm-otp-custom{
        height: calc(100% - 324px) !important;
    }

    .confirm-otp-custom .wizard-actions{
        top: calc(100% - 225px) !important;
    }
}
.chatbot-body .tenant-chat-bot .wizard-actions .btn-primary{
    background-color: #000 !important;
    height: 55px !important;
    margin-bottom: 0 !important;
    border-radius: 12px !important;
    border: 0 none !important;
    color: #fff !important;
}
.chatbot-body .tenant-chat-bot .wizard-actions .btn-primary:focus{
    box-shadow: 0 0 0 !important;
}
.chatbot-body .tenant-chat-bot .wizard-actions .btn-primary:hover{
    background-color: #333 !important;
}
.tenant-chat-bot .otp-fields .form-control{
    width: 63px;
    margin: 0 10px;
    -moz-appearance: textfield;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}
    .tenant-chat-bot .otp-fields .form-control::-webkit-outer-spin-button,
    .tenant-chat-bot .otp-fields .form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    .common-link{
        color: #1481de !important;
        cursor: pointer;
    }
    .common-link:hover{
        text-decoration: underline !important;
    }

.chatbot-bot-icn{
  align-items: flex-end;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
}
.chatbot-bot-icn-container {
    display: inline-block;
    order: 0;
    padding: 6px;
}
.chatbot-bot-icn-container > img{width: 40px;}
.chatbot-bot-msg{
    animation: 0.3s ease 0s 1 normal forwards running Lmuha;
     background: rgb(235, 76, 75);
    border-radius: 18px 18px 18px 0px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
    color: rgb(255, 255, 255);
    display: inline-block;
    font-size: 14px;
    max-width: 50%;
    margin: 0px 0px 10px;
    overflow: hidden;
    position: relative;
    padding: 12px;
}

.chatbot-user-icn{
    align-items: flex-end;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
}
.chatbot-user-icn-container{
    display: inline-block;
    order: 1;
    padding: 6px;
}
.chatbot-user-icn-container > img{width: 40px;}
.chatbot-user-msg{
    animation: 0.3s ease 0s 1 normal forwards running Lmuha;
    background: rgb(255, 255, 255);
    border-radius: 18px 18px 0px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
    color: rgb(74, 74, 74);
    display: inline-block;
    font-size: 14px;
    max-width: 50%;
    margin: 0px 0px 10px;
    overflow: hidden;
    position: relative;
    padding: 12px;
}

.chatbot-footer{
    /*position: relative;*/
}
@media only screen and (max-width: 576px) {
    .chatbot-footer{
        margin-top: -30px;
    }
}
.chatbot-footer .ui.selection.dropdown{
    border: 1px solid #d0e6f9;
    border-radius: 10px !important;
    font-family: 'Poppins', sans-serif;
    color:#1481de ;
    font-size: 14px;
}
.chatbot-footer .ui.selection.dropdown[name="languageId"]{
    width: 130px;
    min-width: 130px !important;
}
.chatbot-footer .ui.active.upward.selection.dropdown{
    border-radius: 10px !important;
}
.chatbot-footer .ui.selection.dropdown .menu {
    max-height: 320px;
}

.chatbot-input{
        border-width: 1px 0px 0px;
        border-right-style: initial;
        border-bottom-style: initial;
        border-left-style: initial;
        border-right-color: initial;
        border-bottom-color: initial;
        border-left-color: initial;
        border-image: initial;
        border-radius: 0px 0px 10px 10px;
        border-top-style: solid;
        border-top-color: rgb(238, 238, 238);
        box-shadow: none;
        box-sizing: border-box;
        font-size: 16px;
        opacity: 1;
        outline: none;
        padding: 16px 52px 16px 10px;
        width: 100%;
        appearance: none;
}

.chatbot-input > input{
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}
.chatbot-button {
    background-color: transparent;
    border: 0px;
    border-bottom-right-radius: 10px;
    box-shadow: none;
    cursor: pointer;
    fill: rgb(74, 74, 74);
    opacity: 1;
    outline: none;
    padding: 14px 16px 12px;
    position: absolute;
    right: 0px;
    top: 0px;
}

.chatbot-button > button{
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

iframe > body{
    overflow: hidden;
}
.job-post-div{
    /* padding: 25px; */
    background-color:  rgb(255, 255, 255);
}

/* body{overflow: hidden;} */

#chatbot-option-btn {
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 145px);
}

.chat-options-div{
    display: block;
}

.chat-options-ul{
    margin: 2px 0px 12px;
    padding: 0px 6px;
    list-style-type: none;
    padding: 6px 12px;
    display: block;
    list-style-type: disc;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}


.chat-options-li{
    cursor: pointer;
    display: inline-block;
    margin: 2px;
    padding: 5px 0;
    text-align: -webkit-match-parent;
}

.chat-options-li > a {
    color: #000 !important;
    border: 0 none !important;
    border-radius: 16px;
    background: #e6e6e6;
    display: inline-block;
    font-size: 14px;
    padding: 10px 12px;
    text-decoration: none;
    cursor: pointer;
    text-align: left;
}
.chat-options-li > a .icon{
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    font-size: 20px;
    color: color(site, lightBrown) !important;
}
.chat-options-li > a:hover {
    background: #000;
    color: #fff !important;
}
.chat-options-li > a:hover .icon {
    background: #333 !important;
    color: #fff !important;
}



.botError{
    width: 100%;
    background-color: #f78288;
    color: #F0F0F0;
    font-size: 12px;
    padding: 10px;
    text-align: center;
    position: fixed;
    bottom: 55px;
    z-index: 1;
}

#chatbot-body>.radio-btn-group > .btn-group{
      margin: 0 auto;
      flex-direction: column !important;
}

@media only screen and (max-width: 600px) {
    .chatbot-container {
        /*height: 100vh !important;*/
        height: calc(100vh - 25px) !important;
        width: 100% !important;
    }
    .chatbot-body {
        /*margin-bottom: 51px;*/
        /* height: calc(100vh - 140px); */
        height: calc(100vh - 270px);
    }
    .chatbot-footer {
       /* position: fixed;
        bottom: 0;*/
        width: 100%;
    }
    .chatbot-wrapper{
        margin-top: 0px !important;
    }
}

@media only screen and (max-width: 576px) {
    .chatbot-container {
        border: 0px none;
        border-radius: 0px;
        box-shadow: 0 0 0;
        height: calc(100vh - 5px) !important;
    }    
    .chatbot-body {
        height: calc(100vh - 215px);
    }   
    .chatbot-body.welcome {
        height: calc(100vh - 177px);
    }
}

@media only screen and (max-width: 450px) {
    #ifram-container {
        width: calc(100vw - 120px) !important;
    }
}
@media only screen and (max-width: 350px) {
    .chatbot-container {
        height: calc(100vh - 75px) !important;
    }
    .chatbot-footer {
    /*    position: fixed;
        bottom: 0;*/
        width: 100%
    }
}
.jobpost-wizard.chat {
    max-width: unset !important;
    font-family: 'Poppins-Regular';
}
    .jobpost-wizard.chat .wizard-main-title {
        font-family: 'Poppins-SemiBold';
    }
    .jobpost-wizard.chat .userTip {
        font-family: 'Poppins-Medium';
    }
    .jobpost-wizard.chat .wizard-actions .wizard-loader {
        min-height: calc(100vh - 450px);
    }
    .jobpost-wizard.chat .wizard-actions .wizard-contents {
        min-height: calc(100vh - 400px);
    }
.jobpost-wizard.chat .wizard-header .sub-title-section .sub-title {
    float: left;
    width: 75%;
    padding-right: 20px;
}
        .jobpost-wizard.chat .wizard-header .sub-title-section .sub-title h3 {
            font-family: 'Poppins-SemiBold';
            font-size: 24px;
            text-transform: capitalize;
            margin-bottom: 0;
            margin-top: 0;
            color: #000;
        }
        .jobpost-wizard.chat .wizard-header .sub-title-section .sub-title h6 {
            font-family: 'Poppins-Light';
            font-size: 24px;
            text-transform: capitalize;
            margin-bottom: 0;
            color: #000;
        }
    .jobpost-wizard.chat .wizard-header .sub-title-section .count {
        float: left;
        width: 25%;
        padding-left: 20px;
        color: #000 !important;
    }
        .jobpost-wizard.chat .wizard-header .sub-title-section .count h5 {
            font-family: 'Poppins-SemiBold';
            font-size: 60px;
            text-transform: capitalize;
            margin-bottom: 0;
            color: #000 !important;
        }

.chat-card-wrapper {
    margin-bottom: 15px;
    border: 0 none;
    box-shadow: 0 0 0;
}
.chat-card {
    background: #f7f7f7;
    border-radius: 10px;
    padding: 20px;
    color: #000;
    display: inline-block;
    width: 100%;
    font-family: 'Poppins-Regular';
    text-align: left;
}

    .chat-card .title-section {
        display: flex;
        width: 100%;
        align-items: center;
    }

        .chat-card .title-section .chat-title {
            font-family: 'Poppins-SemiBold';
            font-size: 13px;
            text-transform: capitalize;
            float: left;
            width: 65%;
            padding-right: 20px;
            margin-bottom: 5px;
        }
        .chat-card .title-section .tags {
            float: left;
            width: 35%;
            padding-left: 20px;
            font-size: 16px;
        }
        .chat-card .title-section .tags .tag {
            background: #000;
            color: #fff;
            border-radius: 50px;
            padding: 6px 15px;
            text-align: center;
            font-size: 10px;
            float: right;

        }

    .chat-card .date {
        font-size: 11px;
        color: #a8a8a8;
        margin-bottom: 15px;
        font-family: 'Poppins-Regular';
    }

    .chat-card .description {
        font-size: 12px;
        color: #000;
        margin-bottom: 20px;
        font-family: 'Poppins-Regular';
    }
    .chat-card .address {
        text-decoration: underline;
    }
    .chat-card .icon-line {
        display: flex;
        width: 100%;
        align-items: start;
        margin-bottom: 20px;
    }
        .chat-card .icon-line .icon {
            width: 35px;
        }
        .chat-card .icon-line .text {
            width: calc(100% - 35px);
            font-family: 'Poppins-Regular';
            font-size: 12px;
        }
        .chat-card .icon-line .red {
            color: #eb4c4b;
        }

    .chat-card .external-gallery .fileslider .fileslider-item {
        width: 87px !important;
        height: 60px !important;
        margin: 0 8px 4px 0;
        background: #ffffff;
        border-radius: 0px;
    }
    .chat-card .quote-wrapper {
        background: #deeaf5;
        border-radius: 10px;
        padding: 15px;
    }
    .chat-card .quote-wrapper:last-child {
        margin-bottom: 0 !important;
    }
        .chat-card .quote-wrapper .quote {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
            .chat-card .quote-wrapper .quote .text {
                display: flex;
                align-items: start;
                justify-content: start;
                flex-direction: column;
                padding-right: 20px;
                padding-top: 3px;
            }
                .chat-card .quote-wrapper .quote .text .quote-title {
                    margin-bottom: 3px !important;
                    font-size: 12px;
                    font-family: 'Poppins-Regular';
                    font-weight: 400;
                }
                .chat-card .quote-wrapper .quote .text .quote-amount {
                    margin-top: 0 !important;
                    font-size: 18px;
                    font-family: 'Poppins-SemiBold';
                }
            .chat-card .quote-wrapper .quote .button {
                display: flex;
                align-items: center;
                justify-content: end;
                border: 0 none;
                padding: 0;
            }

    .chat-card .bt {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-family: 'Poppins-Light' !important;
        font-size: 14px !important;
        color: #fff !important;
        margin-bottom: 0 !important;
        font-weight: 300 !important;
        border: 0 none;
        padding: 10px 25px;
        border-radius: 23px;
        min-width: 110px;
    }
    .chat-card .bt:focus {
        outline: 0 none;
    }
        .chat-card .bt.bt-blue {
            background: #1481de;
        }
        .chat-card .bt.bt-red {
            background: #eb4c4b;
        }

    .chat-card .variation {
        background: #deeaf5;
        border-radius: 10px;
        padding: 15px;
        display: inline-block;
        width: 100%;
    }
    .chat-card .variation:last-child {
        margin-bottom: 0 !important;
    }
        .chat-card .inner-buttons {
            display: flex;
            justify-content: end;
            align-items: center;
            width: 100%;
        }
            .chat-card .inner-buttons .bt {
                margin-left: 12px;
            }

    .chat-card .external-gallery .fileslider .fileslider-item img {
        max-width: 92px;
    }

    .chat-card .approve-reject-section {
    }
        .chat-card .approve-reject-section .approve-reject-title {
            font-family: 'Poppins-Light' !important;
            font-size: 24px !important;
            color: #000 !important;
            margin-bottom: 20px;
        }
        .chat-card .approve-reject-section .approve-reject-title span {
            font-weight: 600;
        }
    .chat-card .approve-reject-reason {
    }
        .chat-card .approve-reject-reason label {
            font-family: 'Poppins-Medium' !important;
            font-size: 14px !important;
            color: #000 !important;
            margin-bottom: 3px;
        }
        .chat-card .approve-reject-reason textarea {
            min-height: 200px;
            max-height: 250px;
            font-family: 'Poppins-Regular';
        }

.success-section-wrapper {
}
    .success-section-wrapper .success-section {
    }
        .success-section-wrapper .success-section .success-title {
            font-size: 24px;
            font-family: 'Poppins-Light';
            color: #000;
        }
        .success-section-wrapper .success-section .success-description {
            font-size: 12px;
            font-family: 'Poppins-Regular';
            color: #000;
        }

.tenant-chat-bot .btn-primary{
    border: 1px solid #d0e6f9 !important;
    border-radius: 8px !important;
    font-family: 'Poppins', sans-serif;
    color: #1481de !important;
    font-size: 12px;
    background: #e3f0fb !important;
    padding: 4px 12px;
    text-transform: capitalize;
}
    .tenant-chat-bot .btn-primary:hover{
        background: #d0e6f9;
    }

.tenant-chat-bot .btn-primary.tile-button{
    border: 1px solid #d0e6f9 !important;
    border-radius: 8px !important;
    font-family: 'Poppins', sans-serif;
    color: #1481de !important;
    font-size: 12px;
    background: #e3f0fb !important;
    padding: 4px 12px;
    text-transform: capitalize;
}
    .tenant-chat-bot .btn-primary.tile-button:hover{
        background: #d0e6f9;
    }

.disabled-link {
    pointer-events: none;
    cursor: default;
}
// @import 'src/Styles/style.scss';

input {
    border: 1px solid #e9ecef;
    padding: 0.5rem;
    // width: 300px;
  }
  
  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }
  
  .suggestions {
    border: 1px solid #e9ecef;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: #f8f9fa;
    color: #818182;
    cursor: pointer;
    font-weight: 400;
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #e9ecef;
  }
@import "src/Styles/_theme";

.dv-star-rating {
    label {
        &.dv-star-rating-star {
            margin-bottom: 0;
            i {
                font-size: 30px;
                // color: color(site, starBg) !important;
                color: #fff !important;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: #f8e71c;
            }
            &.dv-star-rating-full-star {
                i {
                    // color: color(site, StarOnbgFeedback) !important;
                    color: #f8e71c !important;
                }
            }
        }
    }
}

.verticle-margin-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.feed-bk-drwr {
    .table-label {
        width: 100% !important;
        text-align: left !important;
        padding-bottom: 0px !important;
        float: left !important;
        color: #9d9d9d !important;
        font-weight: 600 !important; 
    }

    .table-data {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.2px;
        color: #383d4a;
        float: left;
    }

    .form-control {
        background: #fff !important;
        min-height: 120px !important;
    }
}

.form-control {
    background: #fff !important;
}

.feedback-btn {
    background: #000;
    color: #fff;
    padding: 16px 24px;
    border-radius: 10px;
    h3 {
        font-size: 14px;
  font-weight: 500;
    }
}

//imported to application.scss
//multichat
@import 'src/Styles/mixins';
@import 'src/Styles/theme';
$chat-window-width: 400px;
.multi-chat-container {
    position: fixed;
    right: 15px;
    bottom: 1px;
    z-index: 200;
    display: flex;
    width: calc(100% - 275px);
    height: 1px;
    align-items: flex-end;
    flex-direction: row-reverse;
    &> * {
        flex: 0 1 $chat-window-width;
        padding-left: 20px;
        box-sizing: border-box;
        max-width: $chat-window-width;
    }
    @include respond-to(phablet){
        width: 100%;
    }
}
//chat window
.chat-locator { 
    width: 100%;
    max-width: $chat-window-width;
    // position: fixed;
    // right: 300px;
    // bottom: 1px;
    // z-index: 150;
    @include respond-to(phablet){
        max-width: 100%;
        flex-basis: 100%;
        padding-left: 30px;
    }
}
.chat-container {
    background: #fff;
    font-size: 12px;
    box-shadow: 0 -2px 38px 0px rgba(#000, 0.2);
    border-radius: 5px 5px 0 0;
    //width: $chat-window-width;
    width: 100%;
    &.font-up {
        font-size: 14px;
        .ch-body {
            .ch-set {
                margin-bottom: 12px;
                .ch-dateTime {
                    font-size: 11px;
                }
            }
        }
        
    }
    &.chat-maximum {
        width: 700px;
        max-width: calc(100vw - 340px);
        .ch-body {
            .ch-body-inner {
                height: 100vh;
                max-height: calc(100vh - 150px);
            }
        }
    }
    .ch-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $brand-primary;
        padding: 10px;
        color: #fff;
        border-radius: 5px 5px 0 0;
        &.unread-chats {
            background-color: #f19e1f;
            animation: unread-chats 4s ease;
        }

        .ch-profile-img, .ch-phone, .ch-webcam, .ch-font-up {
            flex-shrink: 0;
        }

        .ch-profile-img {
            width: 35px;
            height: 35px;
            border-radius: 100%;
            overflow: hidden;
            margin-right: 5px;
            background: #fff;
            &:hover {
                cursor: pointer;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .ch-title {
            flex-grow: 1;
            white-space: nowrap;
            input {
                background: transparent;
                border: none;
                font-weight: 400;
                color: #fff;
                width: 100%;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                transition: background .2s ease;
                padding: 3px 5px;
                border-radius: 3px;
                font-weight: 600;
                &:hover:not(:disabled) {
                    background: rgba(#fff, 0.3);
                }
                &:focus:not(:disabled) {
                    background: rgba(#fff, 0.3);
                }
            }
        }

        .ch-phone, .ch-webcam, .ch-user-plus {
            // font-size: 22px;
            // margin-left: 20px;
        }
        .ch-addition {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            background: rgba(#fff, 0.2);
            flex-shrink: 0;
            flex-grow: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            margin-left: 5px;
            color: #fff;
            border: none;
        }
        .ch-phone, .ch-webcam, .ch-user-plus, .ch-minimize, .ch-close, .ch-font-up {
            &:hover {
                opacity: 0.7;
                cursor: pointer;
            }
        }
        .ch-resize {
            margin-left: 20px;
        }
        .ch-minimize {
            margin-left: 10px;
        }
        .ch-close {
            margin-left: 10px;
        }
    }

    .ch-switch-options {
        background: #dce4ea;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .switch-title {
            font-weight: 600;
            font-size: 11px;
            //max-width: calc(100% - 180px);
            overflow: hidden;
            span {
                @include truncate();
                display: block;
            }
        }
        .switch-actions {
            flex: 0 0 auto;
            text-align: right;
            display: flex;
            .button {
                margin-left: 3px;
            }
        }
    } 

    .ch-body {
        $chat-height: 400px;
        $max-height-offset: 300px;
        $rds: 5px;
        padding: 0 0 10px;
        position: relative;
        .ch-body-inner {
            padding: 20px 15px 0px 15px;
            max-height: calc(100vh - #{$max-height-offset});
            height: $chat-height;
            overflow-y: auto;
            @include respond-to(phablet){
                max-height: calc(100vh - 115px);
                height: 100vh;
            }
        }
        .ch-search {
            padding: 5px 10px 5px 0;
            position: absolute;
            width: 100%;
            background: rgba(#fff,0.8);
            display: flex;
            align-items: center;
            border-bottom: 1px solid #dce4ea;
            z-index:100;
            .search-icon {
                font-size: 20px;
                color: $text-light;
                margin-bottom: -3px;
            }
            .search-controls {
                display: flex;
                align-items: center;
                padding-left: 3px;
                .searchUpDown {
                    width: 22px;
                    height: 22px;
                    border-radius: 100%;
                    background: #dce4ea;
                    border: none;
                    font-size: 12px;
                    color: $text-light;
                    margin-left: 5px;
                    &:hover {
                        cursor: pointer;
                    }
                    @include flex-center();
                    i {
                        transform: rotate(90deg);
                    }
                    &.searchUp {
                        i {
                            margin-top: -2px;
                        }
                    }
                }
                .search-count {
                    padding: 0 5px;
                    font-weight: 600;
                }
            }
            .form-control {
                border-width: 0;
                border-radius: 0;
                padding: 7px 5px 8px 10px;
                font-size: 13px;
                background: transparent;
            }
        }
        .ch-switch-select-body {
            max-height: calc(100vh - #{$max-height-offset} - 50px);
            height: calc(#{$chat-height} - 50px);
            margin-top: 50px;
            overflow-y: auto;
            .ch-swith-to {
                display: flex;
                align-items: center;
                padding: 10px;
                border-bottom: 1px solid rgba(#95afc0,.1);
                .switch-to-img {
                    flex: 0 0 40px;
                    .ba-icn {
                        font-size: 25px;
                    }
                    img {
                        max-width: 100%;
                        max-height: 100%;
                        width: auto;
                        height: auto;
                    }
                }
                .switch-to-action {
                    flex: 0 0 50px;
                    text-align: right;
                    display: flex;
                    justify-content: flex-end;
                    button {
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                        background: #82d48f;
                        border: none;
                        color: #fff;
                        transform: rotate(45deg);
                        margin-right: 5px;
                        @include flex-center();
                        &:hover:not(:disabled){
                            cursor: pointer;
                            opacity: 0.7;
                        }
                    }
                }
                .switch-to-details {
                    flex: 1 1 0;
                    overflow: hidden;
                    h3 {
                        font-size: 12px;
                        font-weight: 600;
                        margin: 0;
                    }
                    p {
                        font-size: 11px;
                        margin: 0;
                    }
                    h3, p {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }
            }
        }
        .ch-global-message {
            font-size: 11px;
            font-weight: 600;
            text-align: center;
            padding: 15px;
            margin: 15px 0;
            color: $brand-primary;
        }
        .ch-set {
            $rds: 5px;
            margin-bottom: 5px;
            @include clearfix;
            img {
                max-width: 100%;
                height: auto;
                display: block;
                margin-top: 5px;
            }
            span {
                display: block;
                clear: both;
                padding: 10px;
                max-width: 100%;
                overflow-wrap: break-word;
                word-wrap: break-word;
                word-break: break-word;
            }

            .ch-dateTime {
                clear: both;
                color: rgba($text-light,0.6);
                margin: 0 5px;
                font-size: 10px;
            }


            &.ch-left {
                display: flex;
                align-items: flex-end;

                .ch-pic {
                    width: 35px;
                    height: 35px;
                    @include border-radius(100%);
                    margin: 0 6px 21px 0;
                    overflow: hidden;
                    flex-shrink: 0;

                    img {
                        width: 100%;
                        height: 100%;
                        margin-top: 0px;
                    }
                }

                .ch-dateTime {
                    float: left;
                }
                p {
                    color: #fff;
                    margin-bottom: 0;
                }
                span {
                    float: left;
                    @include border-radius(0 $rds $rds 0);
                    background: #7e97a4;
                    color: #fff;

                    &:first-child {
                        @include border-radius($rds $rds $rds 0);
                    }

                    &:last-of-type {
                        @include border-radius(0 $rds $rds $rds);
                    }
                }
            }

            &.ch-right {
                .ch-dateTime {
                    float: right;
                }

                span {
                    float: right;
                    margin-left: 45px;
                    @include border-radius($rds 0 0 $rds);
                    background: #eff3f6;
                    color: rgba(0, 50, 75, 0.7);

                    &:first-child {
                        @include border-radius($rds $rds 0 $rds);
                    }

                    &:last-of-type {
                        @include border-radius($rds 0 $rds $rds);
                    }
                }
            }
        }
    }

    .ch-post {
        background: #eaf7f7;
        padding: 3px;

        input {
            background: #eaf7f7;
            border: 1px solid #eaf7f7;
            border-radius: 0;
            padding: 16px;
        }

        button {
            background: #eaf7f7;
            border: 1px solid #eaf7f7;
            border-radius: 0;
            // background: url(../images/send.svg) no-repeat center center;
            background-size: 25px 25px;
            // overflow: hidden;
            // text-indent: -500px;
            width: 25px;
            box-sizing: content-box;
            display: flex;
            align-items: center;
            &:focus {
                box-shadow: none;
            }
            i {
               font-size: 25px;
               color: $brand-primary; 
            }
        }
    }
}


//chat users list
.chat-users-container {
    .tl-container &, .communication-drawer & {
        // position: fixed;
        // top: 56px;
        // right: 0;
        // bottom: 0;
        // width: $timeline-width;
        background: #fff;
        // padding: 10px 0 5px 0;

        .adminLayout & {
        }
    }

    .ch-user-heading {
        display: flex;
        // justify-content: space-around;
        padding: 0px 15px;

        button {
            font-size: 12px;
            text-align: center;
            color: $base-color;
            background: transparent;
            border: none;
            padding-right: 20px;

            &.selected {
                color: $green-color;
                cursor: default;
            }

            i {
                font-size: 20px;
                display: block;
                margin: 0 auto;
            }

            &:hover {
                color: $green-color;
                cursor: pointer;
            }

            &:active, &:focus {
                outline: none;
            }

            &.active {
                color: $green-color;
            }
        }
        .panel-tabs {
            padding-bottom: 15px;
        }
    }

    .chat-user-search {
        padding: 15px 0 10px 0;

        .tl-container & {
            padding: 5px 0;
        }

        input {
            background: #fff;
            border: none;
            width: 96%;
            display: block;
            padding: 6px 15px;
            margin:0 auto;
            border-radius:5px;
        }
    }

    h3 {
        padding: 10px 15px;
    }

    .chat-users-selected {
    }

    .chat-user-selected {
        padding-top: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: column;

        .chat-user-img {
            width: 50px;
            height: 50px;
            flex: 0 0 auto;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
            }

            .remove-selected {
                position: absolute;
                top: -2px;
                right: -3px;
                font-size: 11px;
                width: 20px;
                height: 20px;
                background: $color-danger;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 100%;
            }

            .online-status {
                width: 12px;
                height: 12px;
                border-radius: 100%;
                position: absolute;
                bottom: 2px;
                right: 0;

                &.user-available {
                    background: #32b71b;
                }

                &.user-not-available {
                    background: #f69f00;
                }
            }
        }

        .chat-username {
            font-size: 10px;
            text-align: center;
        }
    }

    .chat-users {
        width: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        max-height: calc(100vh - 400px);
        min-height: calc(100vh - 500px);
        &.panel-body {
            max-height: calc(100vh - 190px) !important;
            min-height: calc(100vh - 500px) !important;
        }
        .tl-container & {
            max-height: calc(100vh - 155px);
        }

        .chat-user {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow: hidden;
            width: 100%;
            //margin-bottom: 10px;
            &.chat-contacts {
                padding-bottom: 10px;
            }
            &:hover {
                // background: #ebeff1;
            }

            &.unread {
                font-weight: 700;
            }

            .chat-user-img {
                margin-right: 10px;
                width: 40px;
                height: 40px;
                flex: 0 0 auto;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                }

                .online-status {
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    position: absolute;
                    bottom: 2px;
                    right: 0;
                    border: 1px solid #fff;

                    &.user-available {
                        background: #32b71b;
                    }

                    &.user-not-available {
                        background: #f69f00;
                    }
                }

                &.support-chat {
                    border-radius: 50%;
                    // background: url(../images/support-chat.svg);
                }

                &.bot-chat {
                    border-radius: 50%;
                    // background: url(../images/bot-chat.svg);
                }
            }

            .chat-user-right {
            }

            .chat-username {
                flex: 1 0 auto;
                font-size: 13px;
                width: calc(100% - 132px);
                cursor: pointer;

                span {
                    @include truncate();
                    display: block;

                    &.chat-recent {
                        color: rgba($text-light, 0.8);
                    }
                }
            }

            .chat-poke-btn {
                font-size: 18px;

                &:hover {
                    cursor: pointer;
                }
            }

            .unread-chats {
                flex: 0 0 20px;
                width: 20px;
                height: 20px;
                justify-self: flex-end;
                background: $text-light;
                font-size: 12px;
                line-height: 21px;
                border-radius: 100%;
                color: #fff;
                text-align: center;
                margin-left: auto;
            }

            .custom-control {
                margin-right: 0;

                .custom-control-indicator {
                    top: 8px;
                }
            }
        }
        .chat-content {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            .chat-notification {
                text-align: right;
                .chat-time {
                    color: #668290;
                    font-size: 12px;
                }
                .chat-unread {
                    font-size: 10px;
                    color: #fff;
                    background-color: rgba(53, 185, 74, 0.62);
                    border-radius: 50%;
                    text-align: center;
                    width: 15px;
                    height: 15px;
                    margin-left: 20px;
                }
            }
            &:hover {
                background-color: #ebeff1;
            }
        }

        .chat-poke {
            background: #eaf7f7;
            padding: 3px;
            position: relative;
            top: -1px;
            border-bottom: 1px solid #dde3e6;

            input {
                background: #eaf7f7;
                border: 1px solid #eaf7f7;
                border-radius: 0;
                padding: 7px 8px;
                font-size: 13px;
            }

            button {
                background: #eaf7f7;
                border: 1px solid #eaf7f7;
                border-radius: 0;
                width: 25px;
                box-sizing: content-box;
                padding: 0px 5px;

                &:hover {
                    cursor: pointer;
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }

                &.poke-send {
                    font-size: 18px;

                    &:hover {
                        color: $green-color;
                    }
                }

                &.poke-close {
                    font-size: 12px;
                    width: auto;

                    &:hover {
                        color: $color-danger;
                    }
                }
            }
        }
    }
}
.chat-users-container {
    .com-drawer-icons {
        width: 40px;
        left: -40px;
        // top: 80px;
        top: 108px;
        position: absolute;
        background: #e2e2e2;
        border-radius: 5px 0 0 5px;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                button {
                    height: 40px;
                    font-size: 18px;
                    display: block;
                    padding: 5px 0;
                    background: transparent;
                    border: none;
                    width: 100%;
                    position: relative;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    &:active, &:focus {
                        border: none;
                        outline: none;
                    }
                    &.selected {
                        color: color(site,highlighter);
                    }
                }
            }
        }
        @include respond-to(phablet){
            position: fixed;
            border-radius: 5px;
            top: 10px;
            right: 75px;
            left: inherit;
            width: 55px;
            &>ul {
                display: flex;
                justify-content: space-around;
                li {
                    button {
                        height: 27px;
                    }
                }
            }
            .slideInLeft & {
                display:none;
            }
        }
    }
}

.notification-center {
    position: absolute;
    width: 470px;
    max-width: 100%;
    background: #fff;
    border: 1px solid #e1e2e2;
    padding: 15px 0;
    top: 50px;
    right: 0;
    border-radius: 5px;
    text-align: left;
    .communication-drawer & {
        border: none;
        background: transparent;
        position: relative;
        padding-top: 5px;
        top: 0;
    }
    .notification-header {
        padding: 0px 0px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        h3 {
            font-size: 17px;
            font-weight: 600;
            margin: 0;
        }
    }
    .notification-body {
        padding: 0 15px;
        max-height: calc(100vh - 140px);
        overflow-y: auto;
    }
    ul {
        margin: 0;
        list-style: none;
        padding: 0;
        li {
            margin: 0;
            padding: 0;
        }
    }
    .notification-item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 20px;
        .notification-img {
            width: 50px;
            margin-right: 10px;
            img {
                width: 100%;
                height: auto;
            }
        }
        .notification-detail {
            flex: 0 1 auto;
            width: calc(100% - 60px);
            h4 {
                font-size: 16px;
                font-weight: normal;
                margin-bottom: 5px;
                .notification-blink {
                    content: "";
                    width: 8px;
                    height: 8px;
                    background-color: $color-warning;
                    border-radius: 50px;
                    margin: 2px 0px 5px -10px;
                    display: block;
                    float: right;
                }
            }
            p {
                font-size: 12px;
                margin-bottom: 0;
                color: $base-color-7;
            }
        }
    }
    .notification-clicked {
        background-color: #edf5fd;
        padding: 6px;
        border-radius: 5px;
        margin-bottom: 20px;
        & .notification-item {
            margin-bottom: 0px;
        }
        .notification-blink {
            content: "";
            width: 8px;
            height: 8px;
            background-color: $color-warning;
            border-radius: 50px;
            margin: 2px 0px 5px -10px;
            display: block;
            float: right;
        }
    }
}

.notification-center {
    position: absolute;
    width: 470px;
    max-width: 100%;
    background: #fff;
    border: 1px solid #e1e2e2;
    padding: 15px 0;
    top: 50px;
    right: 0;
    border-radius: 5px;
    text-align: left;
    .notification-header {
        padding: 0px 15px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        h3 {
            font-size: 17px;
            font-weight: 600;
            margin: 0;
        }
    }
    .notification-body {
        padding: 0 15px;
        max-height: calc(100vh - 140px);
        overflow-y: auto;
    }
    ul {
        margin: 0;
        list-style: none;
        padding: 0;
        li {
            margin: 0;
            padding: 0;
        }
    }
    .notification-item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 20px;
        .notification-img {
            width: 50px;
            margin-right: 10px;
            img {
                width: 100%;
                height: auto;
            }
        }
        .notification-detail {
            flex: 0 1 auto;
            width: calc(100% - 60px);
            h4 {
                font-size: 16px;
                font-weight: normal;
                margin-bottom: 5px;
            }
            p {
                font-size: 12px;
                margin-bottom: 0;
                // color: $base-color-7;
                color: #181c2f;
            }
        }
    }
    .notification-clicked {
        background-color: #edf5fd;
        padding: 6px;
        border-radius: 5px;
        margin-bottom: 20px;
        & .notification-item {
            margin-bottom: 0px;
        }
        .notification-blink {
            content: "";
            width: 8px;
            height: 8px;
            background-color: $color-warning;
            border-radius: 50px;
            margin: 2px 0px 5px -10px;
            display: block;
            float: right;
        }
    }
}

.notification-detail {
    a {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      color: #181c2f !important;
    }
  
    .notification-date {
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #126ad9 !important;
    }
  }
  
  .notifi-all{
    a{
      color: #126ad9 !important;
    }
  }
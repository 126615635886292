.freq-srvs {
    .radio-btn-group .btn-group {
        flex-direction: row;

        .btn {
            min-width: fit-content;
            margin: 5px;
            border-radius: 5px !important;
            border-color: #e0e0e0;
            color: #000000;
            background: #fff;
            border-radius: 10px !important;

            &.active {
                border: 1px solid #d3f1e3;
                color: #000;
            }
        }
    }

    .wizard-header{
        margin-bottom: 0px;

        .userTip{
            margin-bottom: 25px;
        }
    }
}

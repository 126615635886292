@import "../../../styles/mixins";
@import "../../../styles/theme";
.ba-drawer {
    h5 {
        font-size: 14px;
        font-weight: 600;
    }
    .grid-filter {
        .input-group {
            position: relative;
            .form-control {
                padding-right: 32px;
                height: 34px;
            }
            .search-key {
                width: 180px;
                margin-left: 5px;
                background: #fff;
                @include respond-to(laptop) {
                    width: 120px;
                }
            }
            .grid-search-reset {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                padding: 0;
                width: 32px;
                background: transparent;
                color: $color-danger;
                border: none;
                z-index: 4;
                &.no-btn {
                    right: 3px;
                }
                &:focus,
                &:active {
                    outline: none;
                }
            }
        }
    }
    .form-group {
        .custom-control {
            .custom-control-description {
                padding-top: 3px;
            }
        }
    }

    .tab-content {
        padding: 0 0px;
        overflow-y: auto;
        height: calc(100vh - 130px); //105
        h5 {
            color: rgba(54, 80, 93, 0.9);
        }
    }
}
.drawer-list {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        position: relative;
        padding: 0 0 3px 20px;
        margin: 0;
        color: $text-light;
        &::before {
            font-family: "ba-icn";
            content: "\e91f";
            position: absolute;
            top: 3px;
            left: 0;
            color: $highlight;
            font-size: 11px;
        }
    }
}

//place job detail on top
.communication-drawer {
    .com-drawer-icons {
        width: 40px;
        left: -40px;
        // top: 80px;
        top: 108px;
        position: absolute;
        background: #fff;
        border-radius: 5px 0 0 5px;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                margin: 0;
                padding: 0;
                button {
                    height: 40px;
                    font-size: 18px;
                    display: block;
                    padding: 5px 0;
                    background: transparent;
                    border: none;
                    width: 100%;
                    position: relative;
                    @include flex-center();
                    &:hover {
                        cursor: pointer;
                    }
                    &.selected {
                        color: $brand-primary;
                    }
                    span {
                        position: absolute;
                        background: #fd2b2b;
                        color: #fff;
                        width: 18px;
                        height: 18px;
                        top: 4px;
                        left: 3px;
                        @include flex-center();
                        font-size: 10px;
                        border-radius: 100%;
                    }
                }
            }
        }
    }
    &.ba-drawer {
        .drawer-panel {
            z-index: 43;
            @include respond-to(phablet) {
                z-index: 53;
            }
        }
    }
    &.slideInLeft {
        .drawer-panel {
            z-index: 1031;
        }
    }
}

//drawer
.slideInLeft {
    & > .drawer-panel {
        animation: slideInLeft 300ms ease 1;
    }
}
.ba-drawer {
    &.drawer-xxl {
        & > .drawer-panel {
            width: 1150px;
            right: -1150px;
            max-width: 100%;
        }
    }

    &.drawer-xl {
        & > .drawer-panel {
            width: 880px;
            right: -880px;
            max-width: 100%;
        }
    }

    &.drawer-lg {
        & > .drawer-panel {
            width: 550px;
            right: -550px;
        }
    }

    &.drawer-sm {
        & > .drawer-panel {
            width: 350px;
            right: -350px;
        }
    }

    &.slideInLeft {
        & > .drawer-panel {
            right: 0 !important;
        }

        &:after {
            content: "";
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1030;
            background: rgba($base-color, 0.5);
            transition: all 300ms ease-in;
            animation: fadeIn 300ms ease 1;
        }
    }

    .drawer-panel {
        transition: all 300ms ease;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 435px;
        right: -435px;
        z-index: 1031;
        background: #f3f3f3;
        max-width: 100%;
        // .tab-pane.fade {
        //     display: none;
        // }
        .job-tray-opened & {
            bottom: 50px;
        }

        &.drawer-lg {
            width: 520px;
            right: -520px;
        }

        .panel-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 10px 10px 15px;
            border-bottom: 1px solid rgba(149, 175, 192, 0.16);

            h2 {
                font-size: 16px;
                margin: 0 !important;
                font-weight: 600;
            }

            .panel-header-actions {
                display: flex;

                .action-btn {
                    background: rgba(#eff3f6, 0.5);
                    border: none;
                    padding: 0 8px;
                    margin-right: 5px;
                    height: 30px;
                    color: $text-color;
                    border-radius: 5px;
                    font-weight: 600;
                    @include flex-center();
                    font-size: 11px;

                    i {
                        font-size: 14px;
                        margin-right: 6px;
                    }

                    &:hover {
                        cursor: pointer;
                        background: #eff3f6;
                    }

                    &.panel-close,
                    &.panel-minimize {
                        background: none;
                        margin-right: 0px;

                        i {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .button {
            font-size: 12px;
        }
    }

    rating .star-icon:before {
        top: 2px;
    }

    &.post-job {
        .drawer-panel {
            z-index: 160;
        }

        &:after {
            z-index: 158;
        }
    }

    &.job-details-related {
        .drawer-panel {
            z-index: 180;
        }

        &:after {
            z-index: 178;
        }
    }

    .panel-body {
        padding: 15px;
        overflow-y: auto;
       // min-height: calc(100vh - 120px); //105px
        // min-height: 120px;
        min-height: calc(100vh - 145px);
        max-height: calc(100vh - 145px);
        @media only screen and (max-width: 450px) {
            max-height: calc(100vh - 200px);
            min-height: calc(100vh - 200px) !important;
        }

        &.tabbed-panel {
            height: calc(100vh - 145px);
        }
        &.reschedule-drawer {
            height: 600px !important;
            @media only screen and (min-width: 992px) {
                height: 720px !important;
            }
            @media only screen and (min-width: 1200px) {
                height: 825px !important;
            }
        }

        &.chatbot-invite-trade {
            min-height: calc(100vh - 207px);
            max-height: calc(100vh - 207px);
        }

        &::-webkit-scrollbar {
            width: 9px;
            height: 9px;
            background-color: rgba($base-color, 0.02);

            &-track {
                // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                border-radius: 10px;
                background-color: rgba($base-color, 0.1);
            }

            &-thumb {
                border-radius: 10px;
                // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                background-color: rgba($base-color, 0.15);

                &:hover {
                    background-color: rgba($base-color, 0.25);
                }
            }
        }
    }

    .panel-body-bot {
        padding: 15px;
        overflow-y: auto;
        //height: 435px; //365px
        height: calc(100vh - 140px);
        &.tabbed-panel {
            height: calc(100vh - 145px);
        }

        &::-webkit-scrollbar {
            width: 9px;
            height: 9px;
            background-color: rgba($base-color, 0.02);

            &-track {
                // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                border-radius: 10px;
                background-color: rgba($base-color, 0.1);
            }

            &-thumb {
                border-radius: 10px;
                // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                background-color: rgba($base-color, 0.15);

                &:hover {
                    background-color: rgba($base-color, 0.25);
                }
            }
        }
    }
    @media only screen and (max-width: 576px) {
        .panel-body-bot {
            height: calc(100vh - 225px);
        }
    }

}
.panel-tabs {
    .nav-tabs {
        margin: 0;
        padding: 0 0 0 15px;
        border-bottom: 1px solid rgba(149, 175, 192, 0.16);
        // li {
        //     margin: 0 3px 0 0;
        //     padding: 0;
        //     a {
        //         padding: 10px 7px;
        //         margin-bottom: -1px;
        //         display: block;
        //         color: $text-color;
        //         font-size: 12px;
        //         border-bottom: 1px solid transparent;
        //         position: relative;
        //         .new-item {
        //             position: absolute;
        //             right: 3px;
        //             top: 9px;
        //             width: 6px;
        //             height: 6px;
        //             border-radius: 100%;
        //             background: $brand-primary;
        //         }
        //         &:hover {
        //             cursor: pointer;
        //         }
        //     }
        //     &:hover, &.selected {
        //         a {
        //             color: $brand-primary;
        //             border-bottom: 1px solid $brand-primary;
        //         }
        //     }
        // }
        .nav-link {
            padding: 10px 7px;
            margin-bottom: -1px;
            display: block;
            color: rgba(54, 80, 93, 0.9);
            font-size: 12px;
            border-bottom: 1px solid transparent;
            position: relative;
            border: none;
        }
        .nav-link:hover,
        .nav-link.active {
            color: #eb4c4b;
            border: none;
            border-bottom: 1px solid #eb4c4b;
        }
        .tab-content {
            padding: 0px;
            overflow-y: auto;
            height: calc(100vh - 105px);
            h5 {
                color: rgba(54, 80, 93, 0.9);
            }
        }
    }
}

.panel-footer {
    position: unset;
    bottom: 0;
    width: 100%;
    //background: color(site,drawerFooterBg);
    height: auto;
    background: #f3f3f3 !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .card-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        // background: color(site,drawerFooterBg);
        background: #f3f3f3 !important;
        position: relative;
        padding: 5px 15px;

        &.chatbot-trade-selected {
            min-height: calc(100vh - 388px);
            max-height: calc(100vh - 388px);
        }
        & > button,
        app-user-chat > button,
        a.button {
            background: #34495e !important;
            border-color: #fff;
            border-style: solid;
            border-width: 0px;
            border-radius: 5px;
            margin-left: 5px;
            // color: color(site,primary);
            color: #fff !important;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 3px 15px;
            min-height: 27px;
            font-size: 12px;
            line-height: normal;
            font-weight: 600 !important;
            font-family: "Open Sans", sans-serif;

            &.button-alert {
                transition: all 300ms ease-in;
                background: #7ed18d;
                color: #186725;

                &:not(:disabled) {
                    &:hover {
                        background: rgba(#7ed18d, 0.8);
                    }
                }
            }

            &.primary-action,
            &.more-actions,
            &.button-primary {
                color: $brand-primary;
            }

            & > [class^="ba-icon"] {
                height: 50px;
                flex: 1;
            }

            & > [class^="ba-icn"] {
                display: none;
            }

            .ba-icn {
                font-size: 12px;
                margin-right: 5px;

                &.ba-icn-work-order,
                &.ba-icn-calendar-gray,
                &.ba-icn-job-done {
                    font-size: 13px;
                }

                &.ba-icn-trades-remind {
                    font-size: 13px;
                }

                &.ba-icn-change-payer {
                    font-size: 13px;
                }

                &.ba-icn-accept-reject,
                &.ba-icn-job-variation-add,
                &.ba-icn-place-quote,
                &.ba-icn-revise-quote,
                &.ba-icn-reject-revise-quote,
                &.ba-icn-request-revision {
                    font-size: 14px;
                }

                &.ba-icn-recover-bin {
                    font-size: 13px;
                }

                &.ba-icn-reassign-job,
                &.ba-icn-assign-job {
                    font-size: 15px;
                }

                &.ba-icn-feedback-complete {
                    font-size: 15px;
                }
            }

            &:hover:not(:disabled) {
                opacity: 0.8;
                cursor: pointer;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &:disabled {
                cursor: no-drop;
                color: rgba(#17455c, 0.7);
            }

            &.btn-danger {
                background: #fdeded;
                border-color: #f5d2d2;

                &:hover {
                    background: #fbdfdf;
                    border-color: #f5d2d2;
                }
            }

            &:disabled {
                [class^="ba-icon"] {
                    opacity: 0.3;
                }
            }

            &:first-child {
                border-left: 0;
            }
        }

        .cancel-action{
            border-radius: 20px;
            padding: 10px 40px;
            background: #E6E6E6 !important;
            color: black !important;
            text-decoration: unset;
            font-weight: bolder !important;
        }

        .more-actions {
            background: #ffffff  !important;
            color: #eb4c4b !important;
        }
        .action-reject{
            background: none !important;
            color: #eb4c4b !important;
          }
          .action-accept{
            background: #ffffff !important;
            color: #2bb460 !important;
          }

        app-user-chat {
            display: block;
            border: none;

            & > button {
                width: 100%;

                &:first-child {
                    border-left: 1px solid rgba(126, 151, 164, 0.2);
                }
            }

            .chat-list-container {
                margin: 0;

                .close {
                    width: 30px;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }

                .chat-list {
                    top: unset;
                    bottom: 0;
                    box-shadow: 0px -6px 19px -14px;
                    border-radius: 5px 5px 0 0px;

                    .chat-user-group {
                        clear: both;
                    }
                }
            }
        }

        .card-sub-actions {
            position: absolute;
            right: 5px;
            bottom: calc(100% + 5px);
            width: 250px;
            border: 1px solid lighten($text-light, 40);
            border-radius: 5px 5px 0 5px;
            overflow: hidden;
            box-shadow: -1px -1px 15px rgba(0, 0, 0, 0.1);
            z-index: 55;

            & > button {
                display: block;
                background: #fff;
                color: $text-color;
                width: 100%;
                text-align: left;
                font-size: 12px;
                padding: 10px 8px 10px 12px;
                border-style: solid;
                border-color: lighten($text-light, 40);
                border-width: 0 0 1px 0;
                font-weight: 700;

                &:hover {
                    cursor: pointer;
                    background: lighten($text-light, 40);
                }

                & > [class^="ba-icn"] {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .panel-footer {
        position: absolute;
        top: calc(100vh - 140px);
    }
}

@media only screen and (max-width: 600px) {
    .disclaimer .chatbot-container{
        height: 100svh !important; 
    }

    .disclaimer .drawer-panel .panel-cont-side .panel-body-wrapper {
        position: relative;
        overflow: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 200px);
        /*padding-bottom: 20%;*/
    }
    .disclaimer .drawer-panel .panel-cont-side .panel-body-wrapper .panel-body-bot{
        height: unset !important;
        table{
                width: 100% !important;
                margin: 10px auto !important;
                td{
                    width: 100% !important; 

                    ul{
                        margin-right: 10px !important;
                    }
                }
            }
    }
    .disclaimer .drawer-panel .panel-cont-side .panel-footer{
        top: calc(100vh - 170px) !important;
        align-items: start;
        padding-top: 10px;
        margin-bottom: 0 !important;
    }   
    .disclaimer .drawer-panel .panel-cont-side .panel-title{
        position: relative;
        top: 0;
        left: 0;
    } 
}


//animation
@keyframes slideInLeft {
    0% {
        right: -350px;
    }
    100% {
        right: 0;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.filepond--drop-label{
    margin-bottom: 30px !important;
}

